import gql from 'graphql-tag';
import {
  EmployeeListQueryFragment,
  employeeFieldsFragment,
  AvailabilityListQueryFragment,
} from '../../fragments/employee.fragment';

export const EmployeeListQuery = gql`
  query Employee(
    $employeeInput: EmployeePagingInput
    $skillsInput: SkillPagingInput
  ) {
    ...EmployeeListQuery
  }

  ${EmployeeListQueryFragment}
`;

export const AvailabiltyListQuery = gql`
  query AvailabilityList(
    $input: AvailabilityPagingInput
    $skillsInput: SkillPagingInput
  ) {
    ...AvailabilityListQuery
  }

  ${AvailabilityListQueryFragment}
`;

export const CreateEmployeeMutation = gql`
  mutation EmployeeCreate(
    $input: EmployeeCreate!
    $skillsInput: SkillPagingInput
  ) {
    EmployeeCreate(input: $input) {
      ...employeeFields
    }
  }

  ${employeeFieldsFragment}
`;

export const UpdateEmployeeMutation = gql`
  mutation EmployeeUpdate(
    $input: EmployeeUpdate!
    $skillsInput: SkillPagingInput
  ) {
    EmployeeUpdate(input: $input) {
      ...employeeFields
    }
  }

  ${employeeFieldsFragment}
`;

export const ReInviteEmployeeMutation = gql`
  mutation EmployeeReInvite($input: EmployeeReInviteInput!) {
    EmployeeReInvite(input: $input) {
      id
    }
  }
`;

export const EmployeeSendSmsMutation = gql`
  mutation qq($input: EmployeeSmsMessageInput!) {
    EmployeeSendSms(input: $input) {
      message
    }
  }
`;
