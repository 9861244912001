import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getDailyReport } from '../../../core/services/report/report.service';
import {
  formatAddress,
  formatTimeSpentSeconds,
  cancelAblePromise, USA_TIME_FORMAT,
} from '../../../core/helpers/utilities';

const { Column } = Table;
const { Text } = Typography;

const PATH_PARAM_DATE_FORMAT = 'YYYYMMDD';

const DayView = ({ date, appliedFilter }) => {
  const [valueFetchStatus, setFetchStatus] = useState<string>('loading');
  const [valueData, setData] = useState<any>(null);

  useEffect(() => {
    setFetchStatus('loading');
    const mDate = moment(date, PATH_PARAM_DATE_FORMAT, true);
    const cPr = cancelAblePromise<any>(
      getDailyReport(
        appliedFilter.date || mDate,
        appliedFilter.employees,
        appliedFilter.orders,
        appliedFilter.customers
      )
    );
    cPr.promise.then(
      (resp) => {
        setData(resp.data);
        setFetchStatus('success');
      },
      () => {
        setFetchStatus('error');
      }
    );
    return () => {
      cPr.cancel();
    };
  }, [date, appliedFilter]);
  if (valueFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }
  if (valueFetchStatus === 'error') {
    return <span>Error...</span>;
  }
  return (
    <Row>
      <Col span={24}>
        <Table
          className="day-table"
          dataSource={valueData.map((orderTrace) => ({
            ...orderTrace,
            children: orderTrace.item.employeeTraces.map((employeeTrace) => ({
              ...employeeTrace,
              key: employeeTrace.employee.id,
            })),
          }))}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
            defaultPageSize: 5,
          }}
          rowKey={(trace) => {
            if (trace.employee) {
              return `emp-${trace.employee.id}`;
            }
            if (trace.order) {
              return `ord-${trace.order.id}`;
            }
            throw new Error('wrong data');
          }}
          expandable={{
            expandIconColumnIndex: 5,
            expandIcon: ({ expanded, onExpand, record }) => {
              if (record.employee) {
                return '';
              }
              return expanded ? (
                <DownOutlined
                  style={{ fontSize: 15, color: '#465968' }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <RightOutlined
                  style={{ fontSize: 15, color: '#465968' }}
                  onClick={(e) => onExpand(record, e)}
                />
              );
            },
          }}
          rowClassName={(trace) => {
            // const currentIndex = record.parent.item.dateTraces.findIndex(
            //   (rr) => rr.date === record.date
            // );
            if (trace.employee) {
              return 'bg-light sub-row';
            }
            return '';
          }}
        >
          <Column
            title="Customer"
            key="customer"
            render={(trace) => {
              if (trace.employee) {
                return (
                  <Text className="row-text sub-row">
                    {trace.employee.user.profile.firstName}{' '}
                    {trace.employee.user.profile.lastName}
                  </Text>
                );
              }
              if (trace.order) {
                return (
                  <Text className="row-text">{trace.order.customer.name}</Text>
                );
              }
              throw new Error('wrong data');
            }}
          />
          <Column
            title="Job #"
            key="nro"
            render={(trace) => {
              if (trace.employee) {
                return {
                  children: '',
                  props: { colSpan: 0 },
                };
              }
              if (trace.order) {
                return <Text className="">{trace.order.nro}</Text>;
              }
              throw new Error('wrong data');
            }}
          />
          <Column
            title="Address"
            key="address"
            render={(trace) => {
              if (trace.employee) {
                const dateTrace = trace.item.dateTraces[0];
                const allTimeEntries = dateTrace
                  ? [].concat(
                      ...dateTrace.item.taskTraces.map(
                        (taskTrace) => taskTrace.item.timeEntries
                      )
                    )
                  : [];
                return {
                  children: (
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        // justifyContent: 'space-around',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                      className="row-text light-color"
                    >
                      {allTimeEntries.map(
                        ({
                          id,
                          startsAt,
                          secondsTimeSpan,
                        }: {
                          id: any;
                          startsAt: any;
                          secondsTimeSpan: any;
                        }) => (
                          <div className="time-entry-item" key={id}>
                            <Text className="entry-text">
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                }}
                                className="c-italic dark-color fs-15"
                              >
                                IN{'  '}
                              </Text>
                              <Text className="c-italic dark-color fs-15">
                                {moment(startsAt).format(USA_TIME_FORMAT)}
                              </Text>
                            </Text>
                            <Text className="entry-text">
                              <Text
                                className="c-italic dark-color fs-15"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                OUT{'  '}
                              </Text>
                              <Text className="c-italic dark-color fs-15">
                                {secondsTimeSpan
                                  ? moment(startsAt)
                                      .add(secondsTimeSpan, 'seconds')
                                      .format(USA_TIME_FORMAT)
                                  : 'N/A'}
                              </Text>
                            </Text>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: 'rgba(70, 89, 104, 0.15)',
                                width: 1,
                                height: '100%',
                                marginLeft: 30,
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  ),
                  props: { colSpan: 2 },
                };
              }
              if (trace.order) {
                return (
                  <Text className="row-text light-color">
                    {formatAddress(trace.order.address)}
                  </Text>
                );
              }
              throw new Error('wrong data');
            }}
          />
          <Column
            title="Time Today"
            key="timeToday"
            dataIndex={['item', 'timeSpentSeconds']}
            render={(rr) => {
              return (
                <Text className="row-text">{formatTimeSpentSeconds(rr)}</Text>
              );
            }}
          />
          <Column
            title="Total"
            key="orderTotal"
            render={(trace) => {
              if (trace.employee) {
                return '';
              }
              if (trace.order) {
                return (
                  <Text className="row-text">
                    {formatTimeSpentSeconds(trace.item.totalTimeSpentSeconds)}
                  </Text>
                );
              }
              throw new Error('wrong data');
            }}
          />
          <Column title="" key="expandIcon" width="2%" />
        </Table>
      </Col>
    </Row>
  );
};

export default DayView;
