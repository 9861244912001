import moment from 'moment';
import { FUESApolloClient } from '../../helpers/api';
import {
  ConfirmInvoiceMutation,
  InvoiceUpdateMutation,
  GenerateInvoiceMutation,
  UpdateQbDateInvoiceMutation,
  RemoveInvoiceMutation,
  GetNextInvoiceDatesQuery,
  GetActiveInvoice,
  GetInvoiceLineItems,
  ListInvoicesQuery,
  GetArchivedInvoices,
  UpdateInvoiceLineItem,
  UpdateInvoiceLineItemQb,
} from './invoice.gql';

function addSums(report) {
  return {
    ...report,
    confirmedAt: report.confirmedAt && moment(report.confirmedAt).toDate(),
    qbDate: report.qbDate && moment(report.qbDate).toDate(),
    createdAt: report.createdAt && moment(report.createdAt).toDate(),
    updatedAt: report.updatedAt && moment(report.updatedAt).toDate(),
    payPeriodStart:
      report.payPeriodStart && moment.utc(report.payPeriodStart).startOf('day'),
    payPeriodEnd:
      report.payPeriodEnd && moment.utc(report.payPeriodEnd).startOf('day'),
    timeSpentSeconds: report.employeesOnOrder
      ? report.employeesOnOrder
          .map(({ timeSpentSeconds }) => timeSpentSeconds)
          .reduce((acum, el) => acum + el, 0)
      : 10,
    totalMoney: report.employeesOnOrder
      ? report.employeesOnOrder
          .map(({ totalMoney }) => totalMoney)
          .reduce((acum, el) => acum + el, 0)
      : 11,
  };
}

export const confirmInvoice = async (idInvoice) =>
  FUESApolloClient()
    .mutate({
      mutation: ConfirmInvoiceMutation,
      variables: {
        input: {
          id: idInvoice,
          confirmedAt: new Date().toISOString(),
        },
      },
    })
    .then((resp) => resp.data.InvoiceUpdate);

export const UpdateQbDateInvoice = async (invoiceId) =>
  FUESApolloClient()
    .mutate({
      mutation: UpdateQbDateInvoiceMutation,
      variables: {
        input: {
          id: invoiceId,
          qbDate: new Date().toISOString(),
        },
      },
    })
    .then((resp) => resp.data.InvoiceUpdate);

export const generateInvoice = async (startDate, endDate) =>
  FUESApolloClient()
    .mutate({
      mutation: GenerateInvoiceMutation,
      variables: {
        input: {
          startDate,
          endDate,
        },
      },
    })
    .then((resp) => resp.data.GenerateInvoice);
// export const generateInvoice = async () => addSums(justGeneratedInvoice);

export const ActiveInvoice = async () =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetActiveInvoice,
      variables: {},
    })
    .then((res) => res.data.GetActiveInvoice);

export const InvoiceArchieved = async (limit: number, skip: number) =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetArchivedInvoices,
      variables: {
        input: {
          limit,
          skip,
        },
      },
    })
    .then((res) => res.data.GetArchivedInvoices);

export const InvoiceLineItems = async (
  id: string,
  limit: number,
  skip: number
) =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetInvoiceLineItems,
      variables: {
        input: {
          invoice: id,
          limit,
          skip,
        },
      },
    })
    .then((res) => res.data.GetInvoiceLineItems);

export const UpdateInvoiceLineItemReq = async (
  lineItemId: string,
  rate: number
) =>
  FUESApolloClient()
    .mutate({
      mutation: UpdateInvoiceLineItem,
      variables: {
        input: {
          lineItem: lineItemId,
          rate,
        },
      },
    })
    .then((res) => res.data);

export const UpdateInvoiceLineItemQbReq = async (
  orderId: string,
  invoiceId: string
) =>
  FUESApolloClient()
    .mutate({
      mutation: UpdateInvoiceLineItemQb,
      variables: {
        input: {
          orderId,
          invoiceId,
        },
      },
    })
    .then((res) => res.data.UpdateInvoiceLineItemQb.allSent);

export const getNextInvoiceDates = async () =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetNextInvoiceDatesQuery,
      variables: {},
    })
    .then((resp) => resp.data.GetNextInvoiceDates);

export const listInvoices = async () =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: ListInvoicesQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc',
          },
        },
      },
    })
    .then((resp) => resp.data.Invoice)
    .then((rr) => ({ ...rr, data: rr.data.map(addSums) }));
// export const listInvoices = async () => ({data: DUMMY_INVOICES.data.map(addSums)});

export const invoiceUpdate = async (invoiceId, values) =>
  FUESApolloClient()
    .mutate({
      mutation: InvoiceUpdateMutation,
      variables: {
        input: {
          id: invoiceId,
          employeesOnOrder: values,
        },
      },
    })
    .then((resp) => resp.data.InvoiceUpdate)
    .then(addSums);

export const RemoveInvoice = async (invoiceId) =>
  FUESApolloClient()
    .mutate({
      mutation: RemoveInvoiceMutation,
      variables: {
        input: {
          invoiceId,
        },
      },
    })
    .then((resp) => resp.data.message);
