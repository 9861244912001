export const App = 5000;
export const Validation = 4000;
export const WrongCredentials = 4011;
export const NotImplemented = 5001;
export const NotAuthorized = 4001;
export const Forbidden = 4003;
export const NotFound = 4004;
export const UniqueUsername = 4005;
export const UniquePhone = 4006;
export const UniqueEmail = 4007;
export const WrongPhone = 4012;
