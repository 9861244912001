import React from 'react';
import { Modal, Row, Col } from 'antd';

const CustomModal = ({
  onConfirm,
  onCancel,
  visible,
  children,
  title,
  footer,
  destroyOnClose,
  width,
  maskClosable,
  ...props
}: {
  onConfirm?;
  onCancel?;
  visible;
  children;
  title;
  footer?;
  destroyOnClose?;
  width?;
  maskClosable?;
}) => {
  return (
    <Modal
      maskClosable={maskClosable}
      title={
        <Row justify="center" align="middle">
          <Col>{title}</Col>
        </Row>
      }
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={footer}
      destroyOnClose={destroyOnClose}
      width={width}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
