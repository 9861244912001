import gql from 'graphql-tag';
import { invoiceFieldsFragment } from '../../fragments/invoice.fragment';
import { pagingFieldsFragment } from '../../fragments/pagination.fragment';
import { superintendentInOrderFieldsFragment } from '../../fragments/order.fragment';

export const GetActiveInvoice = gql`
  query {
    GetActiveInvoice {
      id
      payPeriodStart
      payPeriodEnd
      confirmedAt
      qbDate
    }
  }
`;

export const GetArchivedInvoices = gql`
  query GetArchivedInvoices($input: GetArchivedInvoices!) {
    GetArchivedInvoices(input: $input) {
      total
      data {
        invoice {
          id
          createdAt
          confirmedAt
          qbDate
          payPeriodStart
          payPeriodEnd
            
        }
        lineItemsGroupByOrder {
          total
          avgRate
          timeSpentSeconds
          order {
            id
            pdfUrl
            nro
            customer {
              id
              name
            }
          }
          lineItems {
            total
            rate
            timeSpentSeconds
            type
            task
            employee {
              user {
                profile {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GetInvoiceLineItems = gql`
  query GetInvoiceLineItems($input: GetInvoiceLineItemInput!) {
    GetInvoiceLineItems(input: $input) {
      data {
        avgRate
        timeSpentSeconds
        total
        order {
          id
          nro
          jobSiteName
          jobNumber
          customer {
            name
          }
          superintendent {
            ...superintendentInOrderFields
          }
          address {
            address1
            address2
          }
        }
        lineItems {
          _id
          type
          rate
          task
          qbDate
          total
          timeSpentSeconds
          employee {
            id
            user {
              profile {
                firstName
                lastName
              }
            }
          }
        }
      }
      total
    }
  }
  ${superintendentInOrderFieldsFragment}
`;

export const UpdateInvoiceLineItem = gql`
  mutation UpdateInvoiceLineItem($input: UpdateInvoiceLineItemInput!) {
    UpdateInvoiceLineItem(input: $input) {
      _id
      rate
      total
    }
  }
`;

export const UpdateInvoiceLineItemQb = gql`
  mutation UpdateInvoiceLineItemQb($input: UpdateInvoiceLineItemQbInput!) {
    UpdateInvoiceLineItemQb(input: $input) {
      allSent
    }
  }
`;

export const GetNextInvoiceDatesQuery = gql`
  query GetNextInvoiceDates {
    GetNextInvoiceDates {
      payPeriodStart
      payPeriodEnd
    }
  }
`;

export const GenerateInvoiceMutation = gql`
  mutation GeneratetInvoice($input: GenerateNextInvoiceInput!) {
    GenerateInvoice(input: $input) {
      id
    }
  }
`;

export const ListInvoicesQuery = gql`
  query Invoice($input: InvoicePagingInput) {
    ...InvoiceListQuery
  }
  fragment InvoiceListQuery on Query {
    Invoice(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...invoiceFields
      }
    }
  }
  ${invoiceFieldsFragment}
  ${pagingFieldsFragment}
`;

export const UpdateQbDateInvoiceMutation = gql`
  mutation InvoiceUpdate($input: InvoiceUpdateInput!) {
    InvoiceUpdate(input: $input) {
      id
      qbDate
    }
  }
`;

export const ConfirmInvoiceMutation = gql`
  mutation InvoiceUpdate($input: InvoiceUpdateInput!) {
    InvoiceUpdate(input: $input) {
      id
      confirmedAt
    }
  }
`;

export const InvoiceUpdateMutation = gql`
  mutation InvoiceUpdate($input: InvoiceUpdateInput!) {
    InvoiceUpdate(input: $input) {
      ...invoiceFields
    }
  }
  ${invoiceFieldsFragment}
`;

export const RemoveInvoiceMutation = gql`
  mutation InvoiceRemove($input: RemoveInvoiceInput!) {
    InvoiceRemove(input: $input) {
      message
    }
  }
`;
