import { PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, message, Spin, Tag, Tooltip } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import '../index.scss';
import {
  createCertification, deleteCertification,
  getAllCertifications,
  updateCertification
} from '../../../../core/services/certification/certification.service';
import { ICertification } from '../../../../core/interfaces/certification.interface';

const { Panel } = Collapse;
const { Search } = Input;

const CertificationList = () => {
  const [tags, setTags] = useState<ICertification[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const input: any = useRef(null);
  const editInput: any = useRef(null);

  const fetchCertifications = () => {
    getAllCertifications().then((resp) => {
      setTags(resp.data);
    });
  };

  const handleClose = async (removedTag: ICertification) => {
    try {
      setLoading(true);
      const response = await deleteCertification(removedTag.id);
      if (response) {
        const filteredTags = tags.filter((tag) => tag.id !== removedTag.id);
        setTags(filteredTags);
      }
    } catch (error) {
      message.error('Error removing the skill!');
    }
    setLoading(false);
  };

  const showInput = () => {
    setInputVisible(true);
    input.current && input.current.focus();
  };


  const handleInputConfirm = async (value) => {
    if (!value || value.length === 0) {
      message.error('You have to enter something!');
      return;
    }
    try {
      if (
        tags.map((tag) => tag.name).indexOf(value) === -1
      ) {
        setLoading(true);
        const cert = await createCertification({ name: value });
        if (cert) {
          const newTags = [...tags, { id: cert.id, name: cert.name }];
          setTags(newTags);
        }
      } else {
        message.error('Certification already exists');
        return;
      }
    } catch (error) {
      message.error('Error creating the certification!');
    }
    setLoading(false);
    setInputVisible(false);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = async (tagId) => {
    if (!editInputValue || editInputValue.length === 0) {
      message.error('You have to enter something!');
      return;
    }
    const index = tags.findIndex(tag => tag.id === tagId);

    if (index < 0) {
      message.error('There\'s been an error! Try again.');
      return;
    }

    try {
      setLoading(true);
      const cert = await updateCertification({ id: tags[index].id, name: editInputValue });
      if (cert) {
        const newTags = [...tags];

        newTags[index].name = editInputValue;

        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
      }

    } catch (error) {
      message.error('There\'s been an error updating the certification!');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCertifications();
  }, []);

  return (
    <Collapse defaultActiveKey={['1']} onChange={() => {
    }}>
      <Panel
        header={
          <h1 className='collapse__title'>Employee Certifications <Spin spinning={loading} size={'large'} /></h1>}
        key='1'
      >

        <>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInput}
                  key={tag.id}
                  size='small'
                  className='tag-edit'
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  /*onBlur={() => {
                    setEditInputIndex(-1);
                  }}*/
                  addonAfter={<Button size={'small'} type={'link'}
                                      onClick={() => handleEditInputConfirm(tag.id)}>Save</Button>}
                />
              );
            }

            const isLongTag = tag.name.length > 20;

            const tagElem = (
              <Tag
                className='edit-tag'
                key={tag.id}
                closable={true}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    setEditInputIndex(index);
                    setEditInputValue(tag.name);
                    editInput.current && editInput.current.focus();
                    e.preventDefault();
                  }}
                >
                  {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag.name} key={tag.id}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible && (
            <Spin spinning={loading}>
              <Search
                enterButton='Create'
                allowClear={true}
                disabled={loading}
                loading={loading}
                type='text'
                size='small'
                className='tag-input'
                // value={inputValue}
                onSearch={handleInputConfirm}
                // onPressEnter={handleInputConfirm}
              />
            </Spin>
          )}
          {!inputVisible && (
            <Tag className='site-tag-plus' onClick={() => showInput()}>
              <PlusOutlined /> New Certification
            </Tag>
          )}
        </>
      </Panel>
    </Collapse>
  );
};

export default CertificationList;
