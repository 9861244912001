import React, { useEffect } from 'react';
import './index.scss';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';

// Assets
import logo from '../../core/images/FillerupLogo.png';

// Components
import Login from './Login';
import RequestPasswordChange from './RequestPasswordChange';
import ResetPassword from './ResetPassword';
import { Token } from '../../core/helpers/storage';

const PublicLayout = () => {
  const history = useHistory();

  const token = Token();

  useEffect(() => {
    if (token?.jwtBearer) {
      history.push('/employees');
    }
  }, [history, token]);

  if (token?.jwtBearer) {
    return null;
  }
  return (
    <Row className="public-layout" justify="center" align="middle">
      <Col xs={22} md={12} lg={6}>
        <div className="login-container">
          <Row justify="center">
            <img src={logo} alt="App Logo" className="auth-app-logo" />
          </Row>
          <Row justify="center">
            <p className="auth-app-title">Fillerup</p>
          </Row>
          <Switch>
            <Route
              path="/auth/relogin"
              render={() => (
                <Login initialMsg="Session expired. Please, log in again" />
              )}
            />
            <Route path="/auth/login" render={() => <Login />} />
            <Route
              path="/auth/request-password-change"
              render={() => <RequestPasswordChange />}
            />
            <Route
              path="/auth/reset-password"
              render={() => <ResetPassword />}
            />
            <Route
              exact
              path="*"
              render={() => <Redirect to="/auth/login" />}
            />
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default PublicLayout;
