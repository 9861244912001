import {FUESApolloClient} from '../../helpers/api';
import {
  ConfirmPaymentMutation,
  PaymentUpdateMutation,
  GeneratePaymentMutation,
  RemovePaymentMutation,
  GetNextPaymentDatesQuery,
  GetActivePayment,
  GetPaymentLineItems,
  ListPaymentsQuery,
  GetArchivedPayments,
  UpdatePaymentLineItem,
  UpdatePaymentLineItemQb,
  UpdatePrintedPdfDatePaymentMutation, GetPaymentsForExport
} from './payment.gql';
import {IPaymentForExport} from "../../interfaces/paymentForExport.interface";

export const confirmPayment = async (idPayment): Promise<any> =>
  FUESApolloClient()
    .mutate({
      mutation: ConfirmPaymentMutation,
      variables: {
        input: {
          id: idPayment,
          confirmedAt: new Date().toISOString()
        }
      }
    })
    .then((resp) => resp.data.PaymentUpdate);

export const UpdatePrintedPdfDatePayment = async (paymentId) =>
  FUESApolloClient()
    .mutate({
      mutation: UpdatePrintedPdfDatePaymentMutation,
      variables: {
        input: {
          id: paymentId,
          printedPdf: new Date().toISOString()
        }
      }
    })
    .then((resp) => resp.data.PaymentUpdate);

export const ActivePayment = async (): Promise<any> =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetActivePayment,
      variables: {}
    })
    .then((res) => res.data.GetActivePayment);

export const PaymentArchieved = async (
  limit: number,
  skip: number
): Promise<any> =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetArchivedPayments,
      variables: {
        input: {
          limit,
          skip
        }
      }
    })
    .then((res) => res.data.GetArchivedPayments);

export const PaymentLineItems = async (
  id: string,
  limit: number,
  skip: number
): Promise<any> =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetPaymentLineItems,
      variables: {
        input: {
          payment: id,
          limit,
          skip
        }
      }
    })
    .then((res) => res.data.GetPaymentLineItems);

export const UpdatePaymentLineItemReq = async (
  lineItemId: string,
  rate: number
) =>
  FUESApolloClient()
    .mutate({
      mutation: UpdatePaymentLineItem,
      variables: {
        input: {
          lineItem: lineItemId,
          rate
        }
      }
    })
    .then((res) => res.data);

export const UpdatePaymentLineItemQbReq = async (
  orderId: string,
  paymentId: string
) =>
  FUESApolloClient()
    .mutate({
      mutation: UpdatePaymentLineItemQb,
      variables: {
        input: {
          orderId,
          paymentId
        }
      }
    })
    .then((res) => res.data.UpdatePaymentLineItemQb.allSent);

export const generatePayment = async (startDate, endDate): Promise<any> =>
  FUESApolloClient()
    .mutate({
      mutation: GeneratePaymentMutation,
      variables: {
        input: {
          startDate,
          endDate
        }
      }
    })
    .then((resp) => resp.data.GeneratePayment);

export const getNextPaymentDates = async (): Promise<any> =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetNextPaymentDatesQuery,
      variables: {}
    })
    .then((resp) => resp.data.GetNextPaymentDates);

export const getPaymentsForExport = async (paymentId: string): Promise<IPaymentForExport[]> =>
  FUESApolloClient()
    .query<{ PaymentGetDataForExport: IPaymentForExport[] }>({
      fetchPolicy: 'no-cache',
      query: GetPaymentsForExport,
      variables: {
        input: {
          paymentId
        }
      }
    })
    .then((resp) => resp.data.PaymentGetDataForExport);


export const listPayments = async () =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: ListPaymentsQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc'
          }
        }
      }
    })
    .then((resp) => resp.data.Payment);

export const paymentUpdate = async (id, values) =>
  FUESApolloClient()
    .mutate({
      mutation: PaymentUpdateMutation,
      variables: {
        input: {
          id: id,
          employeesOnOrder: values
        }
      }
    })
    .then((resp) => resp.data.PaymentUpdate);

export const RemovePayment = async (paymentId) =>
  FUESApolloClient()
    .mutate({
      mutation: RemovePaymentMutation,
      variables: {
        input: {
          paymentId
        }
      }
    })
    .then((resp) => resp.data.message);
