import React from 'react';
import arrow_right from '../images/arrows/right_arrow.svg';

const ArrowButtonRight = ({
  handleClick,
  imgStyles,
  disabled,
}: {
  handleClick;
  imgStyles?;
  disabled?;
}) => {
  return (
    <button
      onClick={handleClick}
      style={{
        border: 'none',
        padding: 0,
        outlineColor: 'rgba(0,0,0,0.2)',
        cursor: 'pointer',
      }}
      disabled={disabled}
    >
      <img
        alt={'arrow-right'}
        src={arrow_right}
        style={{ width: 28, height: 28, ...imgStyles }}
      />
    </button>
  );
};

export default ArrowButtonRight;
