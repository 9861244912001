import React, { useEffect, useState } from 'react';
import { Col, Row, message } from 'antd';
import WeekTree from './WeekTree';
import { getDateRangeReport } from '../../../core/services/report/report.service';
import { useMemoCompare } from '../../../core/hooks';
import { cancelAblePromise } from '../../../core/helpers/utilities';

const dateCmp = (v1, v2) => Boolean((!v1 && !v2) || v1?.isSame(v2));

const WeekView = ({ dateRange, appliedFilter }) => {
  const [valueFetchStatus, setFetchStatus] = useState<string>('loading');
  const [valueData, setData] = useState<any>(null);

  const dateRangeStart = useMemoCompare(dateRange && dateRange[0], dateCmp);
  const dateRangeEnd = useMemoCompare(dateRange && dateRange[1], dateCmp);
  const appliedFilterMemo = useMemoCompare(
    appliedFilter,
    (v1, v2) => JSON.stringify(v1) === JSON.stringify(v2)
  );
  useEffect(() => {
    setFetchStatus('loading');
    const cPr = cancelAblePromise<any>(
      getDateRangeReport(
        dateRangeStart,
        dateRangeEnd,
        appliedFilterMemo.employees,
        appliedFilterMemo.orders,
        appliedFilterMemo.customers
      )
    );
    cPr.promise.then(
      (resp) => {
        setData(resp.data);
        setFetchStatus('success');
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
    return () => {
      cPr.cancel();
    };
  }, [dateRangeStart, dateRangeEnd, appliedFilterMemo]);
  if (valueFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }
  if (valueFetchStatus === 'error') {
    return <span>Error...</span>;
  }
  return (
    <Row>
      <Col span={24}>
        <WeekTree
          orderTraces={valueData}
          // setOrderTraces={setData}
          // range={dateRange}
        />
      </Col>
    </Row>
  );
};

export default WeekView;
