import React from 'react';
import { Row, Col, Form, Input, Button, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;

const WitnessForm = ({ disabled }) => {
  return (
    <>
      <Form.List name="witnesses">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => {
              return (
                <div key={field.key}>
                  <Row align="middle">
                    <Col span={8}>
                      <Text className="new-form-labe">Witness</Text>
                      <Form.Item
                        {...field}
                        name={[field.name, 'name']}
                        fieldKey={[field.fieldKey, 'name']}
                        rules={[
                          { required: true, message: 'Witness is required' },
                        ]}
                      >
                        <Input className="filter-label" disabled={disabled} />
                      </Form.Item>
                    </Col>
                    {!disabled && (
                      <Col offset={1}>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Text className="incident-label">Witness statement</Text>
                      <Form.Item
                        {...field}
                        name={[field.name, 'statement']}
                        fieldKey={[field.fieldKey, 'statement']}
                        rules={[
                          {
                            required: true,
                            message: 'Witness statement is required',
                          },
                        ]}
                      >
                        <TextArea
                          className="textArea"
                          rows={4}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            })}

            {!disabled && (
              <Button
                style={{ backgroundColor: '#D7DEE2', borderRadius: 2 }}
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                <Text className="btn-label"> Add Witness</Text>
              </Button>
            )}
          </>
        )}
      </Form.List>
    </>
  );
};

export default WitnessForm;
