import gql from 'graphql-tag';
import { userFieldsFragment, createUserFieldsFragment } from './user.fragment';
import { addressFieldsFragment } from './address.fragment';
import { skillListQueryFragment } from './skill.fragment';
import { entityFieldsFragment } from './entity.fragment';
import { pagingFieldsFragment } from './pagination.fragment';

export const employeeUserFieldsFragment = gql`
  fragment employeeUserFields on Employee {
    user {
      ...userFields
    }
  }

  ${userFieldsFragment}
`;

export const certificationsFieldsFragment = gql`
  fragment certificationsFields on Employee {
    certifications {
      catalog {
        ...entityFields
        name
        description
      }
      media {
        ...entityFields
        url
      }
      issuedAt
    }
  }
`;

export const employeeFieldsFragment = gql`
  fragment employeeFields on Employee {
    id
    gender
    transportation
    nightShifts
    englishLevel
    rating
    startsAt
    notes
    foreman
    h2bVisa
    ...addressFields
    ...employeeUserFields
    ...createUserFields
    ...skillListQuery
    ...entityFields
    ...certificationsFields
  }

  ${addressFieldsFragment('Employee')}

  ${employeeUserFieldsFragment}

  ${createUserFieldsFragment('Employee')}

  ${skillListQueryFragment('Employee')}

  ${certificationsFieldsFragment}

  ${entityFieldsFragment}
`;

export const availabilityFieldsFragment = gql`
  fragment availabilityFields on Availability {
    ...entityFields
    date
    employee {
      ...employeeFields
    }
  }
  ${employeeFieldsFragment}
`;

export const AvailabilityListQueryFragment = gql`
  fragment AvailabilityListQuery on Query {
    Availability(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...availabilityFields
      }
    }
  }
  ${pagingFieldsFragment}

  ${availabilityFieldsFragment}
`;

export const EmployeeListQueryFragment = gql`
  fragment EmployeeListQuery on Query {
    Employee(input: $employeeInput) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...employeeFields
      }
    }
  }

  ${employeeFieldsFragment}

  ${pagingFieldsFragment}
`;
