/* eslint-disable camelcase, import/prefer-default-export */
import { ISelectedAddress, IAddress } from '../interfaces/maps.interface';

export const decodeSelectedAddress = (place: ISelectedAddress): IAddress => {
  return {
    name: place?.formatted_address || '',
    lat: `${place?.geometry?.location.lat()}`,
    long: `${place?.geometry?.location.lng()}`,
    address1:
      place?.address_components?.find((x) => x.types.includes('route'))
        ?.long_name || '',
    address2:
      place?.address_components?.find((x) => x.types.includes('street_number'))
        ?.long_name || '',
    city:
      place?.address_components?.find((x) =>
        x.types.includes('administrative_area_level_2')
      )?.long_name ||
      place?.address_components?.find((x) => x.types.includes('locality'))
        ?.long_name ||
      '',
    state:
      place?.address_components?.find((x) =>
        x.types.includes('administrative_area_level_1')
      )?.long_name || '',
    zipCode:
      place?.address_components?.find((x) => x.types.includes('postal_code'))
        ?.long_name || '',
    country:
      place?.address_components?.find((x) => x.types.includes('country'))
        ?.long_name || '',
    notes: '',
  };
};

// export const encodeSelectedAddress = (
//   place: IAddress
// ): ISelectedAddress => {
//   return {
//     name: place?.formatted_address || '',
//     lat: `${place?.geometry?.location.lat()}`,
//     long: `${place?.geometry?.location.lng()}`,
//     address1: place?.name || '',
//     address2: '',
//     city:
//       place?.address_components?.find((x) =>
//         x.types.includes('administrative_area_level_2')
//       )?.long_name || '',
//     state:
//       place?.address_components?.find((x) =>
//         x.types.includes('administrative_area_level_1')
//       )?.long_name || '',
//     zipCode:
//       place?.address_components?.find((x) =>
//         x.types.includes('postal_code')
//       )?.long_name || '',
//     country:
//       place?.address_components?.find((x) => x.types.includes('country'))
//         ?.long_name || '',
//     notes: place?.formatted_address || '',
//   };
// };
