import React, { useState } from 'react';
import { Button, Row, Form, Input, Col, Typography } from 'antd';
import LoadingButton from '../../../core/components/LoadingButton';
import PhoneInput from '../../../core/components/PhoneInput';

const { Text } = Typography;

const SuperintendentForm = ({
  onCancel,
  onSubmit,
  onReInviteEmployee,
  onDelete,
  superintendent,
}: {
  onCancel: any;
  onSubmit: any;
  onReInviteEmployee?: any;
  onDelete?: any;
  superintendent?: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const superintendentId = superintendent?.id;
  let buttons;
  if (superintendentId && onDelete) {
    buttons = (
      <LoadingButton
        style={{ marginLeft: 10 }}
        className="button-cancel-form"
        onClick={() => onDelete(superintendent.id).then(() => onCancel())}
        size="large"
        danger
      >
        <Text className="btn-label">Delete</Text>
      </LoadingButton>
    );
  } else if (!superintendentId) {
    buttons = (
      <Button
        style={{ marginLeft: 10 }}
        className="button-new-form"
        htmlType="submit"
        size="large"
      >
        <Text className="btn-label">Save</Text>
      </Button>
    );
  }
  return (
    <Form
      form={form}
      name="superintendent-form"
      onFinish={onSubmit}
      initialValues={
        superintendent
          ? {
              firstName: superintendent.user.profile.firstName,
              lastName: superintendent.user.profile.lastName,
              email: superintendent.user.profile.email,
              phone: superintendent.user.profile.phone,
            }
          : {}
      }
    >
      <Form.Item
        name="firstName"
        rules={[{ required: true, message: 'first name is required' }]}
      >
        <Input
          size="large"
          placeholder="First name"
          disabled={superintendentId}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[{ required: true, message: 'last name is required' }]}
      >
        <Input
          size="large"
          placeholder="Last name"
          disabled={superintendentId}
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'email is required' }]}
      >
        <Input size="large" placeholder="Email" disabled={superintendentId} />
      </Form.Item>
      <Form.Item
        name="phone"
        rules={[{ required: true, message: 'phone is required' }]}
      >
        <PhoneInput disabled={superintendentId} />
      </Form.Item>
      {superintendent && superintendent.id && onReInviteEmployee && (
        <Row>
          <Col>
            {superintendent.user.active ? (
              <Text className="row-text">
                Superintendent already accepted the invitation
              </Text>
            ) : (
              <>
                <Text className="row-text">
                  Superintendent hasn&apos;t accepted the invitation yet
                </Text>
                <br />
                <LoadingButton
                  style={{ marginTop: 20 }}
                  className="btn-label"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    onReInviteEmployee(superintendent.id)
                      .catch(() => {})
                      .then(() => {
                        setTimeout(() => setLoading(false), 5000);
                      });
                  }}
                >
                  <Text className="row-text">Send Invitation Again</Text>
                </LoadingButton>
              </>
            )}
          </Col>
        </Row>
      )}
      <Row justify="end">
        <Form.Item>
          <Button
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            size="large"
            className="button-cancel-form"
          >
            <Text className="btn-label">Cancel</Text>
          </Button>
          {buttons}
        </Form.Item>
      </Row>
    </Form>
  );
};

export default SuperintendentForm;
