import React from 'react';
import MaskedInput from 'antd-mask-input';

const PhoneInput = (props) => {
  const onNumberChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, '');
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <MaskedInput
      {...props}
      mask="(111) 111-1111"
      onChange={onNumberChange}
      value={props.value}
      size="large"
      placeholder="Phone"
      type="text"
    />
  );
};

export default PhoneInput;
