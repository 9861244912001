import gql from 'graphql-tag';

const TaskNameFragment = gql`
    fragment TaskName on TaskName{
        id
        name
    }
`;

export const TaskNameListQuery = gql`
    query getTaskNames{
        getTaskNames{
            ...TaskName
        }
    }
    ${TaskNameFragment}
`;

export const TaskNameCreate = gql`
    mutation createTaskName($input: createTaskNameInput!) {
        createTaskName(input: $input) {
            ...TaskName
        }
    }
    ${TaskNameFragment}
`;

export const TaskNameUpdate = gql`
    mutation updateTaskName($input: updateTaskNameInput!) {
        updateTaskName(input: $input) {
            ...TaskName
        }
    }
    ${TaskNameFragment}
`;

export const TaskNameDelete = gql`
    mutation deleteTaskName($input: deleteTaskNameInput!) {
        deleteTaskName(input: $input) {
            ...TaskName
        }
    }
    ${TaskNameFragment}
`;
