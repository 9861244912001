import React from 'react';
import {
  Row,
  Col,
  Card,
  Tag,
  Typography,
  Space,
  Select,
  Form,
  Button
} from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import OrderScheduleList from './OrderScheduleList';
import { formatPhone, USA_TIME_FORMAT } from '../../../core/helpers/utilities';

const normalizeOrder = (ord, date) => ({
  ...ord,
  Schedules: {
    ...ord.Schedules,
    data: [
      ord.Schedules?.data[0]
        ? ord.Schedules.data[0]
        : {
          date: date.format('YYYY-MM-DD'),
          foreman: null
        }
    ]
  }
});

const { Text } = Typography;
const Component = ({
                     selectedDate,
                     availableEmployees,
                     availableSkills,
                     order,
                     onChangeOrder,
                     onSubmit
                     // onRemove,
                   }) => {
  const [form] = Form.useForm();
  const orderTime = moment(`1970-01-01T${order.time}`).format(USA_TIME_FORMAT);

  const workerNeeded = order.workerGroups.reduce(
    (acc, { quantity }) => acc + quantity,
    0
  );

  const getAssignations = (values) => {
    return {
      foreman: values.foreman,
      assignations: values.employees.map(
        ({ id, wcf, billRate, payRate, skill }) => ({
          wcf: parseFloat(wcf),
          billRate: parseFloat(billRate),
          payRate: parseFloat(payRate),
          skill,
          employee: id
        })
      )
    };
  };

  return (
    <Form
      form={form}
      name={order.id}
      initialValues={{
        foreman: order.Schedules.data[0]
          ? order.Schedules.data[0].foreman?.id
          : undefined,
        employees: order.Schedules.data[0]
          ? order.Schedules.data[0].Assignations.data.map(
            ({ employee, wcf, billRate, payRate, skill }) => ({
              id: employee.id,
              skill: skill?.id,
              Skills: employee.Skills,
              user: employee.user,
              wcf,
              billRate,
              payRate
            })
          )
          : []
      }}
      onFinish={(values) => {
        const assignations = getAssignations(values);
        onSubmit(assignations);
      }}
      onFieldsChange={(changedFields, allFields) => {
        const hasEmployeeChanged = changedFields.some(
          ({ name }) => name[0] === 'employees'
        );
        if (hasEmployeeChanged) {
          const employeeField = allFields.find(
            ({ name }: { name: any }) =>
              name[0] === 'employees' && name[1] === undefined
          );
          const valuesEmployees = employeeField ? employeeField.value : [];
          const nOrder = normalizeOrder(order, selectedDate);
          const nOrder2 = {
            ...nOrder,
            Schedules: {
              ...nOrder.Schedules,
              data: nOrder.Schedules.data.map((schedule) => ({
                ...schedule,
                Assignations: {
                  ...schedule.Assignations,
                  data: valuesEmployees.map((assignation) => {
                    const employee = availableEmployees.find(
                      ({ id }) => id === assignation.id
                    );
                    const skill = availableSkills.find(
                      ({ id }) => id === assignation.skill
                    );
                    return {
                      ...assignation,
                      skill,
                      employee
                    };
                  })
                }
              }))
            }
          };
          onChangeOrder(nOrder2);
        }
      }}
    >
      {(fieldss, formInstance) => {
        const employeesSelectedAux = availableEmployees && availableEmployees.length > 0 && formInstance.getFieldValue('employees')
          ? formInstance
            .getFieldValue('employees')
            .map((obj) => ({ obj, avail: availableEmployees.find(({ id }) => id === obj.id) }))
          : [];

        const nonAssignedEmployees = employeesSelectedAux.filter(({ avail }) => !avail);

        const employeesSelected = employeesSelectedAux.filter(({ avail }) => avail).map(({ avail }) => avail);

        if(order.Schedules.data[0]?.foreman){
          employeesSelected.push(order.Schedules.data[0].foreman)
        }
        return (
          <Card
            className='card-custom-padding'
            style={{ marginBottom: 10, padding: 5 }}
          >
            <Text className='subtitle'>{order.customer.name}</Text>
            <Row gutter={24}>
              <Col span={6}>
                <div style={{ marginBottom: 2 }}>
                  <Text className='new-form-label'>report to</Text>
                </div>
                <Text className='schedule-label fs-12'>
                  {order.superintendent?.user.profile.firstName}{' '}
                  {order.superintendent?.user.profile.lastName}{' '}
                </Text>
                <br />
                <Text className='schedule-label fs-12'>
                  {formatPhone(order.phone)}
                </Text>
              </Col>
              <Col span={6}>
                <div style={{ marginBottom: 2 }}>
                  <Text className='new-form-label'>address</Text>
                </div>
                <Text className='schedule-label fs-12'>
                  {order.address.name}
                </Text>
              </Col>
              <Col span={3}>
                <div style={{ marginBottom: 2 }}>
                  <Text className='new-form-label'>workers</Text>
                </div>
                <Text className='schedule-label fs-12'>{workerNeeded}</Text>
              </Col>
              <Col span={9}>
                <Space direction='vertical'>
                  <Text className='new-form-label'>skills</Text>
                  <div>
                    {order.workerGroups.map(({ skill, quantity }) => {
                      const taken = order.Schedules
                        ? lodash.concat(
                          [],
                          ...order.Schedules.data.map(({ Assignations }) =>
                            Assignations.data.filter(
                              ({ skill: lSkill }) => lSkill?.id === skill.id
                            )
                          )
                        ).length
                        : 0;
                      return (
                        <Tag
                          style={{
                            border: '1px solid #636363',
                            boxSizing: 'border-box',
                            borderRadius: 15
                          }}
                          key={skill.id}
                        >
                          <Text className='schedule-label fs-11'>
                            {skill.name} {taken}/{quantity}
                          </Text>
                        </Tag>
                      );
                    })}
                  </div>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <div style={{ marginBottom: 2 }}>
                  <Text className='new-form-label'>time</Text>
                </div>
                <Text className='schedule-label fs-12'>{orderTime}</Text>
              </Col>
              <Col span={12}>
                <div style={{ marginBottom: 2 }}>
                  <Text className='new-form-label'>details</Text>
                </div>
                <Text className='schedule-label fs-12'>{order.notes}</Text>
              </Col>
              <Col span={6}>
                <Space direction='vertical' className='skills'>
                  <Text className='new-form-label'>foreman (lead)</Text>
                  <Form.Item
                    style={{ margin: 0 }}
                    name='foreman'
                    rules={[{ required: !!employeesSelected.length }]}
                  >
                    <Select size='small' style={{ width: 150 }}>
                      {employeesSelected.map((employee) => {
                        return(
                          <Select.Option
                            className='schedule-label fs-12'
                            key={employee.id}
                            value={employee.id}
                          >
                            {employee.user.profile.firstName}{' '}
                            {employee.user.profile.lastName}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <OrderScheduleList
                  availableSkills={availableSkills}
                  availableEmployees={availableEmployees}
                  employeesSelected={employeesSelected}
                  fieldss={fieldss}
                  formInstance={formInstance}
                  order={order}
                />
              </Col>
            </Row>
            <Form.Item style={{ marginBottom: 10 }}>
              <Button
                className='button-new-form'
                htmlType='submit'
                size='small'
              >
                <Text className='btn-label'>Save</Text>
              </Button>
            </Form.Item>
          </Card>
        );
      }}
    </Form>
  );
};

export default Component;
