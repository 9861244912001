import gql from 'graphql-tag';
import {
  OrderListQueryFragment,
  orderFieldsFragment
} from '../../fragments/order.fragment';

export const OrderListQuery = gql`
    query Order(
        $orderInput: OrderPagingInput
        $scheduleInput: SchedulePagingInput
        $skillsInput: SkillPagingInput
    ) {
        ...OrderListQuery
    }

    ${OrderListQueryFragment}
`;

export const CreateOrderMutation = gql`
    mutation OrderCreate(
        $input: OrderCreate!
        $scheduleInput: SchedulePagingInput
        $skillsInput: SkillPagingInput
    ) {
        OrderCreate(input: $input) {
            ...orderFields
        }
    }

    ${orderFieldsFragment}
`;

export const UpdateOrderMutation = gql`
    mutation OrderUpdate(
        $input: OrderUpdate!
        $scheduleInput: SchedulePagingInput
        $skillsInput: SkillPagingInput
    ) {
        OrderUpdate(input: $input) {
            ...orderFields
        }
    }

    ${orderFieldsFragment}
`;
