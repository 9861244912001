import { Button, message, Space, Table } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { formatTimeSpentSeconds } from '../../../../../core/helpers/utilities';
import { PaymentLineItems } from '../../../../../core/services/payment/payment.service';

import EditInvoiceModal from './EditModal';
import {
  IActivePayment,
  IDataLine,
  IDataTablePayments,
  IPaymentLineItems,
  IPaymentsItems
} from '../../../../../core/interfaces/payment.interface';

export interface ITableProps {
  payment: IActivePayment;
  disableEdit: boolean;
  onTotalLines: (total: number) => void;
  onDataTable: (body: IDataTablePayments[]) => void;
}

const PaymentsTable = (props: ITableProps) => {
  const [payments, setPayments] = useState<IDataTablePayments[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editInfo, setEditInfo] = useState<any>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [totalPayments, setTotalPayments] = useState<number>();
  const [currentSkip, setCurrentSkip] = useState<number>(0);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: '30%',
      render: (text, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Hours',
      dataIndex: 'secondToHours',
      width: '10%',
      render: (text, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      width: '10%',
      render: (text, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '15%',
      render: (text, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '10%',
      render: (text, record) => {
        return record.children ? null : (
          <Space size="middle">
            <Button disabled={disableEdit} onClick={() => edit(record)}>
              Edit
            </Button>
          </Space>
        );
      }
    }
  ];

  useEffect(() => {
    if (props.payment) {
      getPaymentLineItem(props.payment.id, 10, 0);
    }
  }, [props.payment]);

  useEffect(() => {
    props.disableEdit ? setDisableEdit(true) : setDisableEdit(false);
  }, [props.disableEdit]);

  const edit = (record: IPaymentsItems) => {
    if (record) {
      setEditInfo(record);
    }
  };

  const transformDataTable = (data: IDataLine[]) => {
    let tablePayments: IDataTablePayments[] = [];
    data.forEach((order) => {
      let listItems: any[] = [];
      order.lineItems.forEach((line) => {
        listItems.push({
          ...line,
          key: line._id,
          name: ` ${line.task} (${
            line.type === 'REGULAR' ? 'Reg. Hrs.' : ' 0/T Hrs.'
          })`,
          customer: line.order.customer.name,
          secondToHours: formatTimeSpentSeconds(line.timeSpentSeconds)
        });
      });
      tablePayments.push({
        key: order.employee.id,
        name: `${order.employee.user.profile.firstName} ${order.employee.user.profile.lastName}`,
        children: listItems,
        secondToHours: formatTimeSpentSeconds(order.timeSpentSeconds),
        total: order.total.toString(),
        customer: order.lineItems[0].order.customer.name,
        rate: order.avgRate
      });
    });
    setPayments(tablePayments);
    setLoading(false);
    props.onDataTable(tablePayments);
  };

  const getPaymentLineItem = async (
    id: string,
    limit: number,
    skip: number
  ) => {
    setLoading(true);
    try {
      const data: IPaymentLineItems = await PaymentLineItems(id, limit, skip);

      if (data) {
        transformDataTable(data.data);
        setTotalPayments(data.total);
        props.onTotalLines(data.total);
      }
    } catch (error) {
      setLoading(false);
      message.error('Error getPaymentLineItem');
    }
  };

  const onOkModal = async () => {
    setEditInfo(null);
    if (props.payment) {
      getPaymentLineItem(props.payment.id, 10, currentSkip);
    }
  };

  const onCancelModal = () => {
    setEditInfo(null);
  };

  const onHandleChangePage = (page: number) => {
    const skip = 10 * (page - 1);
    setCurrentSkip(skip);
    if (totalPayments && skip < totalPayments) {
      getPaymentLineItem(props.payment.id, 10, skip);
    }
  };

  return (
    <>
      <h1 style={{ fontSize: '22px', marginTop: '1rem' }}>Current Payment</h1>
      <Table
        columns={columns}
        dataSource={payments}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          total: totalPayments,
          onChange: onHandleChangePage
        }}
      />
      {editInfo && (
        <EditInvoiceModal
          data={editInfo}
          onOk={onOkModal}
          onCancel={onCancelModal}
        />
      )}
    </>
  );
};

export default PaymentsTable;
