import gql from 'graphql-tag';

export const pagingFieldsFragment = gql`
  fragment pagingFields on PagingMetaResult {
    count
    startIndex
    endIndex
    hasNextPage
  }
`;
