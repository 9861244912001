import gql from 'graphql-tag';
import { entityFieldsFragment } from '../../fragments/entity.fragment';
import { IncidentListQueryFragment } from '../../fragments/incident.fragment';

export const IncidentCreateMutation = gql`
  mutation IncidentCreate($input: IncidentCreate!) {
    IncidentCreate(input: $input) {
      ...entityFields
    }
  }

  ${entityFieldsFragment}
`;

export const IncidentListQuery = gql`
  query Incident($incidentInput: IncidentPagingInput) {
    ...IncidentListQuery
  }

  ${IncidentListQueryFragment}
`;
