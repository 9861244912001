import { FUESApolloClient } from '../../helpers/api';
import {
  GetTracesQuery,
  BulkConfirmEmployeeTraceMutation,
  SaveFeedbackEmployeeTraceOnDateRangeMutation,
} from './report.gql';

export const saveFeedbackEmployeeOnDateRange = (
  daterange1,
  daterange2,
  order,
  employee,
  feedback
) =>
  FUESApolloClient()
    .mutate({
      mutation: SaveFeedbackEmployeeTraceOnDateRangeMutation,
      variables: {
        input: {
          dateRange: {
            dateRangeStart: daterange1.format('YYYY-MM-DD'),
            dateRangeEnd: daterange2.format('YYYY-MM-DD'),
          },
          employee,
          order,
          feedbackText: feedback,
        },
      },
    })
    .then((resp) => resp.data.SaveFeedbackEmployeeTraceOnDateRange);

export const bulkConfirmEmployeeTrace = (ids) =>
  FUESApolloClient()
    .mutate({
      mutation: BulkConfirmEmployeeTraceMutation,
      variables: {
        input: { ids },
      },
    })
    .then((resp) => resp.data.BulkConfirmEmployeeTrace);

export const getDateRangeReport = async (
  startDate,
  endDate,
  employeeIds,
  orderIds,
  customerIds
) =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetTracesQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc',
          },
          args: {
            dateRange: {
              dateRangeStart: startDate.format('YYYY-MM-DD'),
              dateRangeEnd: endDate.format('YYYY-MM-DD'),
            },
            employeeIds,
            orderIds,
            customerIds,
          },
        },
      },
    })
    .then((resp) => resp.data.OrderTrace)
    .then(({ data, ...more }) => ({
      data: data.map((orderTrace) => ({
        ...orderTrace,
        item: {
          ...orderTrace.item,
          employeeTraces: orderTrace.item.employeeTraces.map(
            (employeeTrace) => ({
              ...employeeTrace,
              item: {
                ...employeeTrace.item,
                dateTraces: employeeTrace.item.dateTraces.filter(
                  (dateTrace) => dateTrace.item.taskTraces.length > 0
                ),
              },
            })
          ),
        },
      })),
      ...more,
    }));

export const getDailyReport = async (
  date,
  employeeIds,
  orderIds,
  customerIds
) => getDateRangeReport(date, date, employeeIds, orderIds, customerIds);
