import gql from 'graphql-tag';
import { skillFieldsFragment } from '../../fragments/skill.fragment';

export const CreateSkillReq = gql`
  mutation SkillCreate($input: SkillCreate!) {
    SkillCreate(input: $input) {
      ...skillFields
    }
  }
  ${skillFieldsFragment}
`;
