import React from 'react';
import {
  Col,
  Row,
  Typography,
  Space,
  Input,
  Select,
  Form,
  Checkbox,
  DatePicker,
} from 'antd';

import { HealthSafety, Operational } from '../../../../core/enums/health.enum';

const { Text } = Typography;
const { TextArea } = Input;

const ComponentA = ({ title, display, isReadOnly, employees, orders }) => {
  return (
    <Row style={{ display }} gutter={[16, 32]}>
      <Col span={24}>
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 0.2,
            color: '#000000',
            lineHeight: '20px',
          }}
        >
          Part {title}
        </Text>
      </Col>
      <Col span={12}>
        <Text className="incident-label">Employee</Text>
        <Form.Item
          name="employee"
          rules={[{ required: true, message: 'employee is required' }]}
        >
          <Select
            className="filter-label"
            disabled={isReadOnly}
            optionFilterProp="label"
            // filterOption
            // showSearch
            // size="large"
            // onChange={(employeeId) => {}}
            options={employees.map(({ id, user }) => ({
              value: id,
              label: `${user.profile.firstName} ${user.profile.lastName}`,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Text className="incident-label">Job site</Text>
        <Form.Item
          name="order"
          rules={[{ required: true, message: 'Job site required' }]}
        >
          <Select
            className="filter-label"
            disabled={isReadOnly}
            optionFilterProp="label"
            options={orders.map(({ id, nro, customer }) => ({
              value: id,
              label: `${customer.name} (${nro})`,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Text className="incident-label">Incident title</Text>
        <Form.Item
          name="title"
          rules={[{ required: true, message: 'Incident title required' }]}
        >
          <Input className="filter-label" size="large" disabled={isReadOnly} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="healthTypes"
              rules={[
                { required: true, message: 'Health & Safety is required ' },
              ]}
            >
              <Checkbox.Group>
                <Space direction="vertical">
                  <Text className="incident-label">Health & Safety </Text>
                  {Object.keys(HealthSafety).map((value) => {
                    return (
                      <Checkbox
                        style={{
                          fontSize: 12,
                          lineHeight: '20px',
                          letterSpacing: ' 0.2px',
                          color: 'black',
                        }}
                        key={value}
                        value={value}
                        disabled={isReadOnly}
                      >
                        {value}
                      </Checkbox>
                    );
                  })}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="operationalTypes"
              // rules={[{ required: true, message: 'Operational is required' }]}
            >
              <Checkbox.Group>
                <Space direction="vertical">
                  <Text className="incident-label">Operational </Text>
                  {Object.keys(Operational).map((value) => {
                    return (
                      <Checkbox
                        style={{
                          fontSize: 12,
                          lineHeight: '20px',
                          letterSpacing: ' 0.2px',
                          color: 'black',
                        }}
                        key={value}
                        value={value}
                        disabled={isReadOnly}
                      >
                        {value}
                      </Checkbox>
                    );
                  })}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text className="incident-label">Incident date</Text>
            <Form.Item
              name="occurredAt"
              rules={[{ required: true, message: 'Incident date is required' }]}
            >
              {/* ToDo: Check if time is recovered ok */}
              <DatePicker
                className="filter-label"
                showTime
                size="large"
                style={{ width: '80%' }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text className="incident-label">Incident details </Text>
            <Text className="incident-label">
              (description of what happened, including injuries, equipment, or
              environmental damage sustained, activity at the time)
            </Text>
            <Form.Item
              name="details"
              // rules={[
              //   { required: true, message: 'Incident details are required' },
              // ]}
            >
              <TextArea className="textArea" rows={4} disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text className="incident-label">Incident location </Text>
            <Form.Item
              name="location"
              // rules={[
              //   { required: true, message: 'Incident location is required' },
              // ]}
            >
              <Input className="filter-label" disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text className="incident-label">Department </Text>
            <Form.Item
              name="locationDepartment"
              // rules={[{ required: true, message: 'department is required' }]}
            >
              <Input className="filter-label" disabled={isReadOnly} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ComponentA;
