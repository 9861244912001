import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Typography, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// Components
import EmployeeForm from './EmployeeForm';
import { getEmployee } from '../../../core/services/employee/employee.service';
import { IEmployee } from '../../../core/interfaces/employee.interface';
import { getApplicantById } from '../../../core/services/applicant/applicant.service';
import { IApplicant } from '../../../core/interfaces/applicant.interface';

const { Text } = Typography;

interface IProps {
  employeeId?: string | undefined;
  applicantId?: string | undefined;
}

const EmployeeDetail: FC<IProps> = (props) => {
  const { employeeId, applicantId } = props;
  const [employee, setEmployee] = useState<IEmployee>();
  const [applicant, setApplicant] = useState<IApplicant>();

  const getEmployeeDetails = (employeeIdIn) => {
    getEmployee(employeeIdIn).then((employeeIn) => {
      setEmployee(employeeIn);
    });
  };

  const pullApplicant = async (applicantId: string) => {
    if (applicantId) {
      const response = await getApplicantById(applicantId);
      if (response) {
        setApplicant(response);
      }
    }
  };

  useEffect(() => {
    if (employeeId) {
      getEmployeeDetails(employeeId);
    }
  }, [employeeId]);

  useEffect(() => {
    if (applicantId) {
      pullApplicant(applicantId);
    }
  }, [applicantId]);

  return (
    <div className="employee-container">
      <Row gutter={[15, 32]}>
        <Col span={4}>
          <Link to={'/employees'}>
            <Space>
              <LeftOutlined style={{ fontSize: 20, color: 'black' }} />
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 0.2,
                  color: '#000000',
                  lineHeight: '20px',
                  marginLeft: 5
                }}
              >
                Employee Detail
              </Text>
            </Space>
          </Link>
        </Col>
        <Col offset={1} span={12}>
          {employee || applicant ? (
            <EmployeeForm employee={employee} applicant={applicant} />
          ) : (
            <span>Loading...</span>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeDetail;
