import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';
import { userFieldsFragment } from './user.fragment';

export const superintendentFieldsFragment = gql`
  fragment superintendentFields on Superintendent {
    ...entityFields
    user {
      ...userFields
    }
  }
  ${entityFieldsFragment}
  ${userFieldsFragment}
`;
