import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';
import { pagingFieldsFragment } from './pagination.fragment';
import { userFieldsFragment } from './user.fragment';
import { addressFieldFragment } from './address.fragment';
import { mediaFieldsFragment } from './media.fragment';

export const incidentFieldsFragment = gql`
  fragment incidentFields on Incident {
    title
    healthTypes
    operationalTypes
    occurredAt
    details
    location
    locationDepartment
    injureNature
    injureContact
    injureIllness
    bodyParts
    signatureFiles {
      ...mediaFields
    }
    firstAidNotes
    returnedToDuty
    witnesses {
      name
      statement
    }
    causes
    correctiveAppliedResponsible
    correctiveAppliedDescription
    correctiveAppliedDay
    createdBy {
      ...entityFields
    }

    order {
      ...entityFields
      customer {
        ...entityFields
        address {
          ...addressFieldFragment
        }
        customerNro
        phone
        name
        rating
      }
    }
    employee {
      ...entityFields
      user {
        ...userFields
      }
    }
    ...entityFields
  }

  ${entityFieldsFragment}

  ${mediaFieldsFragment}

  ${addressFieldFragment}

  ${userFieldsFragment}
`;

export const IncidentListQueryFragment = gql`
  fragment IncidentListQuery on Query {
    Incident(input: $incidentInput) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...incidentFields
      }
    }
  }

  ${incidentFieldsFragment}

  ${pagingFieldsFragment}
`;
