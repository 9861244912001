import React from 'react';
import {
  Col,
  Input,
  Row,
  Radio,
  Select,
  Slider,
  Button,
  Typography,
} from 'antd';
import { DEFAULT_FILTER } from './index';

const { Text } = Typography;

const EmployeeFilter = ({
  onSubmit,
  skillsAvailables,
  certificatesAvailables,
  filter,
  setFilter,
  showFilter,
}: {
  onSubmit: any;
  skillsAvailables: any;
  certificatesAvailables: any;
  filter: any;
  setFilter: any;
  showFilter: any;
}) => {
  return (
    <div hidden={showFilter}>
      <Row gutter={[24, 8]}>
        <Col span={4}>
          <Text className="filter-label">Name</Text>
          <Input
            value={filter?.nameLike}
            placeholder="Search"
            onChange={(e) => {
              setFilter({ ...filter, nameLike: e.target.value });
            }}
          />
        </Col>
        <Col span={4}>
          <Text className="filter-label">English Proficiency</Text>
          <Slider
            value={filter?.englishLevelGt}
            onChange={(value) =>
              setFilter({ ...filter, englishLevelGt: value })
            }
          />
        </Col>

        <Col span={5}>
          <div>
            <Text className="filter-label">Has transportation?</Text>
          </div>
          <Radio.Group
            value={filter?.transportation}
            onChange={(e) =>
              setFilter({ ...filter, transportation: e.target.value })
            }
          >
            <Radio.Button value>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row align="bottom" gutter={[24, 8]}>
        <Col span={6}>
          <Text className="filter-label">Certifications</Text>
          <Select
            value={filter ? filter.certificationsHasAll : []}
            mode="multiple"
            placeholder="Search"
            style={{ width: '100%' }}
            onChange={(certificates: string[]) => {
              setFilter({ ...filter, certificationsHasAll: certificates });
            }}
            optionFilterProp="label"
            filterOption
            options={certificatesAvailables.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        </Col>
        <Col span={6}>
          <Text className="filter-label">Skills</Text>
          <Select
            value={filter ? filter.skillsHasAll : []}
            mode="multiple"
            placeholder="Search"
            style={{ width: '100%' }}
            onChange={(skills: string[]) => {
              setFilter({ ...filter, skillsHasAll: skills });
            }}
            optionFilterProp="label"
            filterOption
            options={skillsAvailables.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        </Col>
        {/* <Col span={4}>
          <Text>Rating</Text>
          <Select
            value={filter?.ratingGt}
            placeholder="Search"
            style={{ width: '100%' }}
            onChange={(ratingGt: string) => {
              setFilter({ ...filter, ratingGt });
            }}
          >
            {Object.keys(ratings).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Col> */}
        <Col span={6}>
          <Row>
            <Button
              type="default"
              onClick={() => {
                setFilter(DEFAULT_FILTER);
                onSubmit(DEFAULT_FILTER);
              }}
              style={{
                color: 'white',
                fontSize: 15,
                background: '#BAC2C8',
                borderRadius: 4,
                marginRight: 5,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                height: 44,
                minWidth: 94,
              }}
            >
              Clear
            </Button>
            <Button
              type="default"
              onClick={() => onSubmit(filter)}
              style={{
                color: 'white',
                fontSize: 15,
                background: '#BAC2C8',
                borderRadius: 4,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                height: 44,
                minWidth: 94,
              }}
            >
              Search
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeFilter;
