import React from 'react';
import {DatePicker} from 'antd';
import moment from "moment";

const DateOnlyInput = (props) => (
  <DatePicker
    {...props}
    value={props.value ? moment(props.value, 'YYYY-MM-DD') : null}
    onChange={(date) => props.onChange(date ? date.format('YYYY-MM-DD') : null)}
  />
);

export default DateOnlyInput;
