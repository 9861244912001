import gql from 'graphql-tag';
import { paymentFieldsFragment } from '../../fragments/payment.fragment';
import { pagingFieldsFragment } from '../../fragments/pagination.fragment';

export const GetPaymentsForExport = gql`
    query PaymentGetDataForExport($input: PaymentGetDataForExportInput!) {
        PaymentGetDataForExport(input: $input) {
            employee
            task
            job
            hours
            rate
            total
        }
    }
`

export const GetActivePayment = gql`
  query {
    GetActivePayment {
      id
      periodStart
      periodEnd
      confirmedAt
      createdAt
      printedPdf
    }
  }
`;

export const GetArchivedPayments = gql`
  query GetArchivedPayments($input: GetArchivedPayments!) {
    GetArchivedPayments(input: $input) {
      total
      data {
        id
        periodStart
        periodEnd
        confirmedAt
        createdAt
      }
    }
  }
`;

export const GetPaymentLineItems = gql`
  query GetPaymentLineItems($input: GetPaymentLineItemInput!) {
    GetPaymentLineItems(input: $input) {
      data {
        avgRate
        timeSpentSeconds
        total
        employee {
          id
          user {
            profile {
              firstName
              lastName
            }
          }
        }
        lineItems {
          order {
            jobNumber
            customer {
              name
            }
          }
          _id
          type
          rate
          total
          task
          timeSpentSeconds
        }
      }
      total
    }
  }
`;

export const UpdatePaymentLineItem = gql`
  mutation UpdatePaymentLineItem($input: UpdatePaymentLineItemInput!) {
    UpdatePaymentLineItem(input: $input) {
      _id
      rate
      total
    }
  }
`;

export const UpdatePaymentLineItemQb = gql`
  mutation UpdatePaymentLineItemQb($input: UpdatePaymentLineItemQbInput!) {
    UpdatePaymentLineItemQb(input: $input) {
      allSent
    }
  }
`;

export const GetNextPaymentDatesQuery = gql`
  query GetNextPaymentDates {
    GetNextPaymentDates {
      periodStart
      periodEnd
    }
  }
`;

export const GeneratePaymentMutation = gql`
  mutation GeneratetPayment($input: GenerateNextPaymentInput!) {
    GeneratePayment(input: $input) {
      id
    }
  }
`;

export const ListPaymentsQuery = gql`
  query Payment($input: PaymentPagingInput) {
    ...PaymentListQuery
  }
  fragment PaymentListQuery on Query {
    Payment(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...Payment
      }
    }
  }
  ${paymentFieldsFragment}
  ${pagingFieldsFragment}
`;

export const UpdatePrintedPdfDatePaymentMutation = gql`
  mutation PaymentUpdate($input: PaymentUpdateInput!) {
    PaymentUpdate(input: $input) {
      id
      printedPdf
    }
  }
`;

export const ConfirmPaymentMutation = gql`
  mutation PaymentUpdate($input: PaymentUpdateInput!) {
    PaymentUpdate(input: $input) {
      id
      confirmedAt
    }
  }
`;

export const PaymentUpdateMutation = gql`
  mutation PaymentUpdate($input: PaymentUpdateInput!) {
    PaymentUpdate(input: $input) {
      ...Payment
    }
  }
  ${paymentFieldsFragment}
`;

export const RemovePaymentMutation = gql`
  mutation PaymentRemove($input: RemovePaymentInput!) {
    PaymentRemove(input: $input) {
      message
    }
  }
`;
