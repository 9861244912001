import React, { useEffect, useState } from 'react';
import {
  Col,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Typography
} from 'antd';
import { formatTimeSpentSeconds } from '../../../../../core/helpers/utilities';
import { UpdateInvoiceLineItemReq } from '../../../../../core/services/invoice/invoice.service';
import { UpdatePaymentLineItemReq } from '../../../../../core/services/payment/payment.service';

const { Text } = Typography;

interface IData {
  key: string;
  name: string;
  rate: number;
  secondToHours: string;
  task: string;
  timeSpentSeconds: number;
  total: number;
  type: string;
  __typename: string;
  _id: string;
}

interface IProps {
  data: IData;

  onOk: () => void;
  onCancel: () => void;
}

const EditInvoiceModal = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [result, setResult] = useState<string>();
  const [newRate, setNewRate] = useState<number>(props.data.rate);

  const calcTotalMoney = (rate, timeSpentSeconds) => {
    const scale = rate / 3600;
    const totalSpent = scale * timeSpentSeconds;
    return totalSpent.toFixed(3);
  };

  useEffect(() => {
    if (props.data) {
      setResult(calcTotalMoney(props.data.rate, props.data.timeSpentSeconds));
      setVisible(true);
    }
  }, [props]);

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await UpdatePaymentLineItemReq(props.data._id, newRate);
      setConfirmLoading(false);
      setVisible(false);
      props.onOk();
    } catch (error) {
      setConfirmLoading(false);
      message.error('Error Updating the line item');
    }
  };

  const handleCancel = () => {
    setVisible(false);
    props.onCancel();
  };

  return (
    <>
      <Modal
        title="Rate editor"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Space style={{ marginBottom: '1rem' }}>
          <h3>{props.data?.name}</h3>
        </Space>
        <Row align="middle" gutter={[15, 32]}>
          <Col span={8}>
            <Row justify="center" align="top">
              <Col>
                <Text>Rate</Text>
              </Col>
            </Row>
            <Row align="middle">
              <Col span={4}>
                <Text>$ </Text>
              </Col>
              <Col span={16}>
                <InputNumber
                  type="number"
                  min={0}
                  step="0.01"
                  onChange={(number) => {
                    if (!number || number === '') {
                      setResult('0');
                      return;
                    }
                    let rate: number = 0;
                    if (typeof number === 'number') {
                      rate = number;
                    } else {
                      rate = parseFloat(number);
                    }
                    setNewRate(rate);
                    setResult(
                      calcTotalMoney(rate, props.data.timeSpentSeconds)
                    );
                    return;
                  }}
                  defaultValue={props.data?.rate}
                  size="large"
                  placeholder="rate"
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="center" align="top">
              <Col>
                <Text>Hours</Text>
              </Col>
            </Row>
            <Row align="middle">
              <Col span={4}>
                <Text>X</Text>
              </Col>
              <Col span={16}>
                <Input
                  style={{ border: 'none' }}
                  readOnly
                  size="large"
                  placeholder="hours"
                  value={
                    formatTimeSpentSeconds(props.data.timeSpentSeconds) || ''
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col style={{ backgroundColor: '#F2F7FA' }} span={8}>
            <Row justify="center" align="top">
              <Col>
                <Text>Total</Text>
              </Col>
            </Row>
            <Row align="middle">
              <Col span={4}>
                <Text strong>$ </Text>
              </Col>
              <Col span={16}>
                <Input
                  style={{
                    fontWeight: 'bold'
                  }}
                  size="large"
                  readOnly
                  placeholder="total"
                  value={result || ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditInvoiceModal;
