import React from 'react';
import lodash from 'lodash';
import { Col, Typography, Input, Select, Form } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ISkill } from '../../../core/interfaces/skill.interface';

const { Text } = Typography;

const ScheduleForm = ({
                        quantityEmployees,
                        checkSkillQuantityNeeded,
                        employee,
                        workerGroups,
                        onRemove,
                        name,
                        fieldKey,
                        availableSkills,
                        ...props
                      }) => {
  const freeSkills = lodash.intersectionBy(
    employee.Skills.data,
    workerGroups.map(({ skill }) => skill),
    ({ id }) => id
  ) as ISkill[];

  return (
    <Col span={12}>
      <div
        style={{
          border: '1px solid #d7dee2',
          boxSizing: 'border-box',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          margin: 8,
          paddingLeft: 10
        }}
      >
        <Col span={6}>
          {
            employee &&
            <Text className="row-text fs-12">
              {employee.user.profile.firstName} {employee.user.profile.lastName}
            </Text>
          }

        </Col>
        <Col span={3}>
          <Text className="row-text fs-10"> pay rate </Text>
          <Form.Item
            {...props}
            name={[name, 'payRate']}
            fieldKey={[fieldKey, 'payRate']}
          >
            <Input style={{ minWidth: 40 }}/>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Text className="row-text fs-11"> bill rate </Text>
          <Form.Item
            {...props}
            name={[name, 'billRate']}
            fieldKey={[fieldKey, 'billRate']}
          >
            <Input style={{ minWidth: 40 }}/>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Text className="row-text fs-11"> WCF </Text>
          <Form.Item
            {...props}
            name={[name, 'wcf']}
            fieldKey={[fieldKey, 'wcf']}
          >
            <Input style={{ minWidth: 40 }}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text className="row-text fs-11"> skill </Text>
          <Form.Item
            {...props}
            name={[name, 'skill']}
            fieldKey={[fieldKey, 'skill']}
            rules={[
              {
                validator: async (rule, value) => {
                  if (value === null) throw Error('field required');
                  if (checkSkillQuantityNeeded(value))
                    throw new Error('skill already full.');
                }
              }
            ]}
          >
            <Select>
              {availableSkills.map((skill) => (
                <Select.Option
                  className="row-text fs-11"
                  key={skill?.id}
                  value={skill?.id}
                >
                  {skill?.name}
                </Select.Option>
              ))}
              {/*{freeSkills.map((skill) => (
                <Select.Option
                  className="row-text fs-11"
                  key={skill?.id}
                  value={skill?.id}
                >
                  {skill?.name}
                </Select.Option>
              ))}*/}
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}>
          <CloseCircleOutlined
            onClick={() => {
              onRemove(employee);
            }}
          />
        </Col>
      </div>
    </Col>
  );
};
export default ScheduleForm;
