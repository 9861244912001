import gql from 'graphql-tag';

const CostCodeFragment = gql`
    fragment CostCode on CostCode{
        id
        code
        description
    }
`;

export const CostCodesListQuery = gql`
    query getCostCodes{
        getCostCodes{
            ...CostCode
        }
    }
    ${CostCodeFragment}
`;

export const CostCodeCreate = gql`
    mutation createCostCode($input: createCostCodeInput!) {
        createCostCode(input: $input) {
            ...CostCode
        }
    }
    ${CostCodeFragment}
`;

export const CostCodeUpdate = gql`
    mutation updateCostCode($input: updateCostCodeInput!) {
        updateCostCode(input: $input) {
            ...CostCode
        }
    }
    ${CostCodeFragment}
`;

export const CostCodeDelete = gql`
    mutation deleteCostCode($input: deleteCostCodeInput!) {
        deleteCostCode(input: $input) {
            ...CostCode
        }
    }
    ${CostCodeFragment}
`;
