import React, { useEffect, useState, useRef } from 'react';
import { Upload, Button, Typography } from 'antd';
import { getFileNameFromUrl } from '../helpers/utilities';
import { IMedia } from '../interfaces/media.interface';
import { Token } from '../helpers/storage';

interface IFile {
  uid: string;
  url?: string | undefined;
  name: string;
  response?: IMedia;
}

const { Text } = Typography;
function media2File(media: IMedia, fileList): IFile {
  const file = fileList.find((ff) => ff?.response?.id === media.id);
  return file
    ? {
        ...file,
        url: media.url,
      }
    : {
        uid: media.id,
        url: media.url,
        name: getFileNameFromUrl(media.url),
        response: null,
        status: 'done',
      };
}

function file2Media(file: IFile): IMedia {
  return (
    file.response || {
      id: file.uid,
      url: file?.url || '',
      mediaType: 'Document',
    }
  );
}

export default function FileInput(props) {
  const {
    value,
    onChange,
    disabled,
    onError,
  }: {
    value?: IMedia[];
    onChange?;
    disabled?: boolean;
    onError?;
  } = props;
  const vvalue = value || [];
  const valueMounted = useRef<boolean>(false);
  const [valueUidMapper, setUidMapper] = useState<IFile[]>(
    vvalue.map((mm) => media2File(mm, []))
  );
  useEffect(() => {
    if (valueMounted.current) {
      onChange(valueUidMapper.map(file2Media));
    }
    valueMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueUidMapper]);
  const token = Token();
  if (!token) {
    return null;
  }
  const fileList = vvalue.map((mm) => media2File(mm, valueUidMapper));
  return (
    <Upload
      {...props}
      data={{
        fileType: 'Document',
      }}
      action={`${process.env.REACT_APP_REST_ENDPOINT}file-upload`}
      headers={{
        Authorization: `Bearer ${token?.jwtBearer}`,
      }}
      fileList={fileList}
      onChange={(ev) => {
        if (ev.file.status === 'uploading') {
          return;
        }
        if (ev.file.status === 'done') {
          setUidMapper(ev.fileList);
          return;
        }
        if (ev.file.status === 'error') {
          onError(`${ev.file.name} file upload failed.`);
          return;
        }
        if (ev.file.status === 'removed') {
          setUidMapper(ev.fileList);
          return;
        }
        throw new Error('Unknown status');
      }}
      multiple
      disabled={disabled}
    >
      <Button
        style={{
          background: '#E8EAEC',
          border: '1px solid rgba(0, 0, 0, 0.17)',
          boxSizing: 'border-box',
          borderRadius: 3,
        }}
      >
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: 14,
            lineHeight: '20px',
            letterSpacing: '0.2px',
            color: '#636363',
          }}
        >
          Choose File
        </Text>
      </Button>
    </Upload>
  );
}
