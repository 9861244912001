import axios from 'axios';

const ENDPOINT = process.env.REACT_APP_QUICK_BOOKS_ENDPOINT;

const quickBooksApi = axios.create({
  baseURL: ENDPOINT,
});
// quickBooksApi.interceptors.response.use(
//   (response) => {
//     if (response.status >= 200 && response.status < 400) {
//       return response.data;
//     }
//     throw new Error('Internal error');
//   },
//   (error) => {
//     throw new Error(error.message || 'Internal Error');
//   }
// );

export default quickBooksApi;
