import { IToken } from '../interfaces/auth.interface';

/**
 * Get/Set token in local storage.
 * NULL parameter will return token from local storage.
 * IToken paramter will stora the token in local storage.
 *  @param token IToken or null
 *  @returns token IToken
 */
export const Token = (token?): IToken | null => {
  if (token) {
    localStorage.setItem('FillerUpToken', JSON.stringify(token));
    return token;
  }
  const dd = localStorage.getItem('FillerUpToken');
  if (dd === null) {
    return null;
  }
  try {
    return JSON.parse(dd as string) as IToken;
  } catch (err) {
    if (err instanceof SyntaxError) {
      return null;
    }
    throw err;
  }
};

export const Clear = (key: string) => {
  if (key === '*') {
    localStorage.clear();
    return;
  }
  localStorage.removeItem(key);
};
