import React from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { decodeSelectedAddress } from '../helpers/maps';

const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

const AddressInput = React.forwardRef<any, any>(
  ({ onChange, value, defaultValue = {}, id, ...props }, ref) => (
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_PLACES_API_KEY}
      minLengthAutocomplete={3}
      debounce={500}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['us', 'ca'],
        },
      }}
      selectProps={{
        ref,
        onChange: (ress) => {
          geocodeByAddress(ress.label)
            .then(([result]) => {
              return result
                ? getLatLng(result).then(({ lat, lng }) => ({
                    ...decodeSelectedAddress({ name: ress.label, ...result }),
                    lat: `${lat}`,
                    long: `${lng}`,
                  }))
                : null;
            })
            .then(onChange);
        },
        // value: value && value.name,
        value: value
          ? {
              label: value.name,
              value: {
                //   description: "Montevallo, AL, USA",
                //   matched_substrings: [
                //     {"length":6,"offset":0},
                //   ],
                //   place_id:"ChIJlXYBzMcriYgR0YZood7CTQE",
                //   reference:"ChIJlXYBzMcriYgR0YZood7CTQE",
                //   structured_formatting: {
                //     main_text:"Montevallo",
                //     main_text_matched_substrings: [
                //       {"length":6,"offset":0},
                //     ],
                //     secondary_text:"AL, USA",
                //   },
                //   terms: [
                //     {"offset":0,"value":"Montevallo"},
                //     {"offset":12,"value":"AL"},
                //     {"offset":16,"value":"USA"},
                //   ],
                //   types: [
                //     "locality","political","geocode",
                //   ],
              },
            }
          : null,
        defaultValue: defaultValue
          ? {
              label: defaultValue.name,
            }
          : null,
        ...props,
      }}
    />
  )
);

export default AddressInput;
