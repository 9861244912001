import gql from 'graphql-tag';

export const GetUsersQuery = gql`
  query Users($input: UserPagingInput!) {
    User(input: $input) {
      pagingMeta {
        count
        startIndex
        endIndex
        hasNextPage
      }
      data {
        id
        username
        status
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const DeleteUserMutation = gql`
  mutation UserDelete($input: EntityDeleteInput!) {
    UserDelete(input: $input) {
      id
    }
  }
`;

export const ChangeUserStatusMutation = gql`
  mutation ChangeUserStatus($input: ChangeUserStatusInput!) {
    ChangeUserStatus(input: $input) {
      id
    }
  }
`;
