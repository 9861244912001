import gql from 'graphql-tag';

const CertificationFragment = gql`
    fragment Certification on Certification{
        id
        name
        description
    }
`;

export const CertificationsListQuery = gql`
    query CertificationList($input: CertificationPagingInput) {
        Certification(input: $input) {
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
            data {
                id
                name
            }
        }
    }
`;

export const CertificationCreate = gql`
    mutation CertificationCreate($input: CertificationCreate!) {
        CertificationCreate(input: $input) {
            ...Certification
        }
    }
    ${CertificationFragment}
`;

export const CertificationUpdate = gql`
    mutation CertificationUpdate($input: CertificationUpdate!) {
        CertificationUpdate(input: $input) {
            ...Certification
        }
    }
    ${CertificationFragment}
`;

export const CertificationDelete = gql`
    mutation CertificationDelete($input: EntityDeleteInput) {
        CertificationDelete(input: $input) {
            ...Certification
        }
    }
    ${CertificationFragment}
`;
