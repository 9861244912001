import React from 'react';
import { Typography } from 'antd';
import CertificationIcon from './CertificationIcon';

const { Text } = Typography;

const CertificationIconText = ({ name }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      lineHeight: '12px',
    }}
  >
    <CertificationIcon name={name} />
    <Text className="skill-text">{name}</Text>
  </div>
);

export default CertificationIconText;
