import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, Form, Input, Row, Spin, message } from 'antd';
import { LockOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Rule } from 'rc-field-form/lib/interface';
import { LogIn } from '../../core/services/auth/auth.service';
import { Token } from '../../core/helpers/storage';
import { isPhone } from '../../core/helpers/phone';
import { isEmail } from '../../core/helpers/email';

const Login = ({initialMsg} : {initialMsg?}) => {
  const [error, setError] = useState(null);
  const [recoveryMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(
    () => {
      if (initialMsg) {
        message.error(initialMsg);
      }
    },
    [initialMsg]
  );

  const submitLogin = (values) => {
    setError(null);
    setLoading(true);
    LogIn(values).then(
      (token) => {
        Token(token);
        setLoading(false);
        history.push('/employees');
      },
      (err) => {
        setLoading(false);
        setError(err.message);
      }
    );
  };

  return (
    <div>
      <Form
        layout="vertical"
        name="login-form"
        onFinish={(values) => submitLogin(values)}
        size="large"
      >
        {recoveryMessage && (
          <Alert
            style={{ marginBottom: '15px' }}
            message={recoveryMessage}
            type="info"
            showIcon
          />
        )}
        {error && (
          <Alert
            style={{ marginBottom: '15px' }}
            message={error}
            type="error"
            showIcon
          />
        )}
        <Form.Item
          name="username"
          rules={
            [
              { type: 'string' },
              {
                async validator(rule, value) {
                  if (!value) {
                    throw new Error('Please input your phone or email!');
                  }
                  if (!isPhone(value) && !isEmail(value)) {
                    throw new Error('Value is not phone nor an email!');
                  }
                },
              },
            ] as Rule[]
          }
        >
          <Input placeholder="Phone or Email" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
          style={{ marginBottom: 0 }}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>
        <Row justify="center">
          <Form.Item style={{ marginTop: '25px', width: '150px' }}>
            <Button block type="primary" htmlType="submit">
              LOG IN
              {loading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      spin
                      style={{ color: 'white', marginLeft: 15 }}
                    />
                  }
                />
              )}
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <Row justify="center">
        Forgot Password?&nbsp;
        <Link to="/auth/request-password-change">Reset now.</Link>
      </Row>
    </div>
  );
};

export default Login;
