import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import { IJob } from '../../interfaces/job.interface';
import { Job, JobCreate, JobDelete, JobsQuery, JobUpdate } from './job.gql';

export const getJobs = () => {
  return new Promise<IPaginationWrapper<IJob>>((resolve, reject) => {
    FUESApolloClient()
      .query({
        query: JobsQuery
      })
      .then((resp: ApolloQueryResult<any>) => {
        resolve(resp.data.Jobs);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createJob = (values: IJob) => {
  return new Promise<IJob>((resolve, reject) => {
    FUESApolloClient()
      .mutate({
        mutation: JobCreate,
        variables: {
          input: {
            ...values
          }
        }
      })
      .then((resp: any) => {
        resolve(resp.data.JobCreate);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateJob = (values: IJob) => {
  return new Promise<IJob>((resolve, reject) => {
    FUESApolloClient()
      .mutate({
        mutation: JobUpdate,
        variables: {
          input: {
            ...values
          }
        }
      })
      .then((resp: any) => {
        resolve(resp.data.JobUpdate);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getJobById = (id: string) => {
  return new Promise<IJob>((resolve, reject) => {
    FUESApolloClient()
      .query({
        query: Job,
        variables: {
          input: {
            id
          }
        }
      })
      .then((resp: any) => {
        resolve(resp.data.Job);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteJob = (id: string) => {
  return new Promise<IJob>((resolve, reject) => {
    FUESApolloClient()
      .mutate({
        mutation: JobDelete,
        variables: {
          input: {
            id
          }
        }
      })
      .then((resp: any) => {
        resolve(resp.data.JobDelete);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
