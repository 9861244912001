import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  message,
  Typography,
  Upload
} from 'antd';
import { RightOutlined, UploadOutlined } from '@ant-design/icons';
import { getAllCustomers } from '../../../core/services/customer/customer.service';
import { ICustomer } from '../../../core/interfaces/customer.interface';
import { Token } from '../../../core/helpers/storage';
import {
  formatAddressDumb,
  formatPhone
} from '../../../core/helpers/utilities';

const { Search } = Input;
const { Text } = Typography;

const columns = [
  // TODO: alter dataIndex attributes to match which data needs to be pulled per object
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <Text className="row-text">{name}</Text>
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    render: (rating) => <Text className="row-text">{rating}</Text>
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    render: (phone) => <Text className="row-text">{formatPhone(phone)}</Text>
  },
  {
    title: 'Address',
    render: (customer) => (
      <Text className="row-text">{formatAddressDumb(customer.address)}</Text>
    )
  },
  {
    title: '',
    render: () => (
      <RightOutlined
        style={{ fontSize: 15, color: '#465968', cursor: 'pointer' }}
      />
    )
  }
];

const Customers = () => {
  const token = Token();
  const history = useHistory();
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<String>('loading');
  const fetchObjects = (param) => {
    setFetchStatus('loading');
    getAllCustomers(param).then(
      (resp: any) => {
        setCustomers(resp.data);
        setFetchStatus('success');
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
  };
  useEffect(() => {
    fetchObjects({});
  }, []);

  const onUploadCustomerChange = (info) => {
    console.log(info);
  };

  if (!token) {
    return null;
  }
  if (valueFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }
  if (valueFetchStatus === 'error') {
    return <span>Error...</span>;
  }
  return (
    <div className="customers-container">
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={8}>
          <Row>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#000000',
                marginBottom: 0
              }}
            >
              Customers
            </Text>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="center">
            <Search
              placeholder="Search"
              onSearch={(search) => {
                const name = search.trim() !== '' ? search : null;
                return fetchObjects(name ? { name } : {});
              }}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="center">
            <Upload
              action={`${process.env.REACT_APP_REST_ENDPOINT}quickbook/csv`}
              headers={{
                Authorization: `Bearer ${token.jwtBearer}`
              }}
            >
              <Button>
                <UploadOutlined />
                Load from QuickBooks CSV file
              </Button>
            </Upload>
          </Row>
        </Col>
      </Row>

      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <Table
            dataSource={customers}
            columns={columns}
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomCenter'],
              defaultPageSize: 9
            }}
            rowKey={({ id }) => id}
            onRow={(record) => ({
              onClick: () => {
                history.push(`customers/${record.id}`);
              }
            })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Customers;
