import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, DatePicker, message, Skeleton, Space, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PaymentsTable from './components/PaymentsTable';
import ArchivedTable from './components/ArchivedTable';
import {
  IActivePayment,
  IDataTablePayments,
  INextPaymentDate
} from '../../../../core/interfaces/payment.interface';
import {
  ActivePayment,
  confirmPayment,
  generatePayment,
  getNextPaymentDates,
  RemovePayment,
  UpdatePrintedPdfDatePayment
} from '../../../../core/services/payment/payment.service';
import './index.scss';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

const Payments = () => {
  const [rangeDateSelected, setRangeDateSelected] = useState<any[] | any>(null);
  const [activePayment, setactivePayment] = useState<IActivePayment | null>(
    null
  );
  const [isConfirmedPayment, setIsConfirmedPayment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [totalLines, setTotalLines] = useState<number>();
  const [reloadArchived, setRealoadArchived] = useState<number>(0);
  const [dataTable, setDataTable] = useState<IDataTablePayments[]>([]);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getActivePayments();
  }, []);

  const getActivePayments = async () => {
    setIsLoading(true);
    setIsConfirmedPayment(false);
    try {
      const data: IActivePayment = await ActivePayment();
      if (data) {
        setactivePayment(data);
        data.confirmedAt && setIsConfirmedPayment(true);
        setIsLoading(false);
      } else {
        setactivePayment(null);
        getNextPaymentDatesReq();
      }
    } catch (err) {
      message.error('Error calling active Payments!');
    }
  };

  const getNextPaymentDatesReq = async () => {
    try {
      const data: INextPaymentDate = await getNextPaymentDates();

      if (data) {
        const periodStart = moment(data.periodStart);
        const periodEnd = moment(data.periodEnd);

        setRangeDateSelected([periodStart, periodEnd]);
        setIsLoading(false);
      }
    } catch (error) {
      setRangeDateSelected(null);
      setIsLoading(false);
      message.error('No payments yet');
    }
  };

  const generatePaymentReq = async () => {
    setLoadingBtn(true);
    const [start, end]: any = rangeDateSelected;
    try {
      const data: IActivePayment = await generatePayment(
        start.toISOString(),
        end.toISOString()
      );
      setLoadingBtn(false);
      setactivePayment(data);
      message.success('Payment generated!');
    } catch (error) {
      setLoadingBtn(false);
      message.error('There are no reports for the current period');
    }
  };

  const confirmPaymentReq = async () => {
    setLoadingBtn(true);
    try {
      const data: IActivePayment = await confirmPayment(activePayment?.id);
      if (data.confirmedAt) {
        setIsConfirmedPayment(true);
        message.success('Payment confirmed!');
        onNewPayment();
        setRealoadArchived(reloadArchived + 1);
        setLoadingBtn(false);
      }
    } catch (error) {
      setLoadingBtn(false);
      message.error('Error confirming payment!');
    }
  };

  const removePaymentReq = async () => {
    setIsLoading(true);
    try {
      await RemovePayment(activePayment?.id);
      message.success('Payment removed!');
      setactivePayment(null);
      setIsConfirmedPayment(false);
      getNextPaymentDatesReq();
    } catch (error) {
      setIsLoading(false);
      message.error('Error removing payment!');
    }
  };

  /*const printPdf = async () => {
    if (dataTable && dataTable.length > 0) {
      try {
        if (!isPrinted) {
          setIsLoading(true);
          await UpdatePrintedPdfDatePayment(activePayment?.id);
          setRealoadArchived(reloadArchived + 1);
          setIsPrinted(true);
          setIsLoading(false);
          onNewPayment();
        }
        let tableRows: any = [];
        dataTable.forEach((payment) => {
          let childrensRows: any = [];
          payment.children.forEach((children) => {
            const childrenRow = [
              { text: children.name },
              children.customer,
              children.order.jobNumber,
              children.secondToHours,
              children.rate,
              children.total
            ];
            childrensRows.push(childrenRow);
          });
          const employeeRow = [
            { text: payment.name, bold: true, fontSize: 14 },
            payment.customer,
            '-',
            payment.secondToHours,
            payment.rate,
            payment.total
          ];
          tableRows.push(employeeRow, ...childrensRows);
        });

        const PaymentsDocDefinition: TDocumentDefinitions = {
          info: {
            title: 'Payments Document',
            author: 'Fillerup'
          },
          pageSize: 'A4',
          pageMargins: 16,
          pageOrientation: 'landscape',
          styles: {
            title: {
              fontSize: 18,
              margin: [0, 0, 0, 15]
            }
          },
          content: [
            { text: `Payments`, style: 'title' },
            {
              table: {
                widths: [170, 170, '*', '*', '*', '*'],
                body: [
                  ['Name', 'Customer', 'Job #', 'Hours', 'Rate', 'Total'],
                  ...tableRows
                ]
              }
            }
          ]
        };

        const fonts: any = {
          Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-MediumItalic.ttf',
            bolditalics: 'Roboto-Italic.ttf'
          }
        };

        pdfMake
          .createPdf(
            PaymentsDocDefinition,
            undefined,
            fonts,
            pdfFonts.pdfMake.vfs
          )
          .open();
      } catch (error) {
        setIsPrinted(false);
        setIsLoading(false);
        message.error('Error printing PDF');
      }
    }
  };*/

  const onNewPayment = () => {
    setIsLoading(true);
    setactivePayment(null);
    setIsConfirmedPayment(false);
    getNextPaymentDatesReq();
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : activePayment ? (
        <>
          <div className="top__container">
            <Tooltip title="Delete payment">
              <Button
                type="primary"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={removePaymentReq}
              />
            </Tooltip>

            <Button
              loading={loadingBtn}
              type="primary"
              size="middle"
              disabled={!(activePayment && totalLines)}
              onClick={confirmPaymentReq}
            >
              {'CONFIRM PAYMENT'}
            </Button>
          </div>
          <PaymentsTable
            onTotalLines={(total) => setTotalLines(total)}
            payment={activePayment}
            disableEdit={isConfirmedPayment}
            onDataTable={setDataTable}
          />
        </>
      ) : (
        <Space className="date__picker" direction="horizontal" size={12}>
          <RangePicker
            allowEmpty={[false, false]}
            onChange={setRangeDateSelected}
            value={rangeDateSelected}
          />
          <Button
            loading={loadingBtn}
            type="primary"
            size="middle"
            disabled={!rangeDateSelected}
            onClick={generatePaymentReq}
          >
            GENERATE PAYMENT
          </Button>
        </Space>
      )}
      <ArchivedTable reload={reloadArchived} />
    </>
  );
};

export default Payments;
