export default {
  Male: {
    label: 'Male',
  },
  Female: {
    label: 'Female',
  },
  NA: {
    label: 'N/A',
  },
};
