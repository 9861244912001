import { PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, message, Spin, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import '../index.scss';
import {
  createCostCode,
  deleteCostCode,
  getAllCostcodes,
  updateCostCode
} from '../../../../core/services/costCode/costCode.service';
import { ICostCode } from '../../../../core/interfaces/costCode.interface';
const { Panel } = Collapse;
const { Search } = Input;

const CostcodesList = () => {
  const [tags, setTags] = useState<ICostCode[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const input: any = useRef(null);
  const editInput: any = useRef(null);

  const fetchData = () => {
    getAllCostcodes().then((resp) => {
      setTags(resp);
    });
  };

  const handleClose = async (removedTag: ICostCode) => {
    try {
      setLoading(true);
      const response = await deleteCostCode(removedTag.id);
      if (response) {
        const filteredTags = tags.filter((tag) => tag.id !== removedTag.id);
        setTags(filteredTags);
      }
    } catch (error) {
      message.error('Error removing the cost code!');
    }
    setLoading(false);
  };

  const showInput = () => {
    setInputVisible(true);
    input.current && input.current.focus();
  };


  const handleInputConfirm = async (value) => {
    if (!value || value.length === 0) {
      message.error('You have to enter something!');
      return;
    }
    try {
      if (
        tags.map((tag) => tag.code).indexOf(value) === -1
      ) {
        setLoading(true);
        const costCode = await createCostCode({ code: value });
        if (costCode) {
          const newTags = [...tags, { id: costCode.id, code: costCode.code }];
          setTags(newTags);
        }
      } else {
        message.error('Cost code already exists');
        return;
      }
    } catch (error) {
      message.error('Error creating the cost code!');
    }
    setLoading(false);
    setInputVisible(false);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = async (tagId) => {
    if (!editInputValue || editInputValue.length === 0) {
      message.error('You have to enter something!');
      return;
    }
    const index = tags.findIndex(tag => tag.id === tagId);

    if (index < 0) {
      message.error('There\'s been an error! Try again.');
      return;
    }

    try {
      setLoading(true);
      const costCode = await updateCostCode({ id: tags[index].id, code: editInputValue });
      if (costCode) {
        const newTags = [...tags];

        newTags[index].code = editInputValue;

        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
      }

    } catch (error) {
      message.error('There\'s been an error updating the cost code!');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Collapse defaultActiveKey={['1']} onChange={() => {
    }}>
      <Panel
        header={
          <h1 className='collapse__title'>Cost Codes (default list) <Spin spinning={loading} size={'large'} /></h1>}
        key='1'
      >

        <>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInput}
                  key={tag.id}
                  size='small'
                  className='tag-edit'
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  addonAfter={<Button size={'small'} type={'link'} onClick={() => handleEditInputConfirm(tag.id)}>Save</Button>}
                />
              );
            }

            const isLongTag = tag.code.length > 20;

            const tagElem = (
              <Tag
                className='edit-tag'
                key={tag.id}
                closable={true}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    setEditInputIndex(index);
                    setEditInputValue(tag.code);
                    editInput.current && editInput.current.focus();
                    e.preventDefault();
                  }}
                >
                  {isLongTag ? `${tag.code.slice(0, 20)}...` : tag.code}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag.code} key={tag.id}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible && (
            <Spin spinning={loading}>
              <Search
                enterButton='Create'
                allowClear={true}
                disabled={loading}
                loading={loading}
                type='text'
                size='small'
                className='tag-input'
                // value={inputValue}
                onSearch={handleInputConfirm}
                // onPressEnter={handleInputConfirm}
              />
            </Spin>
          )}
          {!inputVisible && (
            <Tag className='site-tag-plus' onClick={() => showInput()}>
              <PlusOutlined /> New Cost code
            </Tag>
          )}
        </>
      </Panel>
    </Collapse>
  );
};

export default CostcodesList;
