import React from 'react';
import { Col, Row, Typography } from 'antd';
import IncidentForm from './IncidentForm';

const { Text } = Typography;

const NewIncident = () => {
  return (
    <div className="incident-container">
      <Row justify="center" gutter={24}>
        <Col span={24}>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginBottom: 30,
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#000000',
                marginBottom: 0,
              }}
            >
              New Incident
            </Text>
          </div>
          <Row justify="center" align="middle" gutter={15}>
            <Col span={24}>
              <IncidentForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewIncident;
