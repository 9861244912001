import gql from 'graphql-tag';
import { scheduleFieldsFragment, scheduleBriefFieldsFragment } from '../../fragments/schedule.fragment';
import {pagingFieldsFragment} from "../../fragments/pagination.fragment";

export const CreateScheduleMutation = gql`
  mutation ScheduleCreate(
      $input: ScheduleCreate!
      $skillsInput: SkillPagingInput
  ) {
    ScheduleCreate(input: $input) {
      ...scheduleFields
    }
  }

  ${scheduleFieldsFragment}
`;

export const ScheduleListQuery = gql`
query ScheduleList($input: SchedulePagingInput) {
  ...ScheduleListQuery
}

fragment ScheduleListQuery on Query {
  Schedule(input: $input) {
    pagingMeta {
      ...pagingFields
    }
    data {
      ...scheduleBriefFields
    }
  }
}

  ${pagingFieldsFragment}
  ${scheduleBriefFieldsFragment}
`;

export const SendReminderMutation = gql`
  mutation ScheduleSendReminder($input: ScheduleSendReminderInput!) {
    ScheduleSendReminder(input: $input) {
      message
    }
  }
`;
