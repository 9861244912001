import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import {
    ICheckin,
} from '../../interfaces/checkin.interface';
import {
    CheckinListQuery,
    CheckinSaveCommentMutation,
} from './checkin.gql';
import {IPaginationWrapper} from "../../interfaces/graphql.interface";
import {addDates} from "../../helpers/utilities";

export const getAllCheckins = (args = {}) => {
  return FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
        // query: CustomerListQuery,
        query: CheckinListQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'name.asc',
          },
          args,
        },
      },
    })
.then((resp: ApolloQueryResult<{ Checkin: IPaginationWrapper<ICheckin> }>) => resp.data.Checkin).then(
          (rr) => ({
              ...rr,
              data: rr.data.map(
                  addDates
              ),
          })
      );
};

export const getCheckin = async (id) => {
    const ch = await getAllCheckins({ id });
    return ch.data[0];
};

export const saveComments = async (idd, comment) => {
    return FUESApolloClient()
        .mutate({
            mutation: CheckinSaveCommentMutation,
            variables: {
                input: {id: idd, comment},
            },
        }).then((resp) => resp.data.CheckinAddComment);
};
