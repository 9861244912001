import React, { useState, useEffect, FC } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  DatePicker,
  notification,
  Typography,
  Switch
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import PhoneInput from '../../../core/components/PhoneInput';
import {
  UniquePhone,
  UniqueEmail,
  WrongPhone
} from '../../../core/services/errorCodes';
import CertificationSelectControlled from '../../../core/components/CertificationSelectControlled';
import AddressInput from '../../../core/components/AddressInput';
import { getAllSkills } from '../../../core/services/skill/skill.service';
import { getAllCertifications } from '../../../core/services/certification/certification.service';
import {
  inviteEmployee,
  updateEmployee,
  reInviteEmployee
} from '../../../core/services/employee/employee.service';
import gender from '../../../core/enums/gender';
import { IEmployeeCertification } from '../../../core/interfaces/employeeCertification.interface';
import { ratings } from '../../../core/enums/rating.enum';
import LoadingButton from '../../../core/components/LoadingButton';
import { IEmployee } from '../../../core/interfaces/employee.interface';
import { IApplicant } from '../../../core/interfaces/applicant.interface';

const { Text } = Typography;

interface IProps {
  employee?: any;
  applicant?: IApplicant | undefined;
}

const EmployeeForm: FC<IProps> = (props) => {
  const { employee, applicant } = props;
  const history = useHistory();
  const [loadingInvitation, setLoadingInvitation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [skills, setSkiils] = useState([]);
  const [valueAvailableCertifications, setAvailableCertifications] = useState(
    []
  );
  const [certificates, setCertificates] = useState<IEmployeeCertification[]>(
    employee
      ? employee.certifications.map(({ catalog, ...certification }) => ({
          ...certification,
          catalog: catalog?.id
        }))
      : []
  );

  const [form] = Form.useForm();

  useEffect(() => {
    getAllSkills().then((resp: any) => {
      setSkiils(resp.data);
    });
    getAllCertifications().then((resp: any) => {
      setAvailableCertifications(resp.data);
    });
    // ToDo: This looks like a workaround for state handling race condition...
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  const onReset = () => {
    form.resetFields();
    setCertificates(
      employee
        ? employee.certifications.map(({ catalog, ...certification }) => ({
            ...certification,
            catalog: catalog?.id
          }))
        : []
    );
  };

  const onSubmit = (payload) => {
    if (certificates.some(({ media }) => !media)) {
      notification.open({
        message:
          'Please, attach a file for each certificate or remove the certification from list.',
        type: 'error'
      });
      return;
    }
    if (!payload.h2bVisa) {
      notification.open({
        message: 'Signed Safety Policy should be marked as "YES"',
        type: 'error'
      });
      return;
    }
    const nPayload = { ...payload };
    nPayload.certifications = certificates.map((certificate) => ({
      catalog: certificate.catalog,
      issuedAt: certificate.issuedAt,
      media: certificate.media.id
    }));
    // ToDo: Do this normalization on api layer
    nPayload.startsAt = moment(payload.startsAt)
      .startOf('day')
      .format('YYYY-MM-DD');

    let request;
    nPayload.disabled = !nPayload.noDisabled;
    delete nPayload.noDisabled;
    if (employee?.id) {
      delete nPayload.phone;
      delete nPayload.email;
      delete nPayload.firstName;
      delete nPayload.lastName;
      delete nPayload.address.__typename;
      // request = Promise.resolve({});
      // console.log('nPayload', nPayload);
      request = updateEmployee(employee.id, nPayload);
    } else {
      // request = Promise.resolve({});
      // console.log('nPayload', nPayload);
      request = inviteEmployee(nPayload);
    }
    request.then(
      () => {
        notification.open({
          message: 'Employee saved successfully.',
          type: 'success'
        });
        history.push('/employees');
      },
      (err) => {
        const uniquePhoneError = err.graphQLErrors.find(
          (err1) => err1.internalCode === UniquePhone
        );
        const wrongPhoneError = err.graphQLErrors.find(
          (err1) => err1.internalCode === WrongPhone
        );
        const uniqueEmailError = err.graphQLErrors.find(
          (err1) => err1.internalCode === UniqueEmail
        );
        let errorMsg;
        if (uniquePhoneError) {
          errorMsg = 'Phone already in use';
        } else if (wrongPhoneError) {
          errorMsg = 'Please, check the phone number';
        } else if (uniqueEmailError) {
          errorMsg = 'Email already in use';
        } else {
          errorMsg = employee?.id
            ? "Can't update the employee"
            : "Can't save the employee";
        }
        notification.open({
          message: errorMsg,
          type: 'error'
        });
      }
    );
  };

  if (loading) {
    return <span>Loading...</span>;
  }
  return (
    <div>
      <Form
        form={form}
        name="employee-form"
        onFinish={onSubmit}
        initialValues={
          employee
            ? {
                gender: employee.gender,
                foreman: employee.foreman,
                transportation: employee.transportation,
                nightShifts: employee.nightShifts,
                h2bVisa: employee.h2bVisa,
                englishLevel: employee.englishLevel,
                rating: employee.rating,
                notes: employee.notes,
                address: employee.address,
                skill_ids: employee.Skills.data.map((x) => x.id),
                startsAt: moment(employee.startsAt).startOf('day'),
                firstName: employee.user.profile.firstName,
                lastName: employee.user.profile.lastName,
                phone: employee.user.profile.phone,
                email: employee.user.profile.email,
                noDisabled: !employee.user.disabled
              }
            : applicant
            ? {
                firstName: applicant.firstname,
                lastName: applicant.lastname,
                phone: applicant.phone,
                email: applicant.email
              }
            : {
                noDisabled: true
              }
        }
      >
        <Card className="subtitle" title="Employee Information">
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label">first name</Text>
              <Form.Item name="firstName" rules={[{ required: true }]}>
                <Input
                  placeholder="First Name"
                  size="large"
                  disabled={employee?.id}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="new-form-label">last name</Text>
              <Form.Item name="lastName" rules={[{ required: true }]}>
                <Input
                  placeholder="Last Name"
                  size="large"
                  disabled={employee?.id}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={9}>
              <Text className="new-form-label"> phone number</Text>
              <Form.Item name="phone" rules={[{ required: true }]}>
                <PhoneInput disabled={employee?.id} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Text className="new-form-label"> email</Text>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input
                  type="email"
                  placeholder="Email"
                  size="large"
                  disabled={employee?.id}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={15}>
            <Col span={12}>
              <Text className="new-form-label"> Active</Text>
              <Form.Item
                name="noDisabled"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="enabled"
                  unCheckedChildren="disabled"
                />
              </Form.Item>
            </Col>
            {employee && employee.id && (
              <Col span={12}>
                {employee.user.active ? (
                  <Text className="new-form-label">
                    Employee already accepted the invitation
                  </Text>
                ) : (
                  <>
                    <Text className="new-form-label">
                      Employee hasn&apos;t accepted the invitation yet
                    </Text>
                    <br />
                    <LoadingButton
                      disabled={loadingInvitation}
                      onClick={() => {
                        setLoadingInvitation(true);
                        reInviteEmployee(employee.id)
                          .catch(() => {})
                          .then(() =>
                            setTimeout(() => setLoading(false), 5000)
                          );
                      }}
                    >
                      Send Invitation Again
                    </LoadingButton>
                  </>
                )}
              </Col>
            )}
          </Row>
          <Row gutter={15}>
            <Col span={24}>
              <Text className="new-form-label"> address</Text>
              <Form.Item name="address" rules={[{ required: true }]}>
                <AddressInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label"> gender</Text>
              <Form.Item name="gender" rules={[{ required: true }]}>
                <Radio.Group>
                  {Object.entries(gender).map(([kk, { label }]) => (
                    <Radio.Button key={kk} value={kk}>
                      {label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label"> foreman</Text>
              <Form.Item name="foreman" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label"> has own transportation</Text>
              <Form.Item name="transportation" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label">
                {' '}
                available for night shifts?
              </Text>
              <Form.Item name="nightShifts" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label">Signed Safety Policy</Text>
              <Form.Item name="h2bVisa" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label">starts at</Text>
              <Form.Item name="startsAt" rules={[{ required: true }]}>
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={24}>
              <Text className="new-form-label">skills</Text>
              <Form.Item name="skill_ids" rules={[{ required: true }]}>
                <Select
                  mode="multiple"
                  placeholder="Skills"
                  style={{ width: '100%' }}
                  optionFilterProp="label"
                  filterOption
                  options={skills.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <Text className="new-form-label">english proficiency</Text>
              <Form.Item name="englishLevel" rules={[{ required: true }]}>
                <Slider />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Text className="new-form-label">rating</Text>
              <Form.Item name="rating" rules={[{ required: true }]}>
                <Select style={{ width: '100%' }}>
                  {Object.keys(ratings).map((value) => (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={24}>
              <Text className="new-form-label">certifications</Text>
              <CertificationSelectControlled
                certificationChanged={setCertificates}
                certificates={certificates}
                availableCertifications={valueAvailableCertifications}
              />
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={24}>
              <Text className="new-form-label">notes</Text>
              <Form.Item name="notes">
                <Input.TextArea rows={4} placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              {employee && employee.id ? null : (
                <Button
                  onClick={onReset}
                  size="large"
                  className="button-cancel-form"
                >
                  <Text className="btn-label">Cancel</Text>
                </Button>
              )}
              <Button
                style={{ marginLeft: 10 }}
                className="button-new-form"
                htmlType="submit"
                size="large"
              >
                <Text className="btn-label">Save</Text>
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default EmployeeForm;
