import gql from 'graphql-tag';

export const SkillsListQuery = gql`
  query SkillList($input: SkillPagingInput) {
    Skill(input: $input) {
      pagingMeta {
        count
        startIndex
        endIndex
        hasNextPage
      }
      data {
        id
        name
      }
    }
  }
`;
