import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Form, Input, Row } from 'antd';
import { UserOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons';

import { Rule } from 'rc-field-form/lib/interface';

import { ChangePassword } from '../../core/services/auth/auth.service';
import { isPhone } from '../../core/helpers/phone';
import { isEmail } from '../../core/helpers/email';

const ResetPassword = (props) => {
  const [error, setError] = useState(null);
  const [recoveryMessage, setRecoveryMessage] = useState(null);

  const submit = (values) => {
    setError(null);
    ChangePassword(values)
      .then((result: any) => {
        setRecoveryMessage(result.message);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <div {...props}>
      <h1 style={{ fontSize: 18 }}>Reset Password</h1>
      <Form
        layout="vertical"
        name="login-form"
        onFinish={(values) => submit(values)}
        size="large"
      >
        {recoveryMessage && (
          <Alert
            style={{ marginBottom: '15px' }}
            message={recoveryMessage}
            type="info"
            showIcon
          />
        )}
        {error && (
          <Alert
            style={{ marginBottom: '15px' }}
            message={error}
            type="error"
            showIcon
          />
        )}
        <Form.Item
          name="username"
          rules={
            [
              { type: 'string' },
              {
                async validator(rule, value) {
                  if (!value) {
                    throw new Error('Please input your phone or email!');
                  }
                  if (!isPhone(value) && !isEmail(value)) {
                    throw new Error('Value is not phone nor an email!');
                  }
                },
              },
            ] as Rule[]
          }
        >
          <Input placeholder="Phone or Email" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[{ required: true, message: 'Please input your code!' }]}
        >
          <Input placeholder="Code" prefix={<KeyOutlined />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
            placeholder="New Password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Row justify="center">
          <Form.Item style={{ marginTop: '25px', width: '150px' }}>
            <Button block type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>

      <Row justify="center">
        Go back to&nbsp;<Link to="/auth/login">Log In</Link>
      </Row>
    </div>
  );
};

export default withRouter(ResetPassword);
