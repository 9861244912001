import React from 'react';
import { Typography } from 'antd';
import FilterIcon from '../images/filterIcon.svg';

const { Text } = Typography;

const FilterButton = ({ handleFilter, styles }: { handleFilter; styles? }) => {
  return (
    <button
      style={{
        color: '#465968',
        background: '#E5E5E5',
        borderRadius: 4,
        height: 35,
        width: 82,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        cursor: 'pointer',
        outlineColor: 'rgba(0,0,0,0.2)',
        ...styles,
      }}
      type="button"
      onClick={handleFilter}
    >
      <img src={FilterIcon} alt="filter" style={{ width: 20, height: 20 }} />
      <Text
        style={{
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '20px',
          letterSpacing: '0.2px',
          color: '#465968',
          marginLeft: 4,
        }}
      >
        Filter
      </Text>
    </button>
  );
};

export default FilterButton;
