import React, { useEffect, useState } from 'react';
import {
  Card,
  Button,
  Col,
  Row,
  Table,
  Typography,
  Space,
  Form,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
import {
  getCustomer,
  updateCustomer,
} from '../../../core/services/customer/customer.service';
import {
  inviteSuperintendent,
  reInviteSuperintendent,
  deleteSuperintendent,
} from '../../../core/services/superintendent/superintendent.service';
import { ICustomer } from '../../../core/interfaces/customer.interface';
import { ISuperintendent } from '../../../core/interfaces/superintendent.interface';
import CustomModal from '../../../core/components/Modal';
import SuperintendentForm from './SuperintendentForm';
import {
  UniquePhone,
  UniqueEmail,
  WrongPhone,
} from '../../../core/services/errorCodes';
import {
  formatAddressDumb,
  formatPhone,
} from '../../../core/helpers/utilities';

const { Text } = Typography;

const CustomerDetail = () => {
  const params = useParams() as { id? };
  const [valueCustomer, setCustomer] = useState<ICustomer | null>(null);
  const [valueError, setError] = useState<String>('');
  const [valueIsModalVisible, setIsModalVisible] = useState<Boolean>(false);
  const [
    valueSelectedSuperintendent,
    setSelectedSuperintendent,
  ] = useState<ISuperintendent | null>(null);

  useEffect(() => {
    getCustomer(params?.id).then(
      (entity) => {
        if (entity) {
          setCustomer(entity);
        } else {
          setError('Not found');
        }
      },
      (err) => {
        setError(err.message);
      }
    );
  }, [params]);

  const columns = [
    {
      title: 'Name',
      render: (ss) => {
        return (
          <Text className="ant-table-cell fs-10 fw-100">
            {ss.user.profile.firstName} {ss.user.profile.lastName}
          </Text>
        );
      },
    },
    {
      title: 'Phone #',
      render: (ss) => {
        return (
          <Text className="ant-table-cell fs-10 fw-100">
            {formatPhone(ss.user.profile.phone)}
          </Text>
        );
      },
    },
    {
      title: 'Email',
      render: (ss) => {
        return (
          <Text className="ant-table-cell fs-10 fw-100">
            {ss.user.profile.email}
          </Text>
        );
      },
    },
    {
      title: 'Current Job',
      render: (ss) => {
        return ss?.activeOrder ? (
          <Link to={`/orders/${ss?.activeOrder.id}`}>
            <Text className="row-text fs-10 fw-100">
              {ss ? `#${ss.activeOrder.nro}` : ''}
            </Text>
          </Link>
        ) : (
          <Text className="row-text fs-10 fw-100">No current Job</Text>
        );
      },
    },
    {
      title: 'Actions',
      render: (ss) => {
        return (
          <Button
            onClick={() => {
              setSelectedSuperintendent(ss);
            }}
            size="small"
            style={{
              border: 'none',
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                fontSize: 11,
                letterSpacing: 0.2,
                color: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              Detail
            </Text>
          </Button>
        );
      },
    },
  ];

  let content;
  if (!valueError && !valueCustomer) {
    content = <span>Loading...</span>;
  } else if (valueError) {
    content = <span>{valueError}</span>;
  } else if (valueCustomer) {
    content = (
      <>
        <CustomModal
          maskClosable={false}
          visible={valueIsModalVisible || valueSelectedSuperintendent}
          footer={null}
          title={
            valueSelectedSuperintendent
              ? 'Edit superintendent'
              : 'Add superintendent'
          }
          onCancel={() => {
            setIsModalVisible(false);
            setSelectedSuperintendent(null);
          }}
          destroyOnClose
        >
          <SuperintendentForm
            superintendent={valueSelectedSuperintendent}
            onReInviteEmployee={(superintendentId) =>
              reInviteSuperintendent({ id: superintendentId })
            }
            onDelete={(superintendentId) =>
              deleteSuperintendent({ id: superintendentId }).then(() =>
                setCustomer(
                  (prevCustomer) =>
                    prevCustomer && {
                      ...prevCustomer,
                      Superintendents: {
                        ...prevCustomer.Superintendents,
                        data: prevCustomer.Superintendents.data.filter(
                          (ss) => ss.id !== superintendentId
                        ),
                      },
                    }
                )
              )
            }
            onSubmit={(payload) => {
              const prom = inviteSuperintendent({
                ...payload,
                customer: valueCustomer.id,
              }).then((res) => {
                setSelectedSuperintendent(res);
                setCustomer(
                  (prevCustomer) =>
                    prevCustomer && {
                      ...prevCustomer,
                      Superintendents: {
                        ...prevCustomer.Superintendents,
                        data: [...prevCustomer.Superintendents.data, res],
                      },
                    }
                );
              });
              return prom.then(
                () => message.success('The record was saved'),
                (res) => {
                  const uniquePhoneError = res.graphQLErrors.find(
                    (err) => err.internalCode === UniquePhone
                  );
                  const wrongPhoneError = res.graphQLErrors.find(
                    (err) => err.internalCode === WrongPhone
                  );
                  const uniqueEmailError = res.graphQLErrors.find(
                    (err) => err.internalCode === UniqueEmail
                  );
                  let errorMsg;
                  if (uniquePhoneError) {
                    errorMsg = 'Phone already in use';
                  } else if (wrongPhoneError) {
                    errorMsg = 'Please, check the phone number';
                  } else if (uniqueEmailError) {
                    errorMsg = 'Email already in use';
                  } else {
                    errorMsg = "Can't save record";
                  }
                  message.error(errorMsg);
                }
              );
            }}
            onCancel={() => {
              setIsModalVisible(false);
              setSelectedSuperintendent(null);
            }}
          />
        </CustomModal>
        <Space direction="vertical" size="large">
          <Form
            onFinish={(valuesIn) => {
              const values = valuesIn;
              if (values && values.address && values.address.__typename) {
                delete values.address.__typename;
              }
              updateCustomer(valueCustomer.id, values).then(() => {
                message.success('The record was saved');
              });
            }}
            initialValues={valueCustomer}
          >
            {() => (
              <Card style={{ marginTop: '3rem' }} title={valueCustomer.name}>
                <Row gutter={18}>
                  <Col>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        letterSpacing: 0.2,
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineHeight: '20px',
                      }}
                    >
                      Address:
                    </Text>
                  </Col>
                  <Col>
                    <Text style={{ fontSize: 15, letterSpacing: 0.2 }}>
                      {formatAddressDumb(valueCustomer.address)}
                    </Text>
                  </Col>
                </Row>

                <Row gutter={18} style={{ marginTop: '1rem' }}>
                  <Col>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        letterSpacing: 0.2,
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineHeight: '20px',
                      }}
                    >
                      Phone:
                    </Text>
                  </Col>
                  <Col>
                    <Text style={{ fontSize: 15, letterSpacing: 0.2 }}>
                      {formatPhone(valueCustomer.phone)}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: '1rem' }} gutter={18}>
                  <Col>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        letterSpacing: 0.2,
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineHeight: '20px',
                      }}
                    >
                      Rating:
                    </Text>
                  </Col>
                  <Col>
                    <Text style={{ fontSize: 15, letterSpacing: 0.2 }}>
                      {valueCustomer.rating}
                    </Text>
                  </Col>
                </Row>
              </Card>
            )}
          </Form>
          <Row gutter={18}>
            <Col span={24}>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Superintendents:
              </Text>
            </Col>
          </Row>
          <Table
            dataSource={valueCustomer.Superintendents.data}
            rowKey={(data) => data.id}
            columns={columns}
            pagination={false}
          />
          <Row justify="end" gutter={18}>
            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
              size="large"
              style={{
                border: 'none',
              }}
            >
              <PlusOutlined
                style={{ fontSize: 11, color: 'rgba(0, 0, 0, 0.5)' }}
              />
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 11,
                  color: 'rgba(0, 0, 0, 0.5)',
                  letterSpacing: 0.2,
                  lineHeight: '20px',
                }}
              >
                Add Superintendent
              </Text>
            </Button>
          </Row>
        </Space>
      </>
    );
  }

  return (
    <div className="order-container">
      <Row align="middle" gutter={15}>
        <Col span={24}>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000',
              marginBottom: 0,
            }}
          >
            Customer Detail
          </Text>
          <Row gutter={20}>
            <Col span={12}>{content}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerDetail;
