import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';
import { checkinFieldsFragment } from './checkin.fragment';
import { pagingFieldsFragment } from './pagination.fragment';
import { skillListQueryFragment } from './skill.fragment';

export const scheduleFieldsFragment = gql`
    fragment scheduleFields on Schedule {
        ...entityFields
        date
        foreman {
            id
            user{
                profile{
                    firstName
                    lastName
                }
            }
        }
        Assignations {
            data {
                id
                payRate
                billRate
                wcf
                employee {
                    id
                    ...skillListQuery
                    user{
                        profile{
                            firstName
                            lastName
                        }
                    }
                }
                skill {
                    id
                    name
                }
            }
        }
    }
    ${skillListQueryFragment('Employee')}
    ${entityFieldsFragment}
`;

export const scheduleBriefFieldsFragment = gql`
    fragment scheduleBriefFields on Schedule {
        ...entityFields
        date
        Checkins {
            data {
                ...checkinFields
            }
        }
        order {
            id
            nro
            customer {
                name
            }
        }
    }
    ${entityFieldsFragment}
    ${checkinFieldsFragment}
`;

export const scheduleListQueryFragment = (type: string) => gql`
    fragment scheduleListQuery on ${type} {
        Schedules (input: $scheduleInput) {
            pagingMeta {
                ...pagingFields
            }
            data {
                ...scheduleFields
            }
        }
    }

    ${pagingFieldsFragment}

    ${scheduleFieldsFragment}

`;
