import gql from 'graphql-tag';

export const addressFieldFragment = gql`
  fragment addressFieldFragment on Address {
    name
    lat
    long
    address1
    address2
    city
    state
    zipCode
    country
    notes
  }
`;

export const addressFieldsFragment = (type: string) => gql`
    fragment addressFields on ${type} {
        address {
            ...addressFieldFragment
        }
    }

    ${addressFieldFragment}
`;
