/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';
import { addressFieldFragment } from './address.fragment';

export const traceFieldsFragment = gql`
  fragment traceFields on OrderTrace {
    order {
      ...entityFields
      address {
        ...addressFieldFragment
      }
      nro
      customer {
        ...entityFields
        name
      }
    }
    confirmed
    item {
      totalTimeSpentSeconds
      timeSpentSeconds
      timeReviewedSeconds
      employeeTraces {
        employee {
          ...entityFields
          user {
            profile {
              firstName
              lastName
            }
          }
        }
        confirmed
        item {
          timeSpentSeconds
          timeReviewedSeconds
          dateTraces {
            date
            feedback
            confirmed
            item {
              timeSpentSeconds
              timeReviewedSeconds
              taskTraces {
                confirmed
                task {
                  ...entityFields
                  name
                }
                item {
                  timeSpentSeconds
                  timeReviewedSeconds
                  timeEntries {
                    ...entityFields
                    startsAt
                    secondsTimeSpan
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${addressFieldFragment}
  ${entityFieldsFragment}
`;
