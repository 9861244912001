import React, { useState, useEffect, useMemo } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  notification,
  DatePicker,
  Typography
} from 'antd';
import { getAllCustomers } from '../../../core/services/customer/customer.service';
import { ICustomer } from '../../../core/interfaces/customer.interface';

import SkillQuantitySelector from '../../../core/components/SkillQuantitySelector';
import { IWorkerGroup } from '../../../core/interfaces/skill.interface';
import { IOrder } from '../../../core/interfaces/order.interface';
import { ISuperintendent } from '../../../core/interfaces/superintendent.interface';
import {
  createOrder,
  updateOrder
} from '../../../core/services/order/order.service';
import OrderAutocompleteForm from './OrderAutocompleteForm';
import AddressInput from '../../../core/components/AddressInput';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { TextArea } = Input;

const OrderForm = ({
  order,
  isOrderDetail
}: {
  order?: IOrder;
  isOrderDetail?: boolean;
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [valueFetchStatus, setFetchStatus] = useState<String>('loading');
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [skillQuantity, setSkillQuantity] = useState<IWorkerGroup[]>(
    order ? order.workerGroups : []
  );
  const [superintendents, setSuperintendents] = useState<ISuperintendent[]>([]);
  const [supervisors, setSupervisors] = useState<ISuperintendent[]>([]);

  useEffect(() => {
    setFetchStatus('loading');
    getAllCustomers().then(
      (resp) => {
        setFetchStatus('success');
        setCustomers(resp.data);
      },
      () => {
        setFetchStatus('error');
      }
    );
  }, []);

  const handleOnChangeCustomer = useMemo(
    () => (customerId) => {
      if (customerId) {
        const customer = customers.find(({ id }) => customerId === id);
        if (!customer) {
          return;
        }
        setSuperintendents(customer.Superintendents.data);
        setSupervisors(customer.Superintendents.data);
      }
    },
    [customers]
  );

  useEffect(() => {
    handleOnChangeCustomer(order && order.customer.id);
  }, [handleOnChangeCustomer, order]);

  const onReset = () => {
    form.resetFields();
    setSkillQuantity(order ? order.workerGroups : []);
  };
  const onSubmit = ({
    address,
    notes,
    reportToFirstName,
    reportToLastName,
    reportToEmail,
    reportToPhone,
    customer: customerId,
    datesBetween,
    time,
    // eslint-disable-next-line no-shadow
    supervisors,
    jobNumber,
    jobSiteName
  }: {
    address?: any;
    notes?: any;
    reportToFirstName?: any;
    reportToLastName?: any;
    reportToEmail?: any;
    reportToPhone?: any;
    customer?: any;
    datesBetween?: any;
    time?: any;
    supervisors?: any;
    jobNumber?: string;
    jobSiteName?: string;
  }) => {
    const workerGroups = skillQuantity?.map(({ skill, quantity }) => ({
      skill: skill.id,
      quantity
    }));
    const customer = customers.find(({ id }) => id === customerId);
    const payload = {
      workerGroups,
      customer: customer?.id,
      startsAt: moment(datesBetween[0]).format('YYYY-MM-DD'),
      endsAt: datesBetween[1]
        ? moment(datesBetween[1]).format('YYYY-MM-DD')
        : null,
      time: moment(time)
        .set({ year: 1970, month: 0, date: 1 })
        .format('HH:mm:ss.000Z'),
      timeOffset: moment().utcOffset(),
      // ToDo: remove always __typename
      address: lodash.omit(address, '__typename'),
      notes,
      superintendent: {
        firstName: reportToFirstName,
        lastName: reportToLastName,
        email: reportToEmail,
        phone: reportToPhone
      },
      supervisors,
      jobNumber,
      jobSiteName
    };
    let request;
    if (order?.id) {
      request = updateOrder(order.id, payload);
    } else {
      request = createOrder(payload);
    }
    request.then(
      () => {
        notification.open({
          message: order?.id
            ? 'Order updated successfully.'
            : 'Order saved successfully.',
          type: 'success'
        });
        history.push('/orders');
      },
      (err) => {
        notification.open({
          message: err.message,
          type: 'error'
        });
      }
    );
  };

  if (valueFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }
  if (valueFetchStatus === 'error') {
    return <span>Error...</span>;
  }

  return (
    <Form
      form={form}
      name="order-form"
      // autoComplete="new-password"
      initialValues={
        order
          ? {
              customer: order.customer.id,
              address: order.address,
              reportToFirstName: order.superintendent?.user.profile.firstName,
              reportToLastName: order.superintendent?.user.profile.lastName,
              reportToEmail: order.superintendent?.user.profile.email,
              reportToPhone: order.superintendent?.user.profile.phone,
              supervisors: order.supervisors,
              datesBetween: [
                moment(order.startsAt),
                order.endsAt ? moment(order.endsAt) : null
              ],
              time: moment(`1970-01-01T${order.time}`),
              notes: order.notes,
              jobNumber: order.jobNumber,
              jobSiteName: order.jobSiteName
            }
          : {}
      }
      onFinish={onSubmit}
    >
      <Row gutter={15} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <Card
            style={{
              marginBottom: '1rem',
              fontWeight: 'bold',
              fontSize: 15,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000'
            }}
            title="Location Information"
          >
            <Row gutter={15}>
              <Col span={12}>
                <Text className="new-form-label">customer</Text>
                <Form.Item
                  name="customer"
                  rules={[{ required: true, message: 'customer is required' }]}
                >
                  <Select
                    disabled={isOrderDetail}
                    style={{ fontWeight: 400 }}
                    optionFilterProp="label"
                    filterOption
                    showSearch
                    size="large"
                    onChange={(customerId) => {
                      form.setFieldsValue({ reportToFirstName: '' });
                      form.setFieldsValue({ reportToLastName: '' });
                      form.setFieldsValue({ reportToEmail: '' });
                      handleOnChangeCustomer(customerId);
                    }}
                    placeholder="Customer"
                    options={customers.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={24}>
                <Text className="new-form-label">address</Text>
                <Form.Item name="address" rules={[{ required: true }]}>
                  <AddressInput />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            style={{
              marginBottom: '1rem',
              fontWeight: 'bold',
              fontSize: 15,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000'
            }}
            title="Report To"
          >
            <Row gutter={15}>
              <Col span={12}>
                <Text className="new-form-label">first name</Text>
                <Form.Item
                  name="reportToFirstName"
                  rules={[
                    { required: true, message: 'first name is required' }
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    size="large"
                    disabled={isOrderDetail}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="new-form-label">last name</Text>
                <Form.Item
                  name="reportToLastName"
                  rules={[{ required: true, message: 'last name is required' }]}
                >
                  <Input
                    placeholder="Last Name"
                    size="large"
                    disabled={isOrderDetail}
                  />
                </Form.Item>
              </Col>
              <OrderAutocompleteForm
                disabled={isOrderDetail}
                superintendents={superintendents.map((s) => ({
                  label: `${s.user.profile.firstName} ${s.user.profile.lastName}`,
                  value: s.id,
                  superintendent: s
                }))}
                form={form}
              />
            </Row>
          </Card>
          <Card
            style={{
              marginBottom: '1rem',
              fontWeight: 'bold',
              fontSize: 15,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000'
            }}
            title="Supervisors"
          >
            <Form.Item name="supervisors">
              <Select
                mode="multiple"
                id="supervisors"
                style={{ fontWeight: 400, width: '100%' }}
                optionFilterProp="label"
                filterOption
                showSearch
                size="large"
                defaultValue={order ? order.supervisors : []}
                onChange={(ids) => {
                  form.setFieldsValue({ supervisors: ids });
                }}
                placeholder="Supervisors"
              >
                {supervisors.map(({ id, user }) => (
                  <Select.Option
                    key={id}
                    value={id}
                  >{`${user.profile.firstName} ${user.profile.lastName}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Card>
        </Col>
        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Card
            style={{
              marginBottom: '1rem',
              fontWeight: 'bold',
              fontSize: 15,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000'
            }}
            title="Order Information"
          >
            <Row gutter={15}>
              <Col span={10}>
                <Text className="new-form-label">job #</Text>
                <Form.Item
                  name="jobNumber"
                  rules={[{ required: true, message: 'job# are required' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Text className="new-form-label">job site name</Text>
                <Form.Item
                  name="jobSiteName"
                  rules={[
                    { required: true, message: 'job site name is required' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={18}>
                <Text className="new-form-label">date</Text>
                <Form.Item
                  name="datesBetween"
                  rules={[{ required: true, message: 'dates are required' }]}
                >
                  <RangePicker
                    size="large"
                    style={{ width: '100%' }}
                    allowEmpty={[false, true]}
                    allowClear
                    format={'MM/DD/YYYY'}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="new-form-label">time</Text>
                <Form.Item
                  name="time"
                  rules={[{ required: true, message: 'time is required' }]}
                >
                  {/* ToDo: Fix TZ auto filling issue */}
                  <DatePicker picker="time" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={24}>
                <Text className="new-form-label">workers needed</Text>
                <Form.Item>
                  <SkillQuantitySelector
                    defaultSkillQuantity={skillQuantity}
                    skillQuantityChanged={setSkillQuantity}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={24}>
                <Text className="new-form-label">Notes/Job Description</Text>
                <Form.Item name="notes">
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row justify="end">
        <Form.Item>
          <Button
            onClick={onReset}
            size="large"
            style={{ marginRight: '15px' }}
            className="button-cancel-form"
          >
            <Text className="btn-label">Cancel</Text>
          </Button>
          <Button htmlType="submit" size="large" className="button-new-form">
            <Text className="btn-label">Save</Text>
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default OrderForm;
