import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Typography, Radio, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DayView from './Day';
import WeekView from './Week';
import {
  formatWeek,
  weekOfDate,
  weekToDateRange,
  capitalize,
} from '../../../core/helpers/utilities';
import { dayWeek } from '../../../core/enums/day_week';

import { getAllEmployees } from '../../../core/services/employee/employee.service';
import { IEmployee } from '../../../core/interfaces/employee.interface';
import { ICustomer } from '../../../core/interfaces/customer.interface';
import { getAllOrders } from '../../../core/services/order/order.service';
import { IOrder } from '../../../core/interfaces/order.interface';
import { getAllCustomers } from '../../../core/services/customer/customer.service';
import ArrowButtonLeft from '../../../core/components/ArrowButtonLeft';
import ArrowButtonRight from '../../../core/components/ArrowButtonRight';
import TimeTrackingFilter from './TimeTrackingFilter';

import './index.scss';

const { Text } = Typography;
const PATH_PARAM_DATE_FORMAT = 'YYYYMMDD';

const TimeTracking = ({ timegrain }) => {
  const history = useHistory();
  const [valueView, setView] = useState(timegrain);
  const [valueAppliedFilter, setAppliedFilter] = useState<any>({});
  const [valueDate, setDate] = useState(
    moment().format(PATH_PARAM_DATE_FORMAT)
  );

  const [valueEmployees, setEmployees] = useState<IEmployee[]>([]);
  const [valueCustomers, setCustomers] = useState<ICustomer[]>([]);
  const [valueOrders, setOrders] = useState<IOrder[]>([]);

  const fetchEmployees = () => {
    getAllEmployees().then((resp: any) => {
      setEmployees(resp.data);
    });
  };
  const fetchAllOrders = () => {
    getAllOrders({}).then((resp) => {
      setOrders(resp.data);
    });
  };
  const fetchAllCustomers = () => {
    getAllCustomers().then((resp) => {
      setCustomers(resp.data);
    });
  };

  useEffect(() => {
    fetchEmployees();
    fetchAllOrders();
    fetchAllCustomers();
  }, []);

  const transformedDate = useMemo(() => {
    if (valueView === dayWeek.WEEK) {
      return formatWeek(
        weekOfDate(moment(valueDate, PATH_PARAM_DATE_FORMAT, true))
      );
    }
    return moment(valueDate, PATH_PARAM_DATE_FORMAT, true).format(
      'MMMM D, YYYY'
    );
  }, [valueDate, valueView]);

  const handleTypeChange = (e) => {
    setView(e.target.value);
    history.push(`/time-tracking/${e.target.value}`);
  };

  const moveDates = (countDays) => {
    setDate(
      moment(valueDate, PATH_PARAM_DATE_FORMAT, true)
        .add(countDays, 'days')
        .format(PATH_PARAM_DATE_FORMAT)
    );
  };

  const disableDateNav = Boolean(
    (valueView === dayWeek.DAY && valueAppliedFilter?.date) ||
      (valueView === dayWeek.WEEK && valueAppliedFilter?.daterange)
  );

  let content;
  if (valueView === dayWeek.WEEK) {
    const mDate = moment(valueDate, PATH_PARAM_DATE_FORMAT, true);
    const weekRange = weekToDateRange(weekOfDate(mDate));
    const dateRange = [
      valueAppliedFilter.daterange
        ? valueAppliedFilter.daterange[0]
        : weekRange[0],
      valueAppliedFilter.daterange
        ? valueAppliedFilter.daterange[1]
        : weekRange[1],
    ];
    content = (
      <WeekView dateRange={dateRange} appliedFilter={valueAppliedFilter} />
    );
  } else {
    content = <DayView date={valueDate} appliedFilter={valueAppliedFilter} />;
  }
  return (
    <Space
      className="orders-container"
      direction="vertical"
      style={{ width: '100%' }}
      size="middle"
    >
      <Row>
        <Col>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000',
              marginBottom: 0,
            }}
          >
            Time Tracking
          </Text>
        </Col>
      </Row>
      <Row gutter={15} align="middle" className="custom">
        <Col>
          <Radio.Group
            className="custom-radio"
            value={valueView}
            onChange={handleTypeChange}
          >
            <Radio.Button value={dayWeek.WEEK}>
              {capitalize(dayWeek.WEEK)}
            </Radio.Button>
            <Radio.Button value={dayWeek.DAY}>
              {capitalize(dayWeek.DAY)}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <ArrowButtonLeft
          handleClick={() => moveDates(valueView === dayWeek.DAY ? -1 : -7)}
          disabled={disableDateNav}
        />
        <ArrowButtonRight
          handleClick={() => moveDates(valueView === dayWeek.DAY ? 1 : 7)}
          disabled={disableDateNav}
        />

        <Col>
          <Text className="fw-700">
            {valueView === dayWeek.DAY ? (
              <Text className="filter-label dark-color" strong>
                {moment(valueDate)
                  .clone()
                  .startOf('day')
                  .isSame(moment().startOf('day'))
                  ? 'Today '
                  : ''}
              </Text>
            ) : (
              <Text className="filter-label dark-color" strong>
                This Week{' '}
              </Text>
            )}
            <Text className="dark-color fw-200">{transformedDate}</Text>
          </Text>
        </Col>
      </Row>

      <Row align="middle" justify="space-between">
        <Col>
          <TimeTrackingFilter
            view={valueView}
            filterSubmit={setAppliedFilter}
            availableCustomers={valueCustomers}
            availableEmployees={valueEmployees}
            availableOrders={valueOrders}
          />
        </Col>
      </Row>
      {content}
    </Space>
  );
};

export default TimeTracking;
