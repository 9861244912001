import gql from 'graphql-tag';
import { checkinFieldsFragment } from "../../fragments/checkin.fragment";
import { pagingFieldsFragment } from '../../fragments/pagination.fragment';

export const CheckinListQuery = gql`
  query CheckinList($input: CheckinPagingInput) {
    ...checkinListQuery
  }
  fragment checkinListQuery on Query {
    Checkin(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...checkinFields
      }
    }
  }

  ${pagingFieldsFragment}
  ${checkinFieldsFragment}
`;

export const CheckinSaveCommentMutation = gql`
  mutation CheckinAddComment($input: CheckinAddCommentInput!) {
    CheckinAddComment(input: $input) {
      message
    }
  }
`;
