import React, { useState } from 'react';
import { Select, DatePicker, Button, Form } from 'antd';
import { dayWeek } from '../../../core/enums/day_week';
import FilterButton from '../../../core/components/FilterButton';

const { RangePicker } = DatePicker;

const TimeTrackingFilter = ({
  filterSubmit,
  availableOrders,
  availableCustomers,
  availableEmployees,
  view,
}) => {
  const [showFilter, setShowFilter] = useState(true);
  const [form] = Form.useForm();
  const cleanFilter = () => {
    form.resetFields();
  };

  const layout = {
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <Form
        form={form}
        layout="inline"
        onFinish={filterSubmit}
        hidden={showFilter}
        {...layout}
      >
        {view === dayWeek.WEEK ? (
          <Form.Item style={{ marginTop: 10 }} name="daterange">
            <RangePicker />
          </Form.Item>
        ) : (
          <Form.Item style={{ marginTop: 10 }} name="date">
            <DatePicker />
          </Form.Item>
        )}
        <Form.Item name="employees" style={{ marginTop: 10, width: 300 }}>
          <Select
            showArrow
            mode="multiple"
            placeholder="All employees"
            style={{ width: '100%' }}
            options={availableEmployees.map(({ id, user }) => ({
              value: id,
              label: `${user.profile.firstName} ${user.profile.lastName}`,
            }))}
          />
        </Form.Item>
        <Form.Item name="orders" style={{ marginTop: 10, width: 300 }}>
          <Select
            showArrow
            mode="multiple"
            placeholder="All jobs"
            style={{ width: '100%' }}
            options={availableOrders.map(({ id, nro }) => ({
              value: id,
              label: `#${nro}`,
            }))}
          />
        </Form.Item>
        <Form.Item name="customers" style={{ marginTop: 10, width: 300 }}>
          <Select
            showArrow
            mode="multiple"
            placeholder="All companies"
            style={{ width: '100%' }}
            options={availableCustomers.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        </Form.Item>
        <Form.Item style={{ marginTop: 10 }}>
          <Button className="button-new-form" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
        <Form.Item style={{ marginTop: 10 }}>
          <Button className="button-cancel-form" onClick={cleanFilter}>
            Clear
          </Button>
        </Form.Item>
      </Form>
      <FilterButton
        styles={{ marginTop: 10 }}
        handleFilter={() => setShowFilter(!showFilter)}
      />
      {/* <Col>
        <Input
          prefix={<SearchOutlined className="site-form-item-icon" />}
          placeholder="Search"
        />
      </Col> */}
    </>
  );
};

export default TimeTrackingFilter;
