export enum colorsRating {
  A = '#D5F9B8',
  B = '#FFEE54',
  C = '#FCA95D',
  D = '#F66767',
  F = '#2E2D29',
}

export enum ratings {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
}
