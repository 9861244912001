import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Button, Row, Typography, Form, message } from 'antd';

import ComponentA from './formPartA';
import ComponentB from './formPartB';
import ComponentC from './formPartC';
import { createIncident } from '../../../../core/services/incident/incident.service';

const { Text } = Typography;

const Wizard = ({
  incident,
  employees,
  orders,
}: {
  incident?;
  employees;
  orders;
}) => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const isReadOnly = Boolean(incident);
  const steps = [
    {
      title: 'A',
      component: (display, title) => (
        <ComponentA
          display={display ? undefined : 'none'}
          employees={employees}
          orders={orders}
          isReadOnly={isReadOnly}
          title={title}
        />
      ),
    },
    {
      title: 'B',
      component: (display, title) => (
        <ComponentB
          display={display ? undefined : 'none'}
          isReadOnly={isReadOnly}
          title={title}
        />
      ),
    },
    {
      title: 'C',
      component: (display, title) => (
        <ComponentC
          display={display ? undefined : 'none'}
          isReadOnly={isReadOnly}
          title={title}
        />
      ),
    },
  ];
  return (
    <Form
      initialValues={
        incident
          ? {
              employee: incident.employee.id,
              order: incident.order.id,
              title: incident.title,
              healthTypes: incident.healthTypes,
              operationalTypes: incident.operationalTypes,
              occurredAt: moment(incident.occurredAt),
              details: incident.details,
              location: incident.location,
              locationDepartment: incident.locationDepartment,
              injureNature: incident.injureNature,
              injureContact: incident.injureContact,
              injureIllness: incident.injureIllness,
              bodyParts: incident.bodyParts,
              firstAidNotes: incident.firstAidNotes,
              causes: incident.causes,
              returnedToDuty: incident.returnedToDuty,
              correctiveAppliedResponsible:
                incident.correctiveAppliedResponsible,
              correctiveAppliedDescription:
                incident.correctiveAppliedDescription,
              correctiveAppliedDay: moment(incident.correctiveAppliedDay),
              witnesses: incident.witnesses.map(({ name, statement }) => ({
                name,
                statement,
              })),
              signatureFiles: incident.signatureFiles,
            }
          : {
              healthTypes: [],
              operationalTypes: [],
              bodyParts: [],
              firstAidNotes: '',
              causes: '',
              returnedToDuty: false,
              witnesses: [],
              signatureFiles: [],
            }
      }
      onFinish={({ correctiveAppliedDay, signatureFiles, ...values }) => {
        createIncident({
          ...values,
          signatureFiles: signatureFiles.map(({ id }) => id),
          correctiveAppliedDay: moment(correctiveAppliedDay).format(
            'YYYY-MM-DD'
          ),
        }).then(() => {
          message.success('The record was saved');
          history.push('/safety/incidents');
        });
      }}
      name="incidents-form"
    >
      {steps.map(({ title, component }, indexx) => (
        <React.Fragment key={title}>
          {component(step === indexx, title)}
        </React.Fragment>
      ))}
      <Row justify="end">
        <Button
          onClick={() => {
            setStep((oldStep) => {
              if (oldStep === 0) {
                history.push('/safety/incidents');
                return oldStep;
              }
              return oldStep - 1;
            });
          }}
          size="large"
          type="link"
          className="button-cancel-form"
          style={{ marginRight: 10 }}
        >
          <Text className="btn-label">{step === 0 ? 'Cancel' : 'Back'}</Text>
        </Button>
        {step !== steps.length - 1 ? (
          <Button
            className="button-new-form"
            onClick={() => {
              setStep((oldStep) => oldStep + 1);
            }}
            type="link"
            htmlType="button"
            style={{
              backgroundColor: '#D7DEE2',
              borderRadius: 2,
            }}
            size="large"
          >
            <Text className="btn-label">Continue</Text>
          </Button>
        ) : !isReadOnly ? (
          <Button className="button-new-form" htmlType="submit" size="large">
            <Text className="btn-label">Finish</Text>
          </Button>
        ) : null}
      </Row>
    </Form>
  );
};

export default Wizard;
