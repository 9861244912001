import gql from 'graphql-tag';
import { skillFieldsFragment } from '../../fragments/skill.fragment';

export const DeleteSkillReq = gql`
  mutation SkillDelete($input: SkillDeleteInput!) {
    SkillDelete(input: $input) {
      ...skillFields
    }
  }
  ${skillFieldsFragment}
`;
