import React, { useEffect, useState } from 'react';
import { useHistory, Switch, Redirect, Route } from 'react-router-dom';
import { Layout, message } from 'antd';

// Helpers
import { debounce } from 'lodash';
import moment from 'moment';
import { Token } from '../../core/helpers/storage';

// Components
import { AuthPing } from '../../core/services/auth/auth.service';
import Customers from './Customers';
import CustomerDetail from './Customers/CustomerDetail';
import EmployeeDetail from './Employees/EmployeeDetail';
import Employees from './Employees';
import Invoices from './Finance/Invoices';
import Payments from './Finance/Payments';
import NewEmployee from './Employees/NewEmployee';
import NewOrder from './Orders/NewOrder';
import OrderDetail from './Orders/OrderDetail';
import Orders from './Orders';
import SideNavigation from '../../core/components/SideNavigation/SideNavigation';
import TopNavigation from '../../core/components/TopNavigation/TopNavigation';
import Checkins from './Safety/Checkins';
import IncidentDetail from './Safety/Incidents/IncidentDetail';
import NewIncident from './Safety/Incidents/NewIncident';
import Incidents from './Safety/Incidents/IncidentList';
import Scheduling from './Scheduling/Scheduling';
import TimeTracking from './TimeTracking';
import Messaging from './Messaging';
import Lists from './Lists';
import Jobs from './Jobs';
import NewJobForm from './Jobs/components/NewJobForm';

// import RootContext from '../../RootContext';

const PATH_PARAM_DATE_FORMAT = 'YYYYMMDD';

const AuthenticatedLayout = () => {
  const history = useHistory();
  // const context = useContext(RootContext);
  const [menuCollapsed, toggleMenu] = useState(false);
  const [valueToken, setToken] = useState<any>(null);

  useEffect(() => {
    if (!valueToken?.jwtBearer) {
      AuthPing().then(
        () => {
          setToken(Token());
        },
        () => {
          message.error('Session expired...');
          history.push('/auth/login');
        }
      );
    }
  }, [history, valueToken]);

  if (!valueToken?.jwtBearer) {
    return null;
  }
  return (
    <Layout style={{ minHeight: '100vh', background: 'white' }}>
      <Layout.Sider
        collapsible
        onCollapse={() =>
          debounce(toggleMenu, menuCollapsed ? 0 : 200)(!menuCollapsed)
        }
        style={{
          backgroundColor: '#BAC3C8',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}
      >
        <SideNavigation menuCollapsed={menuCollapsed} />
      </Layout.Sider>
      <Layout style={{ marginLeft: menuCollapsed ? 80 : 200 }}>
        <Layout.Header
          style={{
            backgroundColor: '#F7FCFF',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgba(70, 89, 104, 0.5)',
            height: 53,
            lineHeight: '51px',
            justifyContent: 'flex-end',
            alignContent: 'center',
            display: 'flex'
          }}
        >
          <TopNavigation username={valueToken.user.username} />
        </Layout.Header>
        <Layout.Content style={{ margin: '16px' }}>
          <div style={{ padding: '0 24px', marginBottom: 30 }}>
            <Switch>
              <Route exact path="/employees" render={() => <Employees />} />
              <Route
                exact
                path="/employees/new-employee"
                render={() => <NewEmployee />}
              />
              <Route
                exact
                path="/employees/new-employee-from-applicant/:id"
                render={({ match }) => (
                  <EmployeeDetail applicantId={match.params.id} />
                )}
              />
              <Route
                path="/employees/:id"
                render={({ match }) => (
                  <EmployeeDetail employeeId={match.params.id} />
                )}
              />
              {/* <Route exact path="/users" render={() => <Users />} /> */}
              <Route
                path="/scheduling/:day"
                render={({ match }) => {
                  let selectedDate = moment(
                    match.params.day,
                    PATH_PARAM_DATE_FORMAT,
                    true
                  );
                  if (!selectedDate.isValid()) {
                    selectedDate = moment();
                  }
                  return (
                    <Scheduling
                      selectedDate={selectedDate}
                      onDateNavigate={(date) => {
                        history.push(
                          `/scheduling/${date.format(PATH_PARAM_DATE_FORMAT)}`
                        );
                      }}
                    />
                  );
                }}
              />
              <Route
                path="/scheduling"
                render={() => (
                  <Redirect
                    to={`/scheduling/${moment().format(
                      PATH_PARAM_DATE_FORMAT
                    )}`}
                  />
                )}
              />
              <Route exact path="/orders" render={() => <Orders />} />
              <Route
                exact
                path="/orders/new-order"
                render={() => <NewOrder />}
              />
              <Route
                path="/orders/:id"
                render={({ match }) => (
                  <OrderDetail orderId={match.params.id} />
                )}
              />
              <Route
                path="/time-tracking/:timegrain(day|week)"
                exact
                render={({ match }) => (
                  <TimeTracking timegrain={match.params.timegrain} />
                )}
              />
              <Route
                path="/time-tracking"
                render={() => <Redirect to="/time-tracking/day" />}
              />
              <Route
                exact
                path="/safety/checkins/date/:currentDate"
                render={({ match }) => (
                  <Checkins currentDate={match.params.currentDate} />
                )}
              />
              <Route
                path="/safety/checkins/date/"
                render={() => (
                  <Redirect
                    to={`/safety/checkins/date/${moment().format(
                      PATH_PARAM_DATE_FORMAT
                    )}`}
                  />
                )}
              />
              <Route
                exact
                path="/safety/incidents"
                render={() => <Incidents />}
              />
              <Route
                exact
                path="/safety/incidents/new-incident"
                render={() => <NewIncident />}
              />
              <Route
                exact
                path="/safety/incidents/:id"
                render={({ match }) => (
                  <IncidentDetail incidentId={match.params.id} />
                )}
              />
              <Route exact path="/customers" render={() => <Customers />} />
              <Route path="/customers/:id" render={() => <CustomerDetail />} />
              <Route
                exact
                path="/finance/invoices"
                render={() => <Invoices />}
              />
              <Route
                exact
                path="/finance/payments"
                render={() => <Payments />}
              />
              <Route exact path="/messaging" render={() => <Messaging />} />
              <Route exact path="/lists/crud" render={() => <Lists />} />
              {/* JOBS */}
              <Route exact path="/jobs" render={() => <Jobs />} />
              <Route
                exact
                path="/jobs/form-job"
                render={() => <NewJobForm />}
              />
              <Route
                exact
                path="/jobs/form-job/:id"
                render={() => <NewJobForm />}
              />
              {/* EMPLOYEES */}
              <Route
                exact
                path="*"
                render={() => <Redirect to="/employees" />}
              />
            </Switch>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
