import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import { SkillsListQuery } from './skill.gql';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import { ISkill, ISkillResponse } from '../../interfaces/skill.interface';
import { CreateSkillReq } from './createSkill.gql';
import { DeleteSkillReq } from './deleteSkill.gql';
import { UpdateSkillReq } from './editSkill.gql';

export const getAllSkills = () => {
  return new Promise<IPaginationWrapper<ISkill>>((resolve, reject) => {
    FUESApolloClient()
      .query({
        fetchPolicy: 'no-cache',
        query: SkillsListQuery,
        variables: {
          input: {
            paging: {
              first: -1,
              skip: 0,
              orderBy: 'name.asc'
            },
            args: {}
          }
        }
      })
      .then((resp: ApolloQueryResult<ISkillResponse>) => {
        resolve(resp.data.Skill);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createSkill = async (
  name: string,
  description = 'description example'
) =>
  FUESApolloClient()
    .mutate({
      fetchPolicy: 'no-cache',
      mutation: CreateSkillReq,
      variables: {
        input: {
          name,
          description
        }
      }
    })
    .then((res) => res.data.SkillCreate);

export const updateSkill = async (input: {
  id: string;
  name: string
}): Promise<ISkill | null> =>
  FUESApolloClient()
    .mutate<{ SkillUpdate: ISkill }>({
      fetchPolicy: 'no-cache',
      mutation: UpdateSkillReq,
      variables: {
        input
      }
    })
    .then((res) => {
      if (res.data) {
        return res.data.SkillUpdate;
      }
      return null;
    });

export const deleteSkill = async (id: string) =>
  FUESApolloClient()
    .mutate({
      fetchPolicy: 'no-cache',
      mutation: DeleteSkillReq,
      variables: {
        input: {
          id
        }
      }
    })
    .then((res) => res.data.SkillDelete);
