import {message, Table, Button} from 'antd';
import {CSVLink} from "react-csv";

import moment from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  IActivePayment,
  IArchivedPayment
} from '../../../../../core/interfaces/payment.interface';
import {getPaymentsForExport, PaymentArchieved} from '../../../../../core/services/payment/payment.service';
import {IPaymentForExport} from "../../../../../core/interfaces/paymentForExport.interface";
const HEADERS = [
  { label: "Employee", key: "employee" },
  { label: "Task", key: "task" },
  { label: "Job #", key: "job" },
  { label: "Hours", key: "hours" },
  { label: "Rate", key: "rate" },
  { label: "Total", key: "total" }
];

interface IProps {
  reload: number;
}

const ArchivedTable = (props: IProps) => {
  const csvLinkRef = useRef<CSVLink>()
  const [payments, setPayments] = useState<IArchivedPayment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalArchived, setTotalArchived] = useState<number>();
  const [dataForExport, setDataForExport] = useState<IPaymentForExport[]>([]);

  useEffect(() => {
    if (dataForExport && dataForExport.length > 0) {
      csvLinkRef?.current?.link.click();
    }
  }, [dataForExport])


  const columns = [
    {
      title: 'Pay Period',
      dataIndex: 'period',
      width: '40%',
      render: (text) => <strong>{text}</strong>
    },
    {
      title: 'Generated Date',
      dataIndex: 'createdAt',
      width: '20%'
    },
    {
      title: 'Confirmed Date',
      dataIndex: 'confirmedAt',
      width: '20%'
    },
    {
      title: 'Printed Date',
      dataIndex: 'printedPdf',
      width: '20%'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '10%',
      render: (text, record) => {
        return record.children ? null : (
          <>
            <Button color='primary' onClick={() => {
              getPaymentsForExport(record.export)
                .then(data => {
                  const finalData = data.map((d) => {
                    delete d.__typename;
                    return d;
                  })
                  setDataForExport(finalData);
                })
            }}>
              EXPORT
            </Button>
            <div style={{display:'none'}}>
              <CSVLink
                headers={HEADERS}
                data={dataForExport}
                asyncOnClick={true}
                target="_blank"
                filename='data.csv'
                ref={csvLinkRef}
              >
                Download me
              </CSVLink>
            </div>
          </>
        );
      }
    }
  ];


  useEffect(() => {
    getPaymentHistory(10, 0);
  }, []);

  useEffect(() => {
    if (props.reload) {
      console.log("HERE")
      console.log("HERE")
      getPaymentHistory(10, 0);
    }
  }, [props.reload]);

  const getPaymentHistory = async (limit: number, skip: number) => {
    setLoading(true);
    try {
      const data: IArchivedPayment = await PaymentArchieved(limit, skip);
      if (data) {
        const dataTransformed = transformData(data.data);
        setTotalArchived(data.total);
        setPayments(dataTransformed);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Error getPaymentHistory');
    }
  };

  const transformData = (payments: IActivePayment[]) => {
    let tableData: any[] = [];
    payments.forEach((payment) => {
      const period = `${moment(payment.periodStart).format(
        'YYYY/MM/DD'
      )} - ${moment(payment.periodEnd).format('YYYY/MM/DD')}`;
      const createdAt = moment(payment.createdAt).format('YYYY/MM/DD');
      const confirmedAt = moment(payment.confirmedAt).format('YYYY/MM/DD');
      const printedPdf = moment(payment.printedPdf).format('YYYY/MM/DD');

      tableData.push({
        period,
        createdAt,
        confirmedAt,
        printedPdf,
        export: payment.id,
        key: payment.id
      });
    });
    return tableData;
  };

  const onHandleChangePage = (page: number) => {
    const skip = 10 * (page - 1);
    if (totalArchived && skip < totalArchived) {
      getPaymentHistory(10, skip);
    }
  };

  return (
    <>
      <h1 style={{fontSize: '22px', marginTop: '2rem'}}>Payment History</h1>
      <Table
        columns={columns}
        dataSource={payments}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          total: totalArchived,
          onChange: onHandleChangePage
        }}
      />
    </>
  );
};

export default ArchivedTable;
