import { FUESApolloClient } from '../../helpers/api';
import { ITaskName } from '../../interfaces/taskName.interface';
import { TaskNameCreate, TaskNameDelete, TaskNameListQuery, TaskNameUpdate } from './taskName.gql';

export const getAllTaskNames = (): Promise<ITaskName[]> => {
  return FUESApolloClient()
    .query<{ getTaskNames: ITaskName[] }>({
      fetchPolicy: 'no-cache',
      query: TaskNameListQuery,
      variables: {}
    })
    .then(
      (resp) =>
        resp.data.getTaskNames
    );
};

export const createTaskName = (input: { name: string }): Promise<ITaskName | undefined> => {
  return FUESApolloClient()
    .mutate<{ createTaskName: ITaskName }>({
      fetchPolicy: 'no-cache',
      mutation: TaskNameCreate,
      variables: {
        input: {
          ...input
        }
      }
    })
    .then(res => {
      if (res.data && res.data.createTaskName) {
        return res.data.createTaskName;
      }
    });
};

export const updateTaskName = (input: ITaskName): Promise<ITaskName | undefined> => {
  return FUESApolloClient()
    .mutate<{ updateTaskName: ITaskName }>({
      fetchPolicy: 'no-cache',
      mutation: TaskNameUpdate,
      variables: {
        input
      }
    })
    .then(res => {
      if (res.data && res.data.updateTaskName) {
        return res.data.updateTaskName;
      }
    });
};

export const deleteTaskName = (id: string): Promise<ITaskName | undefined> => {
  return FUESApolloClient()
    .mutate<{ deleteTaskName: ITaskName }>({
      fetchPolicy: 'no-cache',
      mutation: TaskNameDelete,
      variables: {
        input: {
          id
        }
      }
    })
    .then(res => {
      if (res.data && res.data.deleteTaskName) {
        return res.data.deleteTaskName;
      }
    });
};
