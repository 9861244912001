import { ApolloQueryResult } from 'apollo-client';
import { FUESApolloClient } from '../../helpers/api';
import { IApplicant } from '../../interfaces/applicant.interface';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import {
  ApplicantByIdQuery,
  ApplicantsQuery,
  DeleteApplicantById
} from './applicant.gql';

export const getApplicants = (id: string) => {
  return new Promise<IPaginationWrapper<IApplicant>>((resolve, reject) => {
    FUESApolloClient()
      .query({
        query: ApplicantsQuery,
        variables: {
          input: {
            args: { job: id }
          }
        }
      })
      .then((resp: ApolloQueryResult<any>) => {
        resolve(resp.data.Applicants);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getApplicantById = (id: string) => {
  return new Promise<IApplicant>((resolve, reject) => {
    FUESApolloClient()
      .query({
        query: ApplicantByIdQuery,
        variables: {
          input: {
            id
          }
        }
      })
      .then((resp: any) => {
        resolve(resp.data.Applicant);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteApplicantById = (id: string) => {
  return new Promise<IApplicant>((resolve, reject) => {
    FUESApolloClient()
      .mutate({
        mutation: DeleteApplicantById,
        variables: {
          input: {
            id
          }
        }
      })
      .then((resp: any) => {
        resolve(resp.data.ApplicantDelete);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
