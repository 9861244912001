import gql from 'graphql-tag';
import { ApplicantFragment } from '../../fragments/applicant.fragment';
import { JobFragment } from '../../fragments/job.fragment';

export const JobsQuery = gql`
  query Jobs($input: JobPagingInput) {
    Jobs(input: $input) {
      pagingMeta {
        count
        startIndex
        endIndex
        hasNextPage
      }
      data {
        ...Job
        applicants {
          id
        }
      }
    }
  }
  ${JobFragment}
`;

export const JobCreate = gql`
  mutation JobCreate($input: JobCreate!) {
    JobCreate(input: $input) {
      ...Job
    }
  }
  ${JobFragment}
`;

export const JobUpdate = gql`
  mutation JobUpdate($input: JobUpdate!) {
    JobUpdate(input: $input) {
      ...Job
    }
  }
  ${JobFragment}
`;

export const Job = gql`
  query Job($input: JobGetByIdInput!) {
    Job(input: $input) {
      ...Job
      applicants {
        ...Applicant
      }
    }
  }
  ${ApplicantFragment}
  ${JobFragment}
`;

export const JobDelete = gql`
  mutation JobDelete($input: JobDeleteInput!) {
    JobDelete(input: $input) {
      id
    }
  }
`;
