import gql from 'graphql-tag';
import { skillFieldsFragment } from '../../fragments/skill.fragment';

export const UpdateSkillReq = gql`
  mutation SkillUpdate($input: SkillUpdate!) {
      SkillUpdate(input: $input) {
      ...skillFields
    }
  }
  ${skillFieldsFragment}
`;
