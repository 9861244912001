import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Typography,
  Space,
  message,
} from 'antd';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAllOrders } from '../../../core/services/order/order.service';
import { IOrder } from '../../../core/interfaces/order.interface';
import SkillIconText from '../../../core/components/SkillIconText';
import {
  USA_DATE_FORMAT,
  USA_TIME_FORMAT,
} from '../../../core/helpers/utilities';

const { Search } = Input;
const { Text } = Typography;

const getClassRowByStatus = {
  'In Progress': 'inProgress',
  Scheduled: 'scheduled',
};

const columns = [
  {
    title: 'Company',
    width: '20%',
    dataIndex: 'customer',
    render: (customer) => {
      return <Text className="row-text">{customer.name}</Text>;
    },
  },
  {
    title: 'Super',
    width: '25%',
    render: (order) => {
      return (
        <Text className="row-text">
          {/* ToDo: superintendent cant be null in fact */}
          {order.superintendent?.user.profile.firstName}{' '}
          {order.superintendent?.user.profile.lastName}
        </Text>
      );
    },
  },
  {
    title: 'Date',
    dataIndex: 'startsAt',
    render: (dateObj) => {
      return (
        <Text className="row-text">
          {moment(dateObj).format(USA_DATE_FORMAT)}
        </Text>
      );
    },
  },
  {
    title: 'Time',
    dataIndex: 'time',
    render: (dateObj) => (
      <Text className="row-text">
        {moment(`1970-01-01T${dateObj}`).format(USA_TIME_FORMAT)}
      </Text>
    ),
  },
  {
    title: 'Workers Needed',
    width: '29%',
    dataIndex: 'workerGroups',
    render: (workerGroups) => {
      return workerGroups.map((workerGroup) => {
        return (
          <Space
            key={workerGroup.skill.id}
            size="small"
            style={{ marginRight: 10, textAlign: 'center' }}
          >
            <SkillIconText name={workerGroup.skill.name} />
          </Space>
        );
      });
    },
  },
  {
    title: 'Status',
    width: '5%',
    dataIndex: 'status',
    render: (status) => {
      return <Text className="row-text">{status}</Text>;
    },
  },
  {
    title: '',
    width: '1%',
    render: () => {
      return (
        <RightOutlined
          style={{ fontSize: 15, color: '#465968', cursor: 'pointer' }}
        />
      );
    },
  },
];

const Orders = () => {
  const history = useHistory();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<String>('loading');

  const fetchOrders = (param) => {
    setFetchStatus('loading');
    getAllOrders(param).then(
      (resp) => {
        setFetchStatus('success');
        setOrders(resp.data);
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
  };
  useEffect(() => {
    fetchOrders({});
  }, []);

  if (valueFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }
  if (valueFetchStatus === 'error') {
    return <span>Error...</span>;
  }

  return (
    <div className="orders-container">
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={8}>
          <Row>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#000000',
                marginBottom: 0,
              }}
            >
              Orders
            </Text>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Row>
            <Search
              placeholder="Search"
              onSearch={(search) => fetchOrders({ search })}
            />
          </Row>
        </Col>
        <Col offset={10} span={8}>
          <Row justify="end">
            <Link to="/orders/new-order">
              <Button
                size="large"
                style={{
                  color: 'white',
                  fontSize: 15,
                  background: '#BAC2C8',
                  borderRadius: 4,
                  fontWeight: 'bold',
                  lineHeight: '20px',
                  letterSpacing: '0.2px',
                }}
              >
                <PlusOutlined /> New Order
              </Button>
            </Link>
          </Row>
        </Col>
      </Row>

      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <Table
            dataSource={orders}
            columns={columns}
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomCenter'],
            }}
            rowClassName={(record) => getClassRowByStatus[record.status]}
            rowKey={(order) => order.id}
            onRow={(order) => ({
              onClick: () => {
                history.push(`orders/${order.id}`);
              },
            })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Orders;
