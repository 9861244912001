import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation LogIn($input: Login!) {
    LogIn(input: $input) {
      ...loginFields
    }
  }

  fragment loginFields on LoginResponse {
    user {
      id
      username
      Roles {
        data {
          id
          name
        }
      }
    }
    jwtBearer
    jwtRefresh
  }
`;

export const LOGOUT = gql`
  mutation LogOut($input: LogOutInput!) {
    LogOut(input: $input) {
      message
      debug
    }
  }
`;

export const REQUEST_PASSWORD_CHANGE = gql`
  mutation RequestPasswordChange($input: RequestPasswordChangeInput!) {
    RequestPasswordChange(input: $input) {
      channel
      sentTo
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation($input: ChangePasswordInput!) {
    ChangePassword(input: $input) {
      ...messageFields
    }
  }

  fragment messageFields on MessageResponse {
    message
    debug
  }
`;

export const EXCHANGE_REFRESH_TOKEN = gql`
  mutation($input: ExchangeRefreshTokenInput!) {
    ExchangeRefreshToken(input: $input) {
      jwtBearer
    }
  }
`;
