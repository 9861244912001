import React, { useState, useEffect } from 'react';
import { Table, Col, Row, Typography, Space, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  getAllSchedules,
} from '../../../../core/services/schedule/schedule.service';
import { IScheduleBrief } from '../../../../core/interfaces/schedule.interface';
import ArrowButtonLeft from '../../../../core/components/ArrowButtonLeft';
import ArrowButtonRight from '../../../../core/components/ArrowButtonRight';
import CheckinDetail from './checkinDetail';
import { USA_TIME_FORMAT } from '../../../../core/helpers/utilities';

const { Text } = Typography;
const { Search } = Input;

const PATH_PARAM_DATE_FORMAT = 'YYYYMMDD';

const Checkin = ({ currentDate }) => {
  const valueDate = currentDate;
  const [valueSearchTerm, setSearchTerm] = useState('');
  const [checkinId, setCheckinId] = useState('');
  const [valueSchedules, setSchedules] = useState<IScheduleBrief[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<String>('loading');
  const history = useHistory();

  useEffect(() => {
    const mDate = moment(valueDate, PATH_PARAM_DATE_FORMAT, true);
    setFetchStatus('loading');
    getAllSchedules({
      date: mDate.clone().utc().format('YYYY-MM-DD'),
      search: valueSearchTerm,
    }).then(
      (resp) => {
        setFetchStatus('success');
        setSchedules(
          resp.data.filter(({ Checkins: { data } }) => data.length !== 0)
        );
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
  }, [valueDate, valueSearchTerm]);

  let content;
  if (valueFetchStatus === 'loading') {
    content = <span>Loading...</span>;
  } else if (valueFetchStatus === 'error') {
    content = <span>Error...</span>;
  } else {
    const data = valueSchedules.map(({ Checkins, ...sch }) => ({
      ...sch,
      children: Checkins.data,
    }));

    content = (
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <Table
            className="week-table"
            dataSource={data}
            rowClassName={(record) =>
              record.id === checkinId ? 'bg-light' : ''
            }
            columns={[
              {
                width: 200,
                title: 'Customer',
                key: 'customer',
                render: (record) => {
                  if (record.order) {
                    return <Text>{record.order.customer.name}</Text>;
                  }
                  return '';
                },
              },
              {
                title: 'Order #',
                key: 'nro',
                render: (record) => {
                  if (record.order) {
                    return <Text>{record.order.nro}</Text>;
                  }
                  if (record.createdBy) {
                    return (
                      <Text>
                        {record.createdBy.profile.firstName}{' '}
                        {record.createdBy.profile.lastName}{' '}
                      </Text>
                    );
                  }
                  return '';
                },
              },
              {
                title: '',
                key: 'createdBy',
                render: (record) => {
                  if (record.order) {
                    return '';
                  }
                  if (record.createdBy) {
                    return (
                      <Text>&nbsp;&nbsp;{record.createdAt.format(USA_TIME_FORMAT)}&nbsp;&nbsp;</Text>
                    );
                  }
                  return '';
                },
              },
              {
                title: '',
                key: 'description',
                render: (record) => {
                  if (record.order) {
                    return '';
                  }
                  if (record.createdBy) {
                    return <Text>{record.description.slice(0, 9) + '...'}</Text>;
                  }
                  return '';
                },
              },
              {
                title: '',
                key: 'expandable',
              },
            ]}
            expandable={{
              expandIconColumnIndex: 4,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!record.order) {
                  return (
                    <RightOutlined
                      style={{ fontSize: 15, color: '#465968' }}
                      onClick={(e) => onExpand(record, e)}
                    />
                  );
                }
                return expanded ? (
                  <DownOutlined
                    style={{ fontSize: 15, color: '#465968' }}
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                    <RightOutlined
                      style={{ fontSize: 15, color: '#465968' }}
                      onClick={(e) => onExpand(record, e)}
                    />
                  );
              },
            }}
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomCenter'],
            }}
            rowKey={({ id, order }) => `${order ? 'sch' : 'chk'}-${id}`}
            onExpand={(exp, record) => {
              if (!record.order) {
                setCheckinId(record.id);
              }
            }}
            onRow={(record) => ({
              onClick: () => {
                if (!record.order) {
                  setCheckinId(record.id);
                }
              },
            })}
          />
        </Col>
      </Row >
    );
  }
  const moveDates = (countDays) => {
    const dateString = moment(valueDate, PATH_PARAM_DATE_FORMAT, true)
      .add(countDays, 'days')
      .format(PATH_PARAM_DATE_FORMAT);

    history.push(`/safety/checkins/date/${dateString}`);
  };
  return (
    <div>
      <Space
        direction="vertical"
        style={{ width: '100%', marginBottom: 20 }}
        size="middle"
      >
        <Row style={{ marginTop: 25 }}>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000',
            }}
          >
            Check-ins
          </Text>
        </Row>
        <Row align="middle" className="custom">
          <Col span={6}>
            <ArrowButtonLeft handleClick={() => moveDates(-1)} />
            <ArrowButtonRight handleClick={() => moveDates(1)} />
            <Text
              style={{
                color: '#465968',
                fontWeight: 'bold',
                fontSize: 14,
                lineHeight: '20px',
                marginLeft: 10,
              }}
            >
              {moment(currentDate)
                .clone()
                .startOf('day')
                .isSame(moment().startOf('day'))
                ? 'Today '
                : ''}
            </Text>
            <Text
              style={{
                color: '#46596',
                fontWeight: 200,
                fontSize: 14,
                lineHeight: '20px',
              }}
            >
              {moment(valueDate, PATH_PARAM_DATE_FORMAT, true).format(
                'MMMM DD, YYYY'
              )}
            </Text>
          </Col>
          <Col offset={10} />
          <Col span={4}>
            <Search placeholder="Search" onSearch={setSearchTerm} />
          </Col>
        </Row>
      </Space>
      <Row>
        <Col span={12}> {content}</Col>
        <Col offset={2} span={10} style={{ marginTop: 50 }}>
          {checkinId ? <CheckinDetail checkinId={checkinId} /> : null}
        </Col>
      </Row>
    </div>
  );
};

export default Checkin;
