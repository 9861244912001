import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';
import { addressFieldFragment } from './address.fragment';
import { superintendentFieldsFragment } from './superintendent.fragment';

export const superintendentInCustomerFieldsFragment = gql`
  fragment superintendentInCustomerFields on Superintendent {
    ...superintendentFields
  }
  ${superintendentFieldsFragment}
`;

export const customerFieldsFragment = gql`
  fragment customerFields on Customer {
    ...entityFields
    address {
      ...addressFieldFragment
    }
    customerNro
    phone
    name
    rating
    Orders {
      data {
        id
      }
    }
  }
  ${addressFieldFragment}
  ${entityFieldsFragment}
`;

export const customerWithSuperintendentsFieldsFragment = gql`
  fragment customerWithSuperintendentsFields on Customer {
    ...customerFields
    Superintendents {
      data {
        ...superintendentInCustomerFields
      }
    }
  }
  ${customerFieldsFragment}
  ${superintendentInCustomerFieldsFragment}
`;

export const customerWithSuperintendentsWithActiveOrdersFieldsFragment = gql`
  fragment customerWithSuperintendentsWithActiveOrdersFields on Customer {
    ...customerFields
    Superintendents {
      data {
        ...superintendentInCustomerFields
        activeOrder(input: $currentDay) {
          ...entityFields
          nro
        }
      }
    }
  }
  ${customerFieldsFragment}
  ${superintendentInCustomerFieldsFragment}
`;
