import gql from 'graphql-tag';
import { ApplicantFragment } from '../../fragments/applicant.fragment';

export const ApplicantsQuery = gql`
  query Applicants($input: ApplicantPagingInput) {
    Applicants(input: $input) {
      pagingMeta {
        count
        startIndex
        endIndex
        hasNextPage
      }
      data {
        ...Applicant
      }
    }
  }
  ${ApplicantFragment}
`;

export const ApplicantByIdQuery = gql`
  query Applicant($input: ApplicantGetByIdInput) {
    Applicant(input: $input) {
      ...Applicant
    }
  }
  ${ApplicantFragment}
`;

export const DeleteApplicantById = gql`
  mutation ApplicantDelete($input: ApplicantDeleteInput) {
    ApplicantDelete(input: $input) {
      id
    }
  }
`;
