import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
// import RootContext from './RootContext';
import PublicLayout from './layouts/public';
// import Superintendent from './layouts/superintendent';
import AuthenticatedLayout from './layouts/authenticated';

function App() {
  // const [valueToken, setToken] = useState(Token());
  // ToDo: value={{valueToken, setToken}} Wont work because is compared with Object.is
  // <RootContext.Provider value={{valueToken, setToken}}>
  // </RootContext.Provider>
  return (
    <Router>
      <Switch>
        <Route path="/auth/*" component={PublicLayout} />
        {/* <Route path="/superintendent/*" component={Superintendent} /> */}
        <Route path="/*" component={AuthenticatedLayout} />
      </Switch>
    </Router>
  );
}

export default App;
