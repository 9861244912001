import React from 'react';
import arrow_left from '../images/arrows/left_arrow.svg';

const ArrowButtonLeft = ({
  handleClick,
  imgStyles,
  disabled,
}: {
  handleClick;
  imgStyles?;
  disabled?;
}) => {
  return (
    <button
      onClick={handleClick}
      style={{
        border: 'none',
        padding: 0,
        outlineColor: 'rgba(0,0,0,0.2)',
        cursor: 'pointer',
      }}
      disabled={disabled}
    >
      <img
        alt={'arrow-left'}
        src={arrow_left}
        style={{ width: 28, height: 28, ...imgStyles }}
      />
    </button>
  );
};

export default ArrowButtonLeft;
