import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const entityFieldsFragment = gql`
  fragment entityFields on Entity {
    id
    createdAt
    updatedAt
  }
`;
