import React, { useState, useEffect } from 'react';

import { Card, Col, Row, Typography, Space, message } from 'antd';

import { LeftOutlined } from '@ant-design/icons';
import WizardForm from './wizard';
import { getAllEmployees } from '../../../../core/services/employee/employee.service';
import { getAllOrders } from '../../../../core/services/order/order.service';
import { IEmployee } from '../../../../core/interfaces/employee.interface';
import { IOrder } from '../../../../core/interfaces/order.interface';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const IncidentForm = ({ incident }: { incident? }) => {
  const [valueEmployees, setEmployees] = useState<IEmployee[]>([]);
  const [valueOrders, setOrders] = useState<IOrder[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<string>('loading');

  useEffect(() => {
    setFetchStatus('loading');
    Promise.all([getAllEmployees({}), getAllOrders({})]).then(
      ([respEmployee, respOrders]) => {
        setEmployees(respEmployee.data);
        setOrders(respOrders.data);
        setFetchStatus('success');
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
  }, []);

  let content;
  if (valueFetchStatus === 'loading') {
    content = <span>Loading...</span>;
  } else if (valueFetchStatus === 'error') {
    content = <span>Error...</span>;
  } else {
    content = (
      <WizardForm
        incident={incident}
        employees={valueEmployees}
        orders={valueOrders}
      />
    );
  }
  return (
    <Row gutter={[15, 32]}>
      <Col span={3}>
        <Space>
          <Link to="/safety/incidents">
            <LeftOutlined style={{ fontSize: 15, color: 'black' }} />
          </Link>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              letterSpacing: 0.2,
              color: '#000000',
              lineHeight: '20px',
            }}
          >
            Log Incident
          </Text>
        </Space>
      </Col>
      <Col offset={1} span={16}>
        <Card style={{ background: '#fff', marginBottom: '1rem' }}>
          <Row>
            <Col span={24}>{content}</Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default IncidentForm;
