/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { userFieldsFragment } from './user.fragment';
import { entityFieldsFragment } from './entity.fragment';
import { plainOrderFieldsFragment } from './order.fragment';

export const invoiceFieldsFragment = gql`
  ${entityFieldsFragment}
  ${userFieldsFragment}
  ${plainOrderFieldsFragment}

  fragment invoiceFields on Invoice {
    id
    payPeriodStart
    payPeriodEnd
    confirmedAt
    qbDate
    orderReports {
      order {
        ...plainOrderFields
      }
      employeesOnOrder {
        rate
        timeSpentSeconds
        totalMoney
        employee {
          id
          user {
            ...userFields
          }
        }
      }
    }
    createdBy {
      ...userFields
    }
  }
`;
