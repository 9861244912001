import { Col, Row, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SkillsList from './components/SkillsList';
import './index.scss';
import CertificationList from './components/CertificationList';
import TaskList from './components/TaskList';
import WcfList from './components/WcfList';
import CostcodesList from './components/CostcodesList';

const { Text } = Typography;

const Lists = () => {
  const history = useHistory();

  return (
    <div className="employees-container">
      <Row gutter={15} style={{}}>
        <Col span={8}>
          <Row>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#000000',
                marginBottom: 5
              }}
            >
              Lists
            </Text>
          </Row>
        </Col>
      </Row>
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <SkillsList />
        </Col>
      </Row>
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <CertificationList />
        </Col>
      </Row>
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <TaskList />
        </Col>
      </Row>
      {/*
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <WcfList />
        </Col>
      </Row>*/}
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <CostcodesList />
        </Col>
      </Row>
    </div>
  );
};

export default Lists;
