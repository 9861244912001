import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';
import { pagingFieldsFragment } from './pagination.fragment';

export const skillFieldsFragment = gql`
  fragment skillFields on Skill {
    ...entityFields
    id
    name
    description
  }

  ${entityFieldsFragment}
`;

export const skillListQueryFragment = (type: string) => gql`
    fragment skillListQuery on ${type} {
        Skills(input: $skillsInput) {
            pagingMeta {
                ...pagingFields
            }
            data {
                ...skillFields
            }
        }
    }

    ${pagingFieldsFragment}

    ${skillFieldsFragment}

`;

export const workerGroupListQueryFragment = (type: string) => gql`
    fragment workerGroupsListQuery on ${type} {
        workerGroups {
            skill {
                ...skillFields
            }
            quantity
        }
    }

    ${skillFieldsFragment}

`;
