import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Space, DatePicker, message, Skeleton, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import InvoicesTable from './components/InvoicesTable';
import ArchivedTable from './components/ArchivedTable';
import {
  IActiveInvoice,
  IInvoiceLineItems,
  INextInvoiceDate
} from '../../../../core/interfaces/invoice.interface';
import {
  ActiveInvoice,
  confirmInvoice,
  generateInvoice,
  getNextInvoiceDates,
  InvoiceLineItems,
  RemoveInvoice,
  UpdateQbDateInvoice
} from '../../../../core/services/invoice/invoice.service';
import './index.scss';
import { IQuickbooksCreateInvoice } from '../../../../core/interfaces/quickbooksCreateInvoice.interface';
import { IQuickBooksInvoiceLineItems } from '../../../../core/interfaces/quickBooksInvoiceLineItems.interface';
import { createInvoices } from '../../../../core/services/quickbooks/quickbooks.service';

const Invoces = () => {
  const [rangeDateSelected, setRangeDateSelected] = useState<any[] | any>(null);
  const [activeInvoice, setactiveInvoice] = useState<IActiveInvoice | null>(
    null
  );
  const [isConfirmedInvoice, setIsConfirmedInvoice] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [totalLines, setTotalLines] = useState<number>();
  const [reloadArchived, setRealoadArchived] = useState<number>(0);
  const [payPeriodCompleted, setPayPeriodCompleted] = useState<boolean>(false);
  const [lastPayPeriod, setLastPayPeriod] = useState<string>('');

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getActiveInvoices();
  }, []);

  const getActiveInvoices = async () => {
    setIsLoading(true);
    setIsConfirmedInvoice(false);
    setPayPeriodCompleted(false);
    try {
      const data: IActiveInvoice = await ActiveInvoice();
      if (data) {
        setactiveInvoice(data);
        if (data.confirmedAt) {
          setIsConfirmedInvoice(true);
          setPayPeriodCompleted(true);
        }
        setIsLoading(false);
      } else {
        setactiveInvoice(null);
        getNextInvoiceDatesReq();
      }
    } catch (err) {
      message.error('Error calling active invoices!');
    }
  };

  const getNextInvoiceDatesReq = async () => {
    try {
      const data: INextInvoiceDate = await getNextInvoiceDates();
      if (data) {
        const payPeriodStart = moment(data.payPeriodStart);
        const payPeriodEnd = moment(data.payPeriodEnd);
        setRangeDateSelected([payPeriodStart, payPeriodEnd]);
        setIsLoading(false);
      }
    } catch (error) {
      setRangeDateSelected(null);
      setIsLoading(false);
      message.error('No invoices yet');
    }
  };

  const generateInvoiceReq = async () => {
    setLoadingBtn(true);
    const [start, end]: any = rangeDateSelected;
    try {
      const data: IActiveInvoice = await generateInvoice(
        start.toISOString(),
        end.toISOString()
      );
      setLoadingBtn(false);
      setactiveInvoice(data);
      message.success('Invoice generated!');
      setRealoadArchived(reloadArchived + 1);
    } catch (error) {
      setLoadingBtn(false);
      message.error('There are no reports for the current period');
    }
  };

  const confirmInvoiceReq = async () => {
    setLoadingBtn(true);
    try {
      const data: IActiveInvoice = await confirmInvoice(activeInvoice?.id);
      if (data.confirmedAt) {
        setIsConfirmedInvoice(true);
        setPayPeriodCompleted(true);
        message.success('Invoice confirmed!');
        setLoadingBtn(false);
        setRealoadArchived(reloadArchived + 1);
      }
    } catch (error) {
      setLoadingBtn(false);
      message.error('Error confirming invoice!');
    }
  };

  const pushAllToQb = async () => {
    setLoadingBtn(true);
    if (activeInvoice && totalLines) {
      try {
        const data: IInvoiceLineItems = await InvoiceLineItems(
          activeInvoice.id,
          totalLines,
          0
        );
        const dataQB = transformDataQb(data);
        await createInvoices(dataQB);
        message.success('Invoice submitted successfully into Quick Books');

        try {
          await UpdateQbDateInvoice(activeInvoice.id);
        } catch (error) {
          setLoadingBtn(false);
          message.error('Error updating invoice QB date');
        }
        setLoadingBtn(false);
        getActiveInvoices();
        setRealoadArchived(reloadArchived + 1);
      } catch (error) {
        setLoadingBtn(false);
        message.error('Error Pushing invoice to QB');
      }
    }
  };

  const transformDataQb = (
    data: IInvoiceLineItems
  ): IQuickbooksCreateInvoice[] => {
    let body: IQuickbooksCreateInvoice[] = [];
    data.data
      .filter((order) => !order.lineItems[0].qbDate)
      .forEach((order) => {
        let lines: IQuickBooksInvoiceLineItems[] = [];
        order.lineItems.forEach((line) => {
          const lineBody = {
            rate: line.rate,
            Description: `${
              line.employee.user.profile.firstName +
              ' ' +
              line.employee.user.profile.lastName
            } ${line.type === 'REGULAR' ? 'Reg. Hrs.' : '0/T Hrs'} ( ${
              line.task
            } )`,
            ItemFullName:
              line.type === 'REGULAR' ? 'Regular Hours' : 'Overt Time Hrs',
            Quantity: parseFloat((line.timeSpentSeconds / 3600).toFixed(2))
          };
          lines.push(lineBody);
        });
        const order2 = order.order;
        let address;
        if (order2.address) {
          address = `${order2.address.address1} ${order2.address.address2}`;
        }
        const superintendentProfile = order2.superintendent.user.profile;
        const customerMessage = `${order2.customer.name}/Job #${order2.jobNumber}/${order2.jobSiteName}/${address}/${superintendentProfile.firstName} ${superintendentProfile.lastName}`;
        const orderBody = {
          CustomerFullName: order2.customer.name,
          TxnDate: moment().toISOString(),
          ShipDate: activeInvoice
            ? moment(activeInvoice.payPeriodEnd).toISOString()
            : '',
          CustomerMsg: customerMessage,
          Lines: lines
        };
        body.push(orderBody);
      });
    return body;
  };

  const removeInvoiceReq = async () => {
    setIsLoading(true);
    try {
      await RemoveInvoice(activeInvoice?.id);
      message.success('Invoice removed!');
      setRealoadArchived(reloadArchived + 1);
      setactiveInvoice(null);
      setIsConfirmedInvoice(false);
      setPayPeriodCompleted(false);
      getNextInvoiceDatesReq();
    } catch (error) {
      setIsLoading(false);
      message.error('Error removing invoice!');
    }
  };

  const onNewInvoice = () => {
    setIsLoading(true);
    setactiveInvoice(null);
    setIsConfirmedInvoice(false);
    setPayPeriodCompleted(false);
    getNextInvoiceDatesReq();
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : activeInvoice ? (
        <>
          <Tooltip title="Delete invoice">
            <Button
              type="primary"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={removeInvoiceReq}
            />
          </Tooltip>

          <h1
            style={{
              fontSize: '22px',
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
          >
            Current Invoices
          </h1>
          <div className="container">
            <div className="top__container">
              <div>
                <p>
                  <strong>Pay period </strong>
                  <span className="span__pay__period">
                    {payPeriodCompleted ? 'completed' : 'incompleted'}
                  </span>
                </p>
                <p>{`Last pay period completed on ${lastPayPeriod || '-'}`}</p>
              </div>
              <Button
                loading={loadingBtn}
                type="primary"
                size="middle"
                disabled={activeInvoice && totalLines ? false : true}
                onClick={isConfirmedInvoice ? pushAllToQb : confirmInvoiceReq}
              >
                {isConfirmedInvoice ? 'PUSH TO QB' : 'CONFIRM INVOICE'}
              </Button>
            </div>

            <InvoicesTable
              onTotalLines={(total) => setTotalLines(total)}
              newInvoice={onNewInvoice}
              invoice={activeInvoice}
              disableEdit={isConfirmedInvoice}
            />
          </div>
        </>
      ) : (
        <Space className="date__picker" direction="horizontal" size={12}>
          <RangePicker
            allowEmpty={[false, false]}
            onChange={setRangeDateSelected}
            value={rangeDateSelected}
          />
          <Button
            loading={loadingBtn}
            type="primary"
            size="middle"
            disabled={rangeDateSelected ? false : true}
            onClick={generateInvoiceReq}
          >
            GENERATE INVOICE
          </Button>
        </Space>
      )}
      <ArchivedTable
        reload={reloadArchived}
        lastPayPeriod={(value) => setLastPayPeriod(value)}
      />
    </>
  );
};

export default Invoces;
