import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import JobsTable from './components/JobsTable';
import './index.scss';

const { Text } = Typography;

const Jobs = () => {
  const { Search } = Input;
  return (
    <>
      <Col span={24} style={{ marginBottom: 10 }}>
        <Row style={titleContainer}>
          <Text style={title}>Jobs</Text>
          <Link to="/jobs/form-job">
            <Button type="default" size="large" style={button}>
              <PlusOutlined /> New Job
            </Button>
          </Link>
        </Row>
      </Col>

      <Col span={6}>
        <Search
          style={{ marginBottom: 10 }}
          placeholder="Search"
          allowClear
          onSearch={(search) => {
            console.log('value', search);
          }}
        />
      </Col>

      <JobsTable />
    </>
  );
};

const titleContainer: React.CSSProperties = {
  marginBottom: 30,
  alignItems: 'center',
  justifyContent: 'space-between'
};

const title: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: 18,
  lineHeight: '20px',
  letterSpacing: '0.2px',
  color: '#000000'
};

const button: React.CSSProperties = {
  color: 'white',
  fontSize: 15,
  background: '#BAC2C8',
  borderRadius: 4,
  fontWeight: 'bold',
  lineHeight: '20px',
  letterSpacing: '0.2px'
};

export default Jobs;
