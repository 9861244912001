/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const paymentFieldsFragment = gql`
  fragment Payment on Payment {
    id
    payPeriodStart
    payPeriodEnd
    confirmedAt
    qbDate
    createdBy {
      ...userFields
    }
  }
`;
