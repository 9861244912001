import { FUESApolloClient } from '../../helpers/api';
import { ApolloQueryResult } from 'apollo-boost';
import { IncidentListQuery } from './incident.gql';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import {
  IIncident,
} from '../../interfaces/incident.interface';
import { IncidentCreateMutation } from './incident.gql';

// export const createIncident = async (input) => {
//   console.log(input);
// }
export const createIncident = async (input) =>
  FUESApolloClient()
    .mutate({
      mutation: IncidentCreateMutation,
      variables: {
        input,
      },
    })
    .then((resp) => resp.data.IncidentCreate);

export const getAllIncidents = (args = {}) => {
  return FUESApolloClient()
    .query<{ Incident: IPaginationWrapper<IIncident> }>({
      fetchPolicy: 'no-cache',
      query: IncidentListQuery,
      variables: {
        incidentInput: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc',
          },
          args,
        },
      },
    })
    .then(
      (resp: ApolloQueryResult<{Incident: IPaginationWrapper<IIncident>}>) =>
        resp.data.Incident
    );
};

export const getIncident = async (id) => {
  const incident = await getAllIncidents({ id });
  return incident.data[0];
};
