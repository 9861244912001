import React from 'react';
import { Col, Row, Typography, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// Components
import EmployeeForm from './EmployeeForm';
const { Text } = Typography;
const NewEmployee = () => {
  return (
    <div className="employee-container">
      <Row gutter={[15, 32]}>
        <Col span={4}>
          <Link to={'/employees'}>
            <Space>
              <LeftOutlined style={{ fontSize: 20, color: 'black' }} />
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 0.2,
                  color: '#000000',
                  lineHeight: '20px',
                  marginLeft: 5,
                }}
              >
                New Employee
              </Text>
            </Space>
          </Link>
        </Col>
        <Col offset={1} span={12}>
          <EmployeeForm />
        </Col>
      </Row>
    </div>
  );
};

export default NewEmployee;
