import gql from 'graphql-tag';
import { traceFieldsFragment } from '../../fragments/trace.fragment';
import { pagingFieldsFragment } from '../../fragments/pagination.fragment';

export const GetTracesQuery = gql`
  query OrderTrace($input: OrderTracePagingInput) {
    OrderTrace(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...traceFields
      }
    }
  }

  ${pagingFieldsFragment}
  ${traceFieldsFragment}

  fragment pagingFields on PagingMetaResult {
    count
    startIndex
    endIndex
    hasNextPage
  }
`;

export const BulkConfirmEmployeeTraceMutation = gql`
  mutation BulkConfirmEmployeeTrace($input: BulkConfirmEmployeeTraceInput!) {
    BulkConfirmEmployeeTrace(input: $input) {
      ...EmployeeTraceFields
    }
  }

  fragment entityFields on Entity {
    id
    createdAt
    updatedAt
    __typename
  }

  fragment EmployeeTraceFields on EmployeeTrace {
    employee {
      ...entityFields
    }
    item {
      timeSpentSeconds
      dateTraces {
        date
        feedback
        confirmed
        item {
          timeSpentSeconds
          taskTraces {
            task {
              ...entityFields
            }
            item {
              timeSpentSeconds
              timeEntries {
                ...entityFields
                secondsTimeSpan
              }
            }
          }
        }
      }
    }
  }
`;

export const SaveFeedbackEmployeeTraceOnDateRangeMutation = gql`
  mutation SaveFeedbackEmployeeTraceOnDateRange(
    $input: SaveFeedbackEmployeeTraceOnDateRangeInput!
  ) {
    SaveFeedbackEmployeeTraceOnDateRange(input: $input) {
      ...EmployeeTraceFields
    }
  }

  fragment entityFields on Entity {
    id
    createdAt
    updatedAt
    __typename
  }

  fragment EmployeeTraceFields on EmployeeTrace {
    employee {
      ...entityFields
    }
    item {
      timeSpentSeconds
      dateTraces {
        date
        feedback
        confirmed
        item {
          timeSpentSeconds
          taskTraces {
            task {
              ...entityFields
            }
            item {
              timeSpentSeconds
              timeEntries {
                ...entityFields
                secondsTimeSpan
              }
            }
          }
        }
      }
    }
  }
`;

export const SaveFeedbackEmployeeTraceOnDayMutation = gql`
  mutation SaveFeedbackEmployeeTraceOnDay(
    $input: SaveFeedbackEmployeeTraceOnDayInput!
  ) {
    SaveFeedbackEmployeeTraceOnDay(input: $input) {
      ...EmployeeTraceFields
    }
  }

  fragment entityFields on Entity {
    id
    createdAt
    updatedAt
    __typename
  }

  fragment EmployeeTraceFields on EmployeeTrace {
    employee {
      ...entityFields
    }
    item {
      timeSpentSeconds
      dateTraces {
        date
        feedback
        confirmed
        item {
          timeSpentSeconds
          taskTraces {
            task {
              ...entityFields
            }
            item {
              timeSpentSeconds
              timeEntries {
                ...entityFields
                secondsTimeSpan
              }
            }
          }
        }
      }
    }
  }
`;
