import gql from 'graphql-tag';
import { superintendentFieldsFragment } from '../../fragments/superintendent.fragment';

export const InviteSuperintendentMutation = gql`
  mutation SuperintendentInvite($input: SuperintendentInvite!) {
    SuperintendentInvite(input: $input) {
      ...superintendentFields
    }
  }
  ${superintendentFieldsFragment}
`;

export const ReInviteSuperintendentMutation = gql`
  mutation SuperintendentReInvite($input: SuperintendentReInviteInput!) {
    SuperintendentReInvite(input: $input) {
      ...superintendentFields
    }
  }
  ${superintendentFieldsFragment}
`;

export const DeleteSuperintendentMutation = gql`
  mutation SuperintendentDelete($input: EntityDeleteInput!) {
    SuperintendentDelete(input: $input) {
      id
    }
  }
`;
