/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';

export const checkinFieldsFragment = gql`
  fragment checkinFields on Checkin {
    ...entityFields
    createdBy {
      ...entityFields
      profile {
        firstName
        lastName
      }
    }
    comment
    has_hazards
    description_hazards
    photo_hazards {
      ...entityFields
      url
    }
    description
    attachments {
      ...entityFields
      url
    }
    schedule {
      id
      date
      order {
        ...entityFields
        id
        customer {
          id
          name
        }
      }
    }
  }

  ${entityFieldsFragment}
`;
