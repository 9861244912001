import ApolloClient, { InMemoryCache } from 'apollo-boost';
import axios from 'axios';
import { isArray } from 'util';
import { Token, Clear } from './storage';

const GRAPH_ENDPOINT = process.env.REACT_APP_GRAPH_ENDPOINT;
const REST_ENDPOINT = process.env.REACT_APP_REST_ENDPOINT;

export const FUESApolloClient = (nonAuthenticated = false) => {
  const token = Token();
  return new ApolloClient({
    uri: GRAPH_ENDPOINT,
    cache: new InMemoryCache(),
    headers: nonAuthenticated
      ? {}
      : {
          Authorization: `Bearer ${token?.jwtBearer}`
        },
    onError: (err) => {
      if (err?.networkError?.message === 'Failed to fetch') {
        // eslint-disable-next-line no-console
        console.error(err);
        // ToDo: Have to handle gracefully. Redirect to error page?
        throw new Error('Network error');
      }
      const responseErrors = err.response?.errors;
      /* if (responseErrors && responseErrors.length > 0) {
        const e = responseErrors[0];
        throw new Error(e.message ? e.message : 'Error');
      } else */
      if (!responseErrors?.length) {
        // eslint-disable-next-line no-console
        // ToDo: Have to handle gracefully. Redirect to error page?
        throw new Error('Unhandled error');
      }
      const errorString = JSON.stringify(responseErrors);
      if (errorString.includes('UnAuthorized') && !nonAuthenticated) {
        Clear('*');
        window.location.href = '/auth/relogin';
        throw new Error('Session expired. Redirecting to log in');
        // ExchangeRefreshToken(token.jwtRefresh).then(
        //   resp => {
        //     const newToken = resp.data;
        //     Token(newToken);
        //     return new ApolloClient({
        //       uri: GRAPH_ENDPOINT,
        //       cache: new InMemoryCache(),
        //       headers: {
        //         Authorization: `Bearer ${newToken?.jwtBearer}`,
        //       },
        //       onError: (err) => {
        //         const responseErrors = err.response?.errors;
        //         if (responseErrors?.length) {
        //           const errorString = JSON.stringify(responseErrors);
        //           if (errorString.includes('UnAuthorized') && token) {
        //             throw new Error('Can retry after refresh!');
        //           }
        //         } else {
        //           throw new Error('Unhandled error');
        //         }
        //       }
        //     });
        //   },
        //   (err) => {
        //     Clear('*');
        //     // ToDo: I think I can use context outside render thread...
        //     // ToDo: reset token in context
        //     const context = useContext(RootContext);
        //     throw err;
        //   }
        // );
      }
    }
  });
};

// const apolloUnAuthInstance = FUESApolloClient();
// const apolloAuthInstance = FUESApolloClient();

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  validateStatus: (status) => status < 400
});

const encodeQueryParams = (url, query) => {
  const encodeURL = new URL(url);
  // ToDo: Have to agree how to encode null
  if (query) {
    Object.entries(query).forEach(([k, v]) =>
      encodeURL.searchParams.append(k, v as string)
    );
  }
  return encodeURL;
};

const axiosCall = async (
  url,
  { query, ...requestOptions },
  authCall,
  doOn401 = async () => Clear('*')
  // doOn401 = ExchangeRefreshToken,
) => {
  try {
    const response = await axiosInstance(
      encodeQueryParams(`${REST_ENDPOINT}${url}`, query).toString(),
      {
        method: requestOptions.method,
        data: requestOptions.body,
        headers: requestOptions.headers
      }
    );
    if (response.status >= 200 && response.status < 400) {
      return response.data;
    }

    throw new Error('Internal error');
  } catch (error) {
    if (error.response && error.response.status) {
      if (error.response.status < 500) {
        if (error.response.status === 401 && authCall) {
          Clear('*');
          if (doOn401) {
            return doOn401().then(() => {
              axiosCall(
                url,
                { query, ...requestOptions },
                authCall,
                async () => {
                  // eslint-disable-next-line no-console
                  console.log('cant retry after refresh');
                  // throw new PermClientError([error.response.data.message], error.response.status);
                }
              );
            });
          }
          // throw new PermClientError([error.response.data.message], error.response.status);
        }
        console.log(error.response.data.message);
        throw new Error(error.response.data.message + error.response.status);
      } else {
        throw new Error('Internal error');
      }
    }
    throw new Error('Internal error');
  }
};

export const authRestCall = (url, requestOptions, doOn401?) =>
  axiosCall(
    url,
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        Authorization: `Bearer ${Token()?.jwtBearer}`
      }
    },
    true,
    doOn401
  );
