import React from 'react';
import { Col, Row, Typography, Radio, Input, Select, Form } from 'antd';

import {
  NatureOfInjury,
  TypeOfContact,
  Illness,
} from '../../../../core/enums/health.enum';

import WitnessForm from './witnessForm';
import BodyPartInput from './BodyPartInput';

const { Text } = Typography;
const { TextArea } = Input;

const ComponentB = ({
  isReadOnly,
  display,
  title,
}: {
  isReadOnly;
  display;
  title;
}) => {
  return (
    <Row style={{ display }} gutter={[16, 32]}>
      <Col span={24}>
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 0.2,
            color: '#000000',
            lineHeight: '20px',
          }}
        >
          Part {title}
        </Text>
        <Row gutter={[16, 32]}>
          <Col span={8}>
            <Text className="incident-label">Nature of injury</Text>
            <Form.Item
              name="injureNature"
              // rules={[
              //   { required: true, message: 'nature of injury is required' },
              // ]}
            >
              <Select
                className="filter-label"
                disabled={isReadOnly}
                // optionFilterProp="label"
                // filterOption
                // showSearch
                size="large"
                // onChange={(employeeId) => {}}
                options={Object.keys(NatureOfInjury).map((value) => ({
                  value,
                  label: value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Text className="incident-label">Type of contact</Text>
            <Form.Item
              name="injureContact"
              // rules={[
              //   { required: true, message: 'type of contact is required' },
              // ]}
            >
              <Select
                className="filter-label"
                disabled={isReadOnly}
                // optionFilterProp="label"
                // filterOption
                // showSearch
                size="large"
                // onChange={(employeeId) => {}}
                options={Object.keys(TypeOfContact).map((value) => ({
                  value,
                  label: value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Text className="incident-label">Illness</Text>
            <Form.Item
              name="injureIllness"
              // rules={[{ required: true, message: 'Illness is required' }]}
            >
              <Select
                className="filter-label"
                disabled={isReadOnly}
                // optionFilterProp="label"
                // filterOption
                // showSearch
                size="large"
                // onChange={(employeeId) => {}}
                options={Object.keys(Illness).map((value) => ({
                  value,
                  label: value,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Text className="incident-label">Part of body</Text>
          <Row justify="center" gutter={15}>
            <Col>
              <Form.Item name="bodyParts">
                <BodyPartInput width={514} height={538} disabled={isReadOnly} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Text className="incident-label">First Aid/Emergency Services </Text>
          <Text className="incident-label">
            (record any observations and treatment provided, as well as any
            advice given to the patient)
          </Text>
          <Form.Item
            name="firstAidNotes"
            // rules={[
            //   {
            //     required: true,
            //     message: 'First Aid/Emergency Services is required',
            //   },
            // ]}
          >
            <TextArea className="textArea" rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text className="incident-label" />
          <Form.Item
            name="returnedToDuty"
            // rules={[{ required: true, message: 'Normal duties is required' }]}
          >
            <Radio.Group>
              <Radio disabled={isReadOnly} value>
                <Text className="incident-label"> Return to normal duties</Text>
              </Radio>
              <Radio
                className="filter-label"
                disabled={isReadOnly}
                value={false}
              >
                <Text className="incident-label">
                  Referred or Transmitted for Medical Treatment
                </Text>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <WitnessForm disabled={isReadOnly} />
        </Col>
      </Col>
    </Row>
  );
};

export default ComponentB;
