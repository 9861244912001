import React from 'react';
import { AutoComplete, Col, Form, Typography } from 'antd';

const { Text } = Typography;

const OrderAutocompleteForm = ({ superintendents, form, disabled }) => {
  // Autocomplete
  const onSelectEmail = (
    email,
    {
      user: {
        profile: { firstName, lastName, phone },
      },
    }
  ) => {
    form.setFieldsValue({ reportToFirstName: firstName });
    form.setFieldsValue({ reportToLastName: lastName });
    form.setFieldsValue({ reportToPhone: phone });
    form.setFieldsValue({ reportToEmail: email });
  };

  const onChangeEmail = (email: string) => {
    form.setFieldsValue({ reportToEmail: email });
    const foundSuperintendent = superintendents.find(
      ({ superintendent }) => superintendent.user.profile.email === email
    );

    if (foundSuperintendent) {
      form.setFieldsValue({
        reportToPhone: foundSuperintendent.superintendent.user.profile.phone,
      });
      form.setFieldsValue({
        reportToFirstName:
          foundSuperintendent.superintendent.user.profile.firstName,
      });
      form.setFieldsValue({
        reportToLastName:
          foundSuperintendent.superintendent.user.profile.lastName,
      });
    }
  };

  const onSelectPhone = (
    phone,
    {
      user: {
        profile: { firstName, lastName, email },
      },
    }
  ) => {
    form.setFieldsValue({ reportToPhone: phone });
    form.setFieldsValue({ reportToFirstName: firstName });
    form.setFieldsValue({ reportToLastName: lastName });
    form.setFieldsValue({ reportToEmail: email });
  };
  const onChangePhone = (phone: string) => {
    form.setFieldsValue({ reportToPhone: phone });
    const foundSuperintendent = superintendents.find(
      ({ superintendent }) => superintendent.user.profile.phone === phone
    );

    if (foundSuperintendent) {
      form.setFieldsValue({
        reportToEmail: foundSuperintendent.superintendent.user.profile.email,
      });
      form.setFieldsValue({
        reportToFirstName:
          foundSuperintendent.superintendent.user.profile.firstName,
      });
      form.setFieldsValue({
        reportToLastName:
          foundSuperintendent.superintendent.user.profile.lastName,
      });
    }
  };
  return (
    <>
      <Col span={12}>
        <Text className="new-form-label">email</Text>
        <Form.Item
          name="reportToEmail"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'email is required',
            },
          ]}
        >
          <AutoComplete
            disabled={disabled}
            style={{ fontWeight: 400 }}
            size="large"
            options={superintendents.map(({ superintendent }) => ({
              value: superintendent.user.profile.email,
              option: superintendent,
            }))}
            onSelect={(value, option) => onSelectEmail(value, option.option)}
            filterOption={(inputValue, option) =>
              option?.value
                ? option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                : false
            }
            onChange={onChangeEmail}
            placeholder="Search by email"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Text className="new-form-label">phone</Text>
        <Form.Item
          name="reportToPhone"
          rules={[
            {
              required: true,
              message: 'phone is required',
            },
          ]}
        >
          {/* ToDo: Make and MaskedAutoComplete, or PhoneAutoComplete */}
          <AutoComplete
            disabled={disabled}
            style={{ fontWeight: 400 }}
            size="large"
            options={superintendents.map(({ superintendent }) => ({
              value: superintendent.user.profile.phone,
              option: superintendent,
            }))}
            onSelect={(value, option) => onSelectPhone(value, option.option)}
            filterOption={(inputValue, option) =>
              option?.value
                ? option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                : false
            }
            onChange={onChangePhone}
            placeholder="search by phone"
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default OrderAutocompleteForm;
