import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Typography, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

// Components
import OrderForm from './OrderForm';

const { Text } = Typography;

const NewOrder = () => {
  return (
    <div className="order-container">
      <Row gutter={[15, 32]}>
        <Col span={3}>
          <Link to={'/orders'}>
            <Space>
              <LeftOutlined style={{ color: 'black' }} />
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 0.2,
                  color: '#000000',
                  lineHeight: '20px',
                }}
              >
                New Order
              </Text>
            </Space>
          </Link>
        </Col>
        <Col offset={1} span={20}>
          <OrderForm />
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(NewOrder);
