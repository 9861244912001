export const parsePhone = (number) => {
  if (!number) return null;
  const onlyNums = number.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

export const normalizePhone = (phoneString) => {
  if (!phoneString) return null;
  return phoneString.replace(/[^\d]/g, '');
};

const PHONE_RE = /^\s*\+?(\s*[0-9])+\s*$/;
export const isPhone = (phoneString) => {
  return PHONE_RE.test(phoneString);
};
