import React from 'react';
import { Button, Col, Dropdown, Menu, Row, Typography } from 'antd';
import {
  // IdcardOutlined,
  DownOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { LogOut } from '../../services/auth/auth.service';
import { useHistory } from 'react-router-dom';
const { Text } = Typography;

const TopNavigation = ({ username }) => {
  const history = useHistory();
  return (
    <Row
      justify="end"
      align="middle"
      style={{
        borderLeft: '1px solid',
        borderLeftColor: 'rgba(70, 89, 104, 0.25)',
      }}
    >
      <Col span={24}>
        <Dropdown
          overlay={
            <Menu style={{ minWidth: 150 }}>
              {/* <Menu.Item>
                <Link to="/profile-settings" style={{ marginTop: 4, marginBottom: 4 }}>
                  <IdcardOutlined />
                  <span style={{ paddingLeft: 8 }}>Profile</span>
                </Link>
              </Menu.Item> */}
              <Menu.Item>
                <Button
                  type="text"
                  onClick={() => {
                    LogOut().then(() => history.push('/auth/login'));
                  }}
                  style={{ marginTop: 4, marginBottom: 4 }}
                >
                  <LogoutOutlined />
                  <span style={{ paddingLeft: 8 }}>Logout</span>
                </Button>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <a href="/" onClick={(e) => e.preventDefault()}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <div>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 12,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    color: '#465968',
                    marginLeft: 15,
                  }}
                >
                  {username}
                </Text>
                <DownOutlined
                  style={{
                    marginLeft: 15,   
                    fontSize: 12,
                    color: '#465968',
                    position: 'absolute',
                    top: 21,
                  }}
                />
              </div>
            </div>
          </a>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default TopNavigation;
