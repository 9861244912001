import React from 'react';
import { Button, Card, Col, Row, Select, Upload, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DateOnlyInput from './DateOnlyInput';
import { Token } from '../helpers/storage';
import { getFileNameFromUrl } from '../helpers/utilities';

const { Text } = Typography;

const CertificationSelectControlled = ({
  certificates,
  certificationChanged,
  availableCertifications,
}: {
  certificates;
  certificationChanged;
  availableCertifications;
}) => {
  // const [selectedCert, setSelectedCerts] = useState<any[]>([]);
  // useEffect(() => {
  //     if (certificates) {
  //         setSelectedCerts(certificates.map(({catalog, ...more}) => ({catalog: catalog.id, ...more})));
  //     }
  // }, [certificates]);
  const value = certificates;
  const onChange = certificationChanged;

  const token = Token();
  if (!token) {
    return null;
  }
  return (
    <Card style={{ marginBottom: 15 }}>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Certifications"
        value={value.map(({ catalog: id }) => id)}
        onChange={(e: string[]) => {
          const vv = e.map((id: string) => {
            const existingCatalog = availableCertifications.find(
              (x) => x.id === id
            );
            const existingCert = value.find((x) => x.catalog === id);
            return {
              catalog: existingCatalog ? existingCatalog.id : null,
              media: existingCert ? existingCert.media : null,
              issuedAt: existingCert ? existingCert.issuedAt : null,
            };
          });
          onChange(vv);
        }}
        optionFilterProp="label"
        filterOption
        options={availableCertifications.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
      />
      {value.map((cert, indexx) => {
        const catalog = availableCertifications.find(
          (x) => x.id === cert.catalog
        );
        return (
          <Row
            gutter={16}
            justify="space-between"
            key={catalog?.id}
            align="middle"
            style={{
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Col span={10}>
              <Text strong> {catalog?.name}</Text>
            </Col>
            <Col span={8}>
              <DateOnlyInput
                value={cert.issuedAt}
                onChange={(date) => {
                  onChange(
                    value.map((cc, index) =>
                      index === indexx
                        ? {
                            ...cc,
                            issuedAt: date,
                          }
                        : cc
                    )
                  );
                }}
              />
            </Col>
            <Col span={6}>
              <Upload
                name="file"
                data={{
                  fileType: 'Document',
                }}
                action={`${process.env.REACT_APP_REST_ENDPOINT}file-upload`}
                headers={{
                  Authorization: `Bearer ${token?.jwtBearer}`,
                }}
                onChange={(info) => {
                  if (info.file.status === 'uploading') {
                    // eslint-disable-next-line no-console
                    console.log('Uploading...');
                  } else if (info.file.status === 'done') {
                    // eslint-disable-next-line no-console
                    console.log(`${info.file.name} file uploaded successfully`);
                    onChange(
                      value.map((cc, index) =>
                        index === indexx
                          ? {
                              ...cc,
                              media: {
                                ...info.file.response,
                                id: info.file.response._id,
                              },
                            }
                          : cc
                      )
                    );
                  } else if (info.file.status === 'error') {
                    // eslint-disable-next-line no-console
                    console.log(`${info.file.name} file upload failed.`);
                  }
                }}
                onRemove={() => {
                  onChange(
                    value.map((cc, index) =>
                      index === indexx
                        ? {
                            ...cc,
                            media: null,
                          }
                        : cc
                    )
                  );
                }}
                defaultFileList={
                  cert.media
                    ? [
                        {
                          uid: cert.media.id,
                          name: getFileNameFromUrl(cert.media.url),
                          ...cert.media,
                        },
                      ]
                    : []
                }
              >
                <Button>
                  <UploadOutlined /> Choose file
                </Button>
              </Upload>
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};

export default CertificationSelectControlled;
