import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const mediaFieldsFragment = gql`
  fragment mediaFields on Media {
    id
    url
    mediaType
  }
`;
