import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import {
  CertificationCreate as createMutation,
  CertificationsListQuery,
  CertificationUpdate as updateMutation,
  CertificationDelete as deleteMutation
} from './certification.gql';
import { ICertification, ICertificationResponse } from '../../interfaces/certification.interface';

export const getAllCertifications = () => {
  return FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: CertificationsListQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc'
          },
          args: {}
        }
      }
    })
    .then(
      (resp: ApolloQueryResult<ICertificationResponse>) =>
        resp.data.Certification
    );
};

export const createCertification = (input: { name: string }): Promise<ICertification | undefined> => {
  return FUESApolloClient()
    .mutate<{ CertificationCreate: ICertification }>({
      fetchPolicy: 'no-cache',
      mutation: createMutation,
      variables: {
        input: {
          ...input,
          description: input.name
        }
      }
    })
    .then(res => {
      if (res.data && res.data.CertificationCreate) {
        return res.data.CertificationCreate;
      }
    });
};

export const updateCertification = (input: ICertification): Promise<ICertification | undefined> => {
  return FUESApolloClient()
    .mutate<{ CertificationUpdate: ICertification }>({
      fetchPolicy: 'no-cache',
      mutation: updateMutation,
      variables: {
        input
      }
    })
    .then(res => {
      if (res.data && res.data.CertificationUpdate) {
        return res.data.CertificationUpdate;
      }
    });
};

export const deleteCertification = (id: string): Promise<ICertification | undefined> => {
  return FUESApolloClient()
    .mutate<{ CertificationDelete: ICertification }>({
      fetchPolicy: 'no-cache',
      mutation: deleteMutation,
      variables: {
        input: {
          id
        }
      }
    })
    .then(res => {
      if (res.data && res.data.CertificationDelete) {
        return res.data.CertificationDelete;
      }
    });
};
