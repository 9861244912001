import React from 'react';
import { Col, Row, Typography, Input, message, Form, Button, Card } from 'antd';
import { sendSmsMutationEmployee } from '../../../core/services/employee/employee.service';

const MSG_KEY = 'sendingMsg';

const Messaging = () => {
  const [formInstance] = Form.useForm();
  return (
    <Form
      form={formInstance}
      initialValues={{ msg: '' }}
      onFinish={(values) => {
        message.loading({ content: 'Sending...', key: MSG_KEY });
        sendSmsMutationEmployee(values).then(
          () => {
            formInstance.resetFields();
            message.success({
              content: 'Message Sent',
              key: MSG_KEY,
              duration: 2,
            });
          },
          (err) => {
            message.error({
              content: err.message || 'Unexpected Error',
              key: MSG_KEY,
              duration: 2,
            });
          }
        );
      }}
    >
      <Row>
        <Col md={24} lg={12}>
          <Card>
            <Row>
              <Col span={24}>
                <Typography.Text>Message</Typography.Text>
                <Form.Item
                  name="msg"
                  rules={[
                    { required: true, message: 'The message body is required' },
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Message" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  style={{
                    backgroundColor: '#D7DEE2',
                    fontSize: 14,
                    borderRadius: 4,
                    fontWeight: 600,
                    letterSpacing: 0.2,
                  }}
                  htmlType="submit"
                >
                  <Typography.Text className="btn-label">Send</Typography.Text>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default Messaging;
