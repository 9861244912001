
// ToDo: Non implemented. lowercase. trim
export const normalizeEmail = (inn) => {
  if (!inn) return null;
  return inn.toLowerCase().trim();
};

// const EMAIL_RE = /^\s*\+?(\s*[0-9])+\s*$/;
export const isEmail = (inn) => {
  return inn.includes('@');
};
