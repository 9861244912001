import { FUESApolloClient } from '../../helpers/api';
import { ISuperintendent } from '../../interfaces/superintendent.interface';
import {
  InviteSuperintendentMutation,
  ReInviteSuperintendentMutation,
  DeleteSuperintendentMutation,
} from './superintendent.gql';

export const inviteSuperintendent = (payload) => {
  return FUESApolloClient()
    .mutate<{ SuperintendentInvite?: ISuperintendent }>({
      mutation: InviteSuperintendentMutation,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data?.SuperintendentInvite as ISuperintendent);
};

export const reInviteSuperintendent = (payload) => {
  return FUESApolloClient()
    .mutate({
      mutation: ReInviteSuperintendentMutation,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.SuperintendentReInvite);
};

export const deleteSuperintendent = (payload) => {
  return FUESApolloClient()
    .mutate({
      mutation: DeleteSuperintendentMutation,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.SuperintendentDelete);
};
