import React from 'react';
import {
  Col,
  Row,
  Typography,
  Input,
  Form,
  DatePicker,
  Button,
  Space,
} from 'antd';
import { Token } from '../../../../core/helpers/storage';
import FileInput from '../../../../core/components/FileInput';

const { Text } = Typography;
const { TextArea } = Input;

const ComponentC = ({ display, isReadOnly, title }) => {
  const token = Token();
  if (!token) {
    return null;
  }
  return (
    <Row style={{ display }} gutter={[16, 32]}>
      <Col span={24}>
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 0.2,
            color: '#000000',
            lineHeight: '20px',
          }}
        >
          Part {title}
        </Text>
        <Row gutter={[16, 32]}>
          <Col span={24}>
            <Text className="incident-label">Incident Causes </Text>
            <Text className="incident-label">
              (facts and causes associated with the incident)
            </Text>
            <Form.Item
              name="causes"
              // rules={[{ required: true, message: 'Causes is required' }]}
            >
              <TextArea className="textArea" rows={4} disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text className="incident-label">Corrective Actions </Text>
            <Text className="incident-label">(description of actions)</Text>
            <Form.Item
              name="correctiveAppliedDescription"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Description of actions is required',
              //   },
              // ]}
            >
              <TextArea className="textArea" rows={4} disabled={isReadOnly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={15}>
              <Col span={12}>
                <Text className="incident-label">Corrective action date</Text>
                <Form.Item
                  name="correctiveAppliedDay"
                  // rules={[
                  //   { required: true, message: 'Corrective date is required' },
                  // ]}
                >
                  <DatePicker
                    className="filter-label"
                    size="large"
                    style={{ width: '80%' }}
                    disabled={isReadOnly}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="incident-label">Person responsible</Text>
                <Form.Item
                  name="correctiveAppliedResponsible"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Person responsible is required',
                  //   },
                  // ]}
                >
                  <Input
                    className="filter-label"
                    disabled={isReadOnly}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col
                span={12}
                style={{
                  border: '1px solid',
                  borderColor: '#d9d9d9',
                  marginTop: 15,
                }}
              >
                <Space direction="vertical">
                  <Text className="incident-label">Upload File</Text>
                  <Form.Item
                    name="signatureFiles"
                    rules={[
                      {
                        required: true,
                        message: 'At least one file is required',
                      },
                    ]}
                  >
                    <FileInput disabled={isReadOnly}>
                      <Button
                        style={{
                          background: '#E8EAEC',
                          border: '1px solid rgba(0, 0, 0, 0.17)',
                          boxSizing: 'border-box',
                          borderRadius: 3,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            lineHeight: '20px',
                            letterSpacing: '0.2px',
                            color: '#636363',
                          }}
                        >
                          Choose File
                        </Text>
                      </Button>
                    </FileInput>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ComponentC;
