export enum HealthSafety {
  Injury = 'Injury',
  Illness = 'Illness',
  COVID = 'COVID',
  'Near Miss' = 'Near Miss',
  'Non-Work Related' = 'Non-Work Related',
}

export enum Operational {
  'Property Damage' = 'Property Damage',
  'Production Loss' = 'Production Loss',
  'Schedule Delays' = 'Schedule Delays',
}

export enum NatureOfInjury {
  'Abrasion' = 'Abrasion',
  'Amputation' = 'Amputation',
  'bite/sting' = 'bite/sting',
  'bruise_contusion' = 'bruise/contusion',
  'burns' = 'burns',
  'concussion' = 'concussion',
  'crush_injury' = 'crush injury',
  'cut_puncture' = 'cut/puncture',
  'dislocation' = 'dislocation',
  'foreign_body' = 'foreign body',
  'fracture' = 'fracture',
  'hernia' = 'hernia',
  'inhalation' = 'inhalation',
  'internal_injury' = 'internal injury',
  'sprain_strain' = 'sprain/strain',
  'suffocation' = 'sprain_strain',
}

export enum TypeOfContact {
  'biological' = 'biological',
  'caught on' = 'caught on',
  'chemical' = 'chemical',
  'electrocution' = 'electrocution',
  'explosion' = 'explosion',
  'fall-same-level' = 'fall-same-level',
  'fall from height' = 'fall from height',
  'irritant' = 'irritant',
  'repetitive motion' = 'repetitive motion',
  'strain sprain' = 'strain sprain',
  'struck against' = 'struck against',
  'struck by' = 'struck by',
  'temperature/weather' = 'temperature/weather',
  'trapped/pinched' = 'trapped/pinched',
  'vehicle' = 'vehicle',
}

export enum Illness {
  'dust-disease of lungs' = 'dust-disease of lungs',
  'occupational illness' = 'occupational illness',
  'hearing loss' = 'hearing loss',
  'chemical exposure' = 'chemical exposure',
  'skin disorder' = 'skin disorder',
  'respiratory condition' = 'respiratory condition',
}
