import { Button, message, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { formatTimeSpentSeconds } from '../../../../../core/helpers/utilities';
import {
  IActiveInvoice,
  IDataLine,
  IEmployeeOnOrder,
  IInvoiceLineItems,
  IRecord
} from '../../../../../core/interfaces/invoice.interface';
import { IQuickbooksCreateInvoice } from '../../../../../core/interfaces/quickbooksCreateInvoice.interface';
import { IQuickBooksInvoiceLineItems } from '../../../../../core/interfaces/quickBooksInvoiceLineItems.interface';
import {
  InvoiceLineItems,
  UpdateInvoiceLineItemQbReq
} from '../../../../../core/services/invoice/invoice.service';
import { createInvoice } from '../../../../../core/services/quickbooks/quickbooks.service';
import EditInvoiceModal from './EditModal';

export interface ITableProps {
  invoice: IActiveInvoice;
  disableEdit: boolean;

  onTotalLines: (total: number) => void;
  newInvoice: () => void;
}

const InvoicesTable = (props: ITableProps) => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editInfo, setEditInfo] = useState<any>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [loadingQbBtn, setLoadingQbBtn] = useState<boolean>(false);
  const [currentOrderLoading, setCurrentOrderLoading] = useState<string>('');
  const [totalInvoices, setTotalInvoices] = useState<number>();
  const [currentSkip, setCurrentSkip] = useState<number>(0);
  const [rowKeys, setRowKeys] = useState<string[]>([]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      className: 'row__class',
      render: (text, record) => {
        return <strong>{text}</strong>;
      }
    },
    {
      title: 'Hours',
      dataIndex: 'secondToHours',
      width: '10%',
      className: 'row__class',
      render: (text, record) => {
        return <strong>{text}</strong>;
      }
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      width: '10%',
      className: 'row__class',
      render: (text, record) => {
        return record.children ? '' : <strong>${text}</strong>;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      className: 'row__class',
      width: '20%',
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '10%',
      className: 'row__class',
      render: (text, record) => {
        return <strong>${text}</strong>;
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '15%',
      className: 'row__class',
      render: (text, record) => {
        return (
          <Space size="middle">
            {record.children ? (
              <>
                <Button
                  className="custom__button"
                  loading={
                    loadingQbBtn &&
                    !record.children[0].qbDate &&
                    currentOrderLoading === record.key
                  }
                  disabled={
                    record.children[0].qbDate ? true : false || !disableEdit
                  }
                  onClick={() => pushToQb(record)}
                >
                  Push to QB
                </Button>
              </>
            ) : (
              <Button
                className="custom__button"
                disabled={disableEdit}
                onClick={() => edit(record)}
              >
                Edit
              </Button>
            )}
          </Space>
        );
      }
    }
  ];

  useEffect(() => {
    if (props.invoice) {
      getInvoiceLineItem(props.invoice.id, 10, 0);
    }
  }, [props.invoice]);

  useEffect(() => {
    props.disableEdit ? setDisableEdit(true) : setDisableEdit(false);
  }, [props.disableEdit]);

  const edit = (record: IEmployeeOnOrder) => {
    if (record) {
      setEditInfo(record);
    }
  };

  const pushToQb = async (data: IRecord) => {
    if (!loadingQbBtn) {
      setLoadingQbBtn(true);
      setCurrentOrderLoading(data.key);
      const dataQB = transformDataQB(data);
      try {
        await createInvoice(dataQB);
        message.success(`Submitted successfully into Quick Books`);
        try {
          const allSent = await UpdateInvoiceLineItemQbReq(
            data.key,
            props.invoice.id
          );
          setLoadingQbBtn(false);
          if (allSent) {
            props.newInvoice();
          } else {
            getInvoiceLineItem(props.invoice.id, 10, currentSkip);
          }
        } catch (error) {
          setLoadingQbBtn(false);
          message.error(`Error updating invoice QB date`);
        }
      } catch (error) {
        setLoadingQbBtn(false);
        message.error(`Error Pushing to QB`);
      }
    }
  };

  const transformDataQB = (data: IRecord) => {
    let lines: IQuickBooksInvoiceLineItems[] = [];
    data.children.forEach((line) => {
      const lineBody = {
        rate: line.rate,
        Description: `${
          line.employee.user.profile.firstName +
          ' ' +
          line.employee.user.profile.lastName
        } ${line.type === 'REGULAR' ? 'Reg. Hrs.' : '0/T Hrs'} ( ${
          line.task
        } )`,
        ItemFullName:
          line.type === 'REGULAR' ? 'Regular Hours' : 'Overt Time Hrs',
        Quantity: parseFloat((line.timeSpentSeconds / 3600).toFixed(2))
      };
      lines.push(lineBody);
    });
    /*const order2 = data;
    let address;
    if (order2.address) {
      address = `${order2.address.address1} ${order2.address.address2}`;
    }
    const superintendentProfile = order2.superintendent.user.profile;
    const customerMessage = `${order2.customer.name}/Job #${order2.jobNumber}/${order2.jobSiteName}/${address}/${superintendentProfile.firstName} ${superintendentProfile.lastName}`;*/
    const body: IQuickbooksCreateInvoice = {
      CustomerFullName: data.simpleName,
      TxnDate: moment().toISOString(),
      ShipDate: moment(props.invoice.payPeriodEnd).toISOString(),
      CustomerMsg: data.customerMessage,
      Lines: lines
    };

    return body;
  };

  const transformDataTable = (data: IDataLine[]) => {
    let tableInvoices: any[] = [];
    let keys: string[] = [];
    data.forEach((order) => {
      let listItems: any[] = [];
      order.lineItems.forEach((line) => {
        listItems.push({
          ...line,
          key: line._id,
          name: `${line.employee.user.profile.firstName} ${line.employee.user.profile.lastName}`,
          // (${line.task}) (${
          //   line.type === 'REGULAR' ? 'Reg. Hrs.' : ' 0/T Hrs.'
          // })
          secondToHours: formatTimeSpentSeconds(line.timeSpentSeconds),
          qbDate: line.qbDate
        });
      });
      const order2 = order.order;
      let address = '-';
      if (order2.address) {
        address = `${order2.address.address1} ${order2.address.address2}`;
      }
      const superintendentProfile = order2.superintendent.user.profile;
      const customerMessage = `${order2.customer.name}/Job #${order2.jobNumber}/${order2.jobSiteName}/${address}/${superintendentProfile.firstName} ${superintendentProfile.lastName}`;
      keys.push(order2.id);
      tableInvoices.push({
        key: order2.id,
        simpleName: order2.customer.name,
        name: `${order2.customer.name}`, //( ${order2.nro} )
        customerMessage,
        children: listItems,
        secondToHours: formatTimeSpentSeconds(order.timeSpentSeconds),
        total: order.total.toString(),
        rate: order.avgRate
      });
    });
    setRowKeys(keys);
    setInvoices(tableInvoices);
    setLoading(false);
  };

  const getInvoiceLineItem = async (
    id: string,
    limit: number,
    skip: number
  ) => {
    setLoading(true);
    try {
      const data: IInvoiceLineItems = await InvoiceLineItems(id, limit, skip);

      if (data) {
        transformDataTable(data.data);
        setTotalInvoices(data.total);
        props.onTotalLines(data.total);
      }
    } catch (error) {
      setLoading(false);
      message.error('Error getInvoiceLineItem');
    }
  };

  const onOkModal = async () => {
    setEditInfo(null);
    if (props.invoice) {
      getInvoiceLineItem(props.invoice.id, 10, currentSkip);
    }
  };

  const onCancelModal = () => {
    setEditInfo(null);
  };

  const onHandleChangePage = (page: number) => {
    const skip = 10 * (page - 1);
    setCurrentSkip(skip);
    if (totalInvoices && skip < totalInvoices) {
      getInvoiceLineItem(props.invoice.id, 10, skip);
    }
  };

  return (
    <>
      <Table
        expandable={{
          expandIcon: () => <></>,
          expandedRowKeys: rowKeys,
          indentSize: 20
        }}
        rowClassName={(rec, idx) =>
          rec.children ? 'table__row' : 'table__children__row'
        }
        scroll={{ y: 480 }}
        bordered
        columns={columns}
        dataSource={invoices}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          total: totalInvoices,
          onChange: onHandleChangePage
        }}
      />
      {editInfo && (
        <EditInvoiceModal
          data={editInfo}
          onOk={onOkModal}
          onCancel={onCancelModal}
        />
      )}
    </>
  );
};

export default InvoicesTable;
