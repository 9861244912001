import { FUESApolloClient } from '../../helpers/api';
import { EStatus } from '../../interfaces/status.enum';
import { IUser } from '../../interfaces/user.interface';
import {
  ChangeUserStatusMutation,
  DeleteUserMutation,
  GetUsersQuery
} from './user.gql';

export const GetUsers = async (args, limit: number, skip: number) =>
  FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: GetUsersQuery,
      variables: {
        input: {
          paging: {
            first: limit,
            skip,
            orderBy: 'createdAt.desc'
          },
          args
        }
      }
    })
    .then((resp) => resp.data.User);

export const DeleteUser = async (id: string) =>
  FUESApolloClient()
    .mutate({
      mutation: DeleteUserMutation,
      variables: {
        input: {
          id
        }
      }
    })
    .then((resp) => resp.data.UserDelete);

export const ChangeUserStatus = async (
  id: string,
  status: EStatus
): Promise<IUser> =>
  FUESApolloClient()
    .mutate({
      mutation: ChangeUserStatusMutation,
      variables: {
        input: {
          id,
          status
        }
      }
    })
    .then((resp) => resp.data.ChangeUserStatus);
