import React, { useState, useEffect } from 'react';
import { Table, Col, Row, Typography, Button, Space, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAllIncidents } from '../../../../core/services/incident/incident.service';
import { IIncident } from '../../../../core/interfaces/incident.interface';
import {formatAddress, USA_DATE_FORMAT} from '../../../../core/helpers/utilities';

const { Text } = Typography;

const IncidentList = () => {
  const history = useHistory();
  const [incidents, setIncidents] = useState<IIncident[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<String>('');

  useEffect(() => {
    setFetchStatus('loading');
    getAllIncidents({}).then(
      (resp) => {
        setFetchStatus('success');
        setIncidents(resp.data);
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
  }, []);

  let content;
  if (valueFetchStatus === 'loading') {
    content = <span>Loading...</span>;
  } else if (valueFetchStatus === 'error') {
    content = <span>Error...</span>;
  } else {
    content = (
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <Table
            dataSource={incidents}
            columns={[
              {
                title: 'Incident Title',
                key: 'IncidentTitle',
                render: ({ title }) => {
                  return <Text className="row-text">{title}</Text>;
                },
              },
              {
                title: 'Incident Job Site',
                key: 'IncidentJobSite',
                render: ({ order }) => {
                  return (
                    <Text className="row-text">
                      {formatAddress(order.customer.address)}
                    </Text>
                  );
                },
              },
              {
                title: 'Incident Date',
                key: 'incidentDate',
                render: ({ createdAt }) => {
                  return (
                    <Text className="row-text">
                      {moment(createdAt).format(USA_DATE_FORMAT)}
                    </Text>
                  );
                },
              },
              {
                title: 'Employee',
                key: 'employee',
                render: ({ employee }) => {
                  return (
                    <Text className="row-text">
                      {employee.user.profile.firstName}{' '}
                      {employee.user.profile.lastName}
                    </Text>
                  );
                },
              },
              {
                title: '',
                key: 'action',
                render: () => {
                  return (
                    <RightOutlined
                      style={{
                        fontSize: 15,
                        color: '#465968',
                        cursor: 'pointer',
                      }}
                    />
                  );
                },
              },
            ]}
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomCenter'],
              defaultPageSize: 9,
            }}
            rowKey={({ id }) => id}
            onRow={({ id }) => ({
              onClick: () => {
                history.push(`/safety/incidents/${id}`);
              },
            })}
          />
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Row style={{ marginTop: 25 }} justify="space-between">
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#000000',
              marginBottom: 0,
            }}
          >
            Incident History
          </Text>
          <Link to="/safety/incidents/new-incident">
            <Button
              style={{
                backgroundColor: '#BAC2C8',
                borderRadius: 4,
                borderColor: '#919CA2',
                minHeight: 44,
              }}
              type="link"
            >
              <Text
                style={{
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                  fontSize: 15,
                  lineHeight: '20px',
                  letterSpacing: '0.2px',
                }}
              >
                Log New Incident
              </Text>
            </Button>
          </Link>
        </Row>
        {content}
      </Space>
    </div>
  );
};

export default IncidentList;
