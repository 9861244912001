import { FUESApolloClient } from '../../helpers/api';
import { ICostCode } from '../../interfaces/costCode.interface';
import { CostCodeCreate, CostCodeDelete, CostCodesListQuery, CostCodeUpdate } from './costCode.gql';

export const getAllCostcodes = (): Promise<ICostCode[]> => {
  return FUESApolloClient()
    .query<{ getCostCodes: ICostCode[] }>({
      fetchPolicy: 'no-cache',
      query: CostCodesListQuery,
      variables: {}
    })
    .then(
      (resp) =>
        resp.data.getCostCodes
    );
};

export const createCostCode = (input: { code: string }): Promise<ICostCode | undefined> => {
  return FUESApolloClient()
    .mutate<{ createCostCode: ICostCode }>({
      fetchPolicy: 'no-cache',
      mutation: CostCodeCreate,
      variables: {
        input: {
          ...input,
          description: input.code,
          type: 'GENERAL'
        }
      }
    })
    .then(res => {
      if (res.data && res.data.createCostCode) {
        return res.data.createCostCode;
      }
    });
};

export const updateCostCode = (input: ICostCode): Promise<ICostCode | undefined> => {
  return FUESApolloClient()
    .mutate<{ updateCostCode: ICostCode }>({
      fetchPolicy: 'no-cache',
      mutation: CostCodeUpdate,
      variables: {
        input
      }
    })
    .then(res => {
      if (res.data && res.data.updateCostCode) {
        return res.data.updateCostCode;
      }
    });
};

export const deleteCostCode = (id: string): Promise<ICostCode | undefined> => {
  return FUESApolloClient()
    .mutate<{ deleteCostCode: ICostCode }>({
      fetchPolicy: 'no-cache',
      mutation: CostCodeDelete,
      variables: {
        input: {
          id
        }
      }
    })
    .then(res => {
      if (res.data && res.data.deleteCostCode) {
        return res.data.deleteCostCode;
      }
    });
};
