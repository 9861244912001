import gql from 'graphql-tag';
import {
  LOGIN,
  LOGOUT,
  REQUEST_PASSWORD_CHANGE,
  CHANGE_PASSWORD,
  EXCHANGE_REFRESH_TOKEN,
} from './auth.gql';
import { FUESApolloClient } from '../../helpers/api';
import { IToken } from '../../interfaces/auth.interface';
import { Clear, Token } from '../../helpers/storage';

function errorHandler(err) {
  if (err.graphQLErrors) {
    // eslint-disable-next-line no-param-reassign
    err.message = err.graphQLErrors.map((x) => x.details).join('. ');
  }
  throw err;
}

export const LogIn = (payload): Promise<IToken> =>
  FUESApolloClient(true)
    .mutate({
      mutation: LOGIN,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.LogIn, errorHandler);

export const LogOut = async () => {
  const token = Token();
  if (!token) {
    return;
  }
  await FUESApolloClient(true)
    .mutate({
      mutation: LOGOUT,
      variables: {
        input: {
          jwtRefresh: token.jwtRefresh || null,
          user: token.user?.id || null,
        },
      },
    })
    .then((resp) => {
      Clear('*');
      return resp.data.LogOut;
    });
};

export const RequestPasswordChange = (username) => {
  return FUESApolloClient()
    .mutate({
      mutation: REQUEST_PASSWORD_CHANGE,
      variables: {
        input: {
          username,
        },
      },
    })
    .then((resp) => resp.data.RequestPasswordChange, errorHandler);
};

export const ChangePassword = (payload) => {
  return FUESApolloClient()
    .mutate({
      mutation: CHANGE_PASSWORD,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.ChangePassword, errorHandler);
};

export const ExchangeRefreshToken = (jwtRefresh) => {
  return FUESApolloClient(true).mutate({
    mutation: EXCHANGE_REFRESH_TOKEN,
    variables: {
      input: { jwtRefresh },
    },
  });
};

export const AuthPing = () =>
  FUESApolloClient()
    .query<{ LoggedInUser: any }>({
      fetchPolicy: 'no-cache',
      query: gql`
        query UserInfo {
          ...UserInfo
        }

        fragment UserInfo on Query {
          LoggedInUser {
            id
          }
        }
      `,
      variables: {},
    })
    .then((resp) => resp.data.LoggedInUser);
