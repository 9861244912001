import React from 'react';
import { Typography } from 'antd';
import SkillIcon from './SkillIcon';

const { Text } = Typography;

const SkillIconText = ({ name }) => (
  <div
    title={name}
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      lineHeight: '12px',
      width: 25,
    }}
  >
    <SkillIcon skillName={name} />
    <Text
      className="skill-text"
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
      }}
    >
      {name}
    </Text>
  </div>
);

export default SkillIconText;
