import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import {
  EmployeeListQuery,
  AvailabiltyListQuery,
  CreateEmployeeMutation,
  UpdateEmployeeMutation,
  ReInviteEmployeeMutation,
  EmployeeSendSmsMutation,
} from './employee.gql';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import {
  IEmployee,
  IEmployeeResponse,
  IAvailabilityResponse,
} from '../../interfaces/employee.interface';

export const getAllEmployees = (args = {}) => {
  return new Promise<IPaginationWrapper<IEmployee>>((resolve, reject) => {
    FUESApolloClient()
      .query({
        fetchPolicy: 'no-cache',
        query: EmployeeListQuery,
        variables: {
          employeeInput: {
            paging: {
              first: -1,
              skip: 0,
              orderBy: 'createdAt.desc',
            },
            args,
          },
          skillsInput: {
            paging: {
              first: -1,
              skip: 0,
              orderBy: 'createdAt.desc',
            },
            args: {},
          },
        },
      })
      .then((resp: ApolloQueryResult<IEmployeeResponse>) => {
        resolve(resp.data?.Employee);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllEmployeesAvailables = (args = {}) => {
  return FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: AvailabiltyListQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc',
          },
          args,
        },
        skillsInput: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc',
          },
          args: {},
        },
      },
    })
    .then((resp: ApolloQueryResult<IAvailabilityResponse>) => ({
      data: resp.data.Availability.data.map(
        (availability) => availability.employee
      ),
    }));
};

export const inviteEmployee = (payload) => {
  return FUESApolloClient()
    .mutate({
      mutation: CreateEmployeeMutation,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.EmployeeCreate);
};

export const reInviteEmployee = (id) => {
  return FUESApolloClient()
    .mutate({
      mutation: ReInviteEmployeeMutation,
      variables: {
        input: { id },
      },
    })
    .then((resp) => resp.data.EmployeeReInvite);
};

export const updateEmployee = (id, payload) => {
  return FUESApolloClient()
    .mutate({
      mutation: UpdateEmployeeMutation,
      variables: {
        input: {
          ...payload,
          id,
        },
        skillsInput: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'createdAt.desc',
          },
          args: {},
        },
      },
    })
    .then((resp) => resp.data.EmployeeUpdate);
};

export const getEmployee = async (id) => {
  const employees = await getAllEmployees({ id });
  return employees.data[0];
};

export const sendSmsMutationEmployee = async (payload) =>
  // {
  //   await sleep(2000);
  //   console.log('payload', payload);
  // };
  FUESApolloClient()
    .mutate({
      mutation: EmployeeSendSmsMutation,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.EmployeeSendSms);
