import gql from 'graphql-tag';
import { entityFieldsFragment } from './entity.fragment';

export const userFieldsFragment = gql`
  fragment userFields on User {
    ...entityFields
    username
    active
    disabled
    status
    phoneVerified
    emailVerified
    profile {
      firstName
      lastName
      email
      phone
      preferPhoneVerification
    }
  }

  ${entityFieldsFragment}
`;

export const createUserFieldsFragment = (type: string) => gql`
    fragment createUserFields on ${type} {
        createUser {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;
