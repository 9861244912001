import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import {
  OrderListQuery,
  CreateOrderMutation,
  UpdateOrderMutation,
} from './order.gql';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import { IOrder, IOrderResponse } from '../../interfaces/order.interface';

export const getAllOrders = (args, filterDateSchedules?) => {
  return new Promise<IPaginationWrapper<IOrder>>((resolve, reject) => {
    FUESApolloClient()
      .query({
        fetchPolicy: 'no-cache',
        query: OrderListQuery,
        variables: {
          orderInput: {
            paging: {
              first: -1,
              skip: 0,
              orderBy: 'createdAt.desc',
            },
            args,
          },
          scheduleInput: filterDateSchedules
            ? {
                paging: {
                  first: -1,
                  skip: 0,
                  orderBy: 'createdAt.desc',
                },
                args: {
                  date: filterDateSchedules,
                },
              }
            : {
                paging: {
                  first: -1,
                  skip: 0,
                  orderBy: 'createdAt.desc',
                },
                args: {},
              },
        },
      })
      .then((resp: ApolloQueryResult<IOrderResponse>) => {
        resolve(resp.data?.Order);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOrder = (payload) => {
  return new Promise((resolve, reject) => {
    FUESApolloClient()
      .mutate({
        mutation: CreateOrderMutation,
        variables: {
          input: payload,
          scheduleInput: {
            paging: {
              first: -1,
              skip: 0,
              orderBy: 'createdAt.desc',
            },
            args: {},
          },
        },
      })
      .then(
        (resp) => {
          resolve(resp.data.OrderCreate);
        },
        (err) => {
          reject(err);
        }
      );
  });
};

export const updateOrder = (id, payload) => {
  return new Promise((resolve, reject) => {
    FUESApolloClient()
      .mutate({
        mutation: UpdateOrderMutation,
        variables: {
          input: { ...payload, id },
          scheduleInput: {
            paging: {
              first: -1,
              skip: 0,
              orderBy: 'createdAt.desc',
            },
            args: {},
          },
        },
      })
      .then((resp) => {
        resolve(resp.data.OrderUpdate);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOrder = async (id) => {
  const allOrders = await getAllOrders({ id });
  return allOrders.data[0];
};
