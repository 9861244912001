import React from 'react';
import forklift from "../images/certificationIcons/forklift.svg";

const NAME_2_ICON_MAP = new Map<string, any>([
  ['Rough Terrain Forklift', forklift],
  ['Boom Lift', forklift],
  ['Scissor Lift', forklift],
  ['Warehouse Forklift', forklift],
]);
export const nameToIcon = (nname) => {
  if (NAME_2_ICON_MAP.has(nname)) {
    return NAME_2_ICON_MAP.get(nname);
  }
  return forklift;
};

const ICON_SIZE = 20;

const CertificationIcon = ({ name, ...props }) => (
  <img alt={name} src={nameToIcon(name)} {...props} style={{width: ICON_SIZE, height: ICON_SIZE}}/>
);

export default CertificationIcon;
