import gql from 'graphql-tag';
import { pagingFieldsFragment } from './pagination.fragment';
import { createUserFieldsFragment } from './user.fragment';
import { workerGroupListQueryFragment } from './skill.fragment';
import { scheduleListQueryFragment } from './schedule.fragment';
import { entityFieldsFragment } from './entity.fragment';
import { superintendentFieldsFragment } from './superintendent.fragment';
import {
  addressFieldsFragment,
  addressFieldFragment
} from './address.fragment';
import { customerFieldsFragment } from './customer.fragment';

export const superintendentInOrderFieldsFragment = gql`
  fragment superintendentInOrderFields on Superintendent {
    ...superintendentFields
    customer {
      ...customerFields
    }
  }
  ${superintendentFieldsFragment}
  ${customerFieldsFragment}
`;

export const plainOrderFieldsFragment = gql`
  fragment plainOrderFields on Order {
    nro
    status
    startsAt
    endsAt
    time
    customer {
      ...customerFields
    }
    notes
    address {
      ...addressFieldFragment
    }
    ...entityFields
  }
  ${addressFieldFragment}
  ${customerFieldsFragment}
  ${entityFieldsFragment}
`;

export const orderFieldsFragment = gql`
  fragment orderFields on Order {
    id
    nro
    status
    startsAt
    endsAt
    time
    superintendent {
      ...superintendentInOrderFields
    }
    supervisors
    customer {
      ...customerFields
    }
    jobNumber
    jobSiteName
    notes
    ...addressFields
    ...createUserFields
    ...workerGroupsListQuery
    ...scheduleListQuery
    ...entityFields
  }

  ${scheduleListQueryFragment('Order')}

  ${addressFieldsFragment('Order')}

  ${createUserFieldsFragment('Order')}

  ${workerGroupListQueryFragment('Order')}

  ${customerFieldsFragment}

  ${superintendentInOrderFieldsFragment}

  ${entityFieldsFragment}
`;

export const OrderListQueryFragment = gql`
  fragment OrderListQuery on Query {
    Order(input: $orderInput) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...orderFields
      }
    }
  }

  ${pagingFieldsFragment}
  ${orderFieldsFragment}
`;
