import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Space, Image, Radio } from 'antd';
import { getCheckin } from '../../../../core/services/checkin/checkin.service';
import { ICheckin } from '../../../../core/interfaces/checkin.interface';
import { USA_TIME_FORMAT } from '../../../../core/helpers/utilities';

const { Text } = Typography;

const CheckinDetail = ({ checkinId }) => {
  const [valueCurrentCheckin, setCurrentCheckin] = useState<ICheckin | null>(
    null
  );
  const [valueError, setError] = useState<string>('');
  const [valueLoading, setLoading] = useState<Boolean>(false);
  useEffect(() => {
    setLoading(true);
    getCheckin(checkinId)
      .then(
        (checkin) => {
          if (checkin) {
            setCurrentCheckin(checkin);
          } else {
            setError('Not found');
          }
        },
        (err) => {
          setError(err.message);
        }
      )
      .then(() => setLoading(false));
  }, [checkinId]);

  if (valueError) {
    return <span>{valueError}</span>;
  }
  if (valueLoading) {
    return <span>Loading...</span>;
  }
  if (!valueCurrentCheckin) {
    return null;
  }
  return (
    <Row gutter={[24, 32]}>
      <Col span={18}>
        <Card
          style={{
            border: '1px solid #D7DEE2',
            boxSizing: 'border-box',
            borderRadius: 2,
            backgroundColor: '#FFFFFF',
            marginBottom: '1rem',
          }}
        >
          <Row justify="start" gutter={[12, 32]}>
            <Col offset={1}>
              <Text
                style={{
                  fontSize: 18,
                  color: '#000000',
                  fontWeight: 'bold',
                  lineHeight: '20px',
                }}
              >
                {valueCurrentCheckin?.schedule.order.customer.name}
              </Text>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col offset={2}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Text
                        style={{
                          fontWeight: 'bold',
                          fontSize: 12,
                          color: 'rgba(0, 0, 0, 0.5)',
                          lineHeight: '20px',
                        }}
                      >
                        created by
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text
                        style={{
                          fontWeight: 600,
                          fontSize: 14,
                          lineHeight: '20px',
                          letterSpacing: 0.2,
                          color: '#000000',
                        }}
                      >
                        {valueCurrentCheckin?.createdBy.profile.firstName}{' '}
                        {valueCurrentCheckin?.createdBy.profile.lastName}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col offset={2}>
              <Row>
                <Col>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                      color: 'rgba(0, 0, 0, 0.5)',
                      lineHeight: '20px',
                    }}
                  >
                    received
                  </Text>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      lineHeight: '20px',
                      letterSpacing: 0.2,
                      color: '#000000',
                    }}
                  >
                    {valueCurrentCheckin?.createdAt.format(USA_TIME_FORMAT)}{' '}
                  </Text>{' '}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col offset={2}>
              <Row gutter={[16, 8]}>
                <Col>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                      color: 'rgba(0, 0, 0, 0.5)',
                      lineHeight: '20px',
                    }}
                  >
                    safety hazards?
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Radio.Group
                    disabled
                    value={valueCurrentCheckin?.has_hazards}
                  >
                    <Radio value>
                      <Text
                        style={{
                          fontWeight: 600,
                          fontSize: 15,
                          lineHeight: '20px',
                          letterSpacing: 0.2,
                          color: '#000000',
                        }}
                      >
                        Yes
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text
                        style={{
                          fontWeight: 600,
                          fontSize: 15,
                          lineHeight: '20px',
                          letterSpacing: 0.2,
                          color: '#000000',
                        }}
                      >
                        No
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col offset={2}>
              <Row gutter={[16, 8]}>
                <Col>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                      color: 'rgba(0, 0, 0, 0.5)',
                      lineHeight: '20px',
                    }}
                  >
                    notes
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 15,
                      lineHeight: '20px',
                      letterSpacing: 0.2,
                      color: '#000000',
                    }}
                  >
                    {valueCurrentCheckin?.description}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>

          {valueCurrentCheckin?.has_hazards && (
            <Row gutter={[32, 32]}>
              <Col offset={2}>
                <Row gutter={[16, 8]}>
                  <Col>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineHeight: '20px',
                      }}
                    >
                      Hazard description
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 15,
                        lineHeight: '20px',
                        letterSpacing: 0.2,
                        color: '#000000',
                      }}
                    >
                      {valueCurrentCheckin?.description_hazards}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row gutter={[32, 32]}>
            <Col offset={2}>
              {valueCurrentCheckin?.attachments &&
                valueCurrentCheckin?.attachments.length > 0 && (
                  <Row gutter={[12, 32]}>
                    <Col>
                      <Space direction="vertical">
                        <Text
                          style={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineHeight: '20px',
                          }}
                        >
                          files
                        </Text>
                        {valueCurrentCheckin.attachments.map((media) => {
                          return (
                            <React.Fragment key={media.id}>
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  color: 'rgba(0, 0, 0, 0.5)',
                                  lineHeight: '20px',
                                }}
                              >
                                {media.url}
                              </Text>
                              <a href={media.url} download="file">
                                download
                              </a>
                            </React.Fragment>
                          );
                        })}
                      </Space>
                    </Col>
                  </Row>
                )}
              <Row gutter={[32, 32]}>
                <Col>
                  <Space direction="vertical">
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineHeight: '20px',
                      }}
                    >
                      images
                    </Text>
                    {valueCurrentCheckin?.photo_hazards.map((media, index) => {
                      return (
                        <Col key={media.id} span={12}>
                          <Image alt={`image ${index}`} src={media.url} />
                        </Col>
                      );
                    })}
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CheckinDetail;
