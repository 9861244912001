import React from 'react';
import { Row, Typography, Space, Form, notification } from 'antd';
import lodash from 'lodash';
import ScheduleForm from './ScheduleForm';

const { Text } = Typography;

const OrderScheduleList = ({
                             fieldss,
                             availableEmployees,
                             formInstance,
                             employeesSelected,
                             order,
                             availableSkills
                           }) => {

  const hasEmployeeCorrectSkills = (emp) => {
    const res = emp.Skills.data.filter(({ id }) =>
      order.workerGroups.find(({ skill }) => skill.id === id)
    );
    return res.length;
  };

  const checkSkillQuantityNeeded = (skillSelectedId) => {
    const aux = order.workerGroups.map(({ skill, quantity }) => {
      const taken = order.Schedules
        ? lodash.concat(
          [],
          ...order.Schedules.data.map(({ Assignations }) =>
            Assignations.data.filter(
              ({ skill: lSkill }) => lSkill?.id === skill.id
            )
          )
        ).length
        : 0;
      return {
        skillId: skill.id,
        quantityNeeded: quantity,
        filled: taken
      };
    });
    const res = aux.find(({ skillId }) => {
      return skillId === skillSelectedId;
    });
    return res && res.filled > res.quantityNeeded;
  };

  return (
    <>
      <div>
        <Text className="new-form-label">assigned</Text>
      </div>
      <Form.List name="employees">
        {(fields, { remove, add }) => {
          return (
            <Space direction="vertical" style={{ width: '100%' }}>
              <div
                style={{
                  border: 'none'
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(ev) => {
                  ev.preventDefault();
                  const data = ev.dataTransfer.getData('text');
                  const employeeDropped = availableEmployees.find(
                    (emp) => emp.id === data
                  );

                  /*if (!hasEmployeeCorrectSkills(employeeDropped)) {
                    notification.open({
                      message: 'The employee does not have the correct skills',
                      type: 'error'
                    });
                    return;
                  }*/

                  if (
                    employeeDropped.foreman &&
                    !formInstance.getFieldValue('foreman')
                  ) {
                    formInstance.setFieldsValue({
                      foreman: employeeDropped.id
                    });
                  }
                  if (!employeeDropped) throw new Error('There was an error.');

                  add({
                    id: employeeDropped.id,
                    payRate: '0',
                    billRate: '0',
                    wcf: '0',
                    skill: null
                  });
                }}
                id={order.id}
              >
                <Text className="schedule-label">
                  drag & drop here to assign
                </Text>
              </div>
              <Row
                style={{
                  float: employeesSelected.length % 2 === 0 ? 'left' : 'none'
                }}
              >
                {fields.map((field, indexx) => {
                  const emp = fieldss.employees[indexx];
                  let employee = availableEmployees.find(
                    ({ id }) => id === emp.id
                  );
                  if (!employee) {
                    employee = fieldss.employees[indexx];
                  }
                  return (
                    <ScheduleForm
                      {...field}
                      quantityEmployees={employeesSelected.length}
                      checkSkillQuantityNeeded={checkSkillQuantityNeeded}
                      employee={employee}
                      workerGroups={order.workerGroups}
                      availableSkills={availableSkills}
                      onRemove={(employeeIn) => {
                        remove(field.name);
                        const actualSelectedId = formInstance.getFieldValue(
                          'foreman'
                        );
                        if (employeeIn.id === actualSelectedId) {
                          const foreman = employeesSelected.filter(
                            (empSel) =>
                              empSel.foreman && empSel.id !== actualSelectedId
                          );
                          formInstance.setFieldsValue({
                            foreman: foreman[0]?.id || null
                          });
                        }
                      }}
                      key={field.key}
                    />
                  );
                })}
              </Row>
            </Space>
          );
        }}
      </Form.List>
    </>
  );
};

export default OrderScheduleList;
