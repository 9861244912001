import {
  DollarCircleOutlined,
  FieldTimeOutlined,
  LeftOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Space,
  Typography
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IJob } from '../../../../core/interfaces/job.interface';
import {
  createJob,
  deleteJob,
  getJobById,
  updateJob
} from '../../../../core/services/job/job.service';
import '../index.scss';
import Applicants from './Applicants';

const options = [
  { label: 'Hourly', value: 'HOURLY' },
  { label: 'Annual', value: 'ANNUAL' }
];

const optionsType = [
  { label: 'Full Time', value: 'FULL_TIME' },
  { label: 'Part Time', value: 'PART_TIME' }
];

const { Text } = Typography;

const initialValues = {
  title: '',
  incomeAmount: null,
  description: '',
  qualifications: ''
};

const NewJobForm = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const [incomeType, setIncomeType] = useState('HOURLY');
  const [workingDay, setWorkingDay] = useState('FULL_TIME');
  const [shift, setShift] = useState('DAY');
  const [currentJob, setCurrentJob] = useState<IJob>();

  const createNewJob = async (values: IJob) => {
    if (values) {
      try {
        await createJob(values);
        message.success('Job created successfully.');
        history.push('/jobs');
      } catch (error) {
        message.error('Error creating new job.');
      }
    }
  };

  const updateCurrentJob = async (values: IJob) => {
    if (values && id) {
      try {
        await updateJob({ ...values, id });
        message.success('Job updated successfully.');
        history.push('/jobs');
      } catch (error) {
        message.error('Error updating current job.');
      }
    }
  };

  const getJob = async (id: string) => {
    if (id) {
      try {
        const response = await getJobById(id);
        if (response) {
          setCurrentJob(response);
          setValues(response);
        }
      } catch (error) {
        message.error('Error fetching current job.');
      }
    }
  };

  const deleteCurrentJob = async () => {
    if (id) {
      if (window.confirm('Are you sure you want to delete this job? ')) {
        try {
          const response = await deleteJob(id);
          if (response) {
            history.push('/jobs');
          }
        } catch (error) {
          message.error('Error deleting current job.');
        }
      }
    }
  };

  const setValues = (job: IJob) => {
    form.setFieldsValue({
      title: job.title,
      incomeAmount: job.incomeAmount,
      description: job.description,
      qualifications: job.qualifications
    });
    setIncomeType(job.incomeType);
    setWorkingDay(job.workingDay);
    setShift(job.shift);
  };

  const onSubmit = async (payload: IJob) => {
    const body = {
      ...payload,
      workingDay,
      shift,
      incomeType,
      incomeAmount: Number(payload.incomeAmount)
    };

    if (id) {
      await updateCurrentJob(body);
    } else {
      await createNewJob(body);
    }
  };

  useEffect(() => {
    if (id) {
      getJob(id);
    }
  }, [id]);

  return (
    <>
      <Col span={24} style={{ marginBottom: 10 }}>
        <Row style={titleContainer}>
          <Text style={title}>New Job</Text>
        </Row>
        <Row style={titleContainer}>
          <Link to="/jobs">
            <Button style={button} type="text">
              <LeftOutlined />
              <Text>Jobs</Text>
            </Button>
          </Link>
        </Row>
      </Col>
      <Row gutter={40}>
        <Col span={12}>
          <Form
            form={form}
            name="employee-form"
            onFinish={onSubmit}
            initialValues={initialValues}
          >
            <div>
              <Text className="form-label">Job Details</Text>
              <Form.Item name="title" rules={[{ required: true }]}>
                <Input placeholder="Job Title" size="middle" />
              </Form.Item>
            </div>

            {/* Amount */}

            <Row>
              <DollarCircleOutlined
                style={{ fontSize: 20, marginTop: 5, marginRight: 10 }}
              />
              <Form.Item
                style={{ marginRight: 10 }}
                name="incomeAmount"
                rules={[{ required: true }]}
              >
                <Input placeholder="$" size="middle" type="number" />
              </Form.Item>
              <Radio.Group
                options={options}
                onChange={(e) => setIncomeType(e.target.value)}
                value={incomeType}
                optionType="button"
                buttonStyle="solid"
              />
            </Row>

            {/* Working day */}

            <Row>
              <ScheduleOutlined
                style={{ fontSize: 20, marginTop: 5, marginRight: 10 }}
              />
              <Radio.Group
                options={optionsType}
                onChange={(e) => setWorkingDay(e.target.value)}
                value={workingDay}
                optionType="button"
                buttonStyle="solid"
              />
            </Row>

            {/* Shift */}

            <Row style={{ marginTop: 20 }}>
              <FieldTimeOutlined
                style={{ fontSize: 20, marginTop: 5, marginRight: 10 }}
              />

              <Radio.Group
                onChange={(e) => setShift(e.target.value)}
                value={shift}
              >
                <Space direction="vertical">
                  <Radio value={'DAY'}>Day Shift</Radio>
                  <Radio value={'SWING'}>Swing Shift</Radio>
                  <Radio value={'NIGHT'}>Night Shift</Radio>
                  <Radio value={'SPLIT'}>Split Shift</Radio>
                </Space>
              </Radio.Group>
            </Row>

            {/* Job description */}

            <div style={{ marginTop: 20 }}>
              <Text className="form-label">Job Description</Text>
              <Form.Item name="description" rules={[{ required: true }]}>
                <TextArea rows={4} placeholder="Type here..." />
              </Form.Item>
            </div>

            {/* Qualifications */}

            <div style={{ marginTop: 20 }}>
              <Text className="form-label">Required Minimums</Text>
              <Form.Item name="qualifications" rules={[{ required: true }]}>
                <TextArea rows={4} placeholder="Type here..." />
              </Form.Item>
            </div>

            <Form.Item>
              {id ? (
                <Button
                  style={{ marginTop: 20, marginRight: 15 }}
                  type="ghost"
                  htmlType="button"
                  onClick={() => deleteCurrentJob()}
                >
                  DELETE
                </Button>
              ) : (
                <Button
                  style={{ marginTop: 20, marginRight: 15 }}
                  type="ghost"
                  htmlType="button"
                  onClick={() => history.push('/jobs')}
                >
                  CANCEL
                </Button>
              )}

              <Button
                style={{ marginTop: 20 }}
                type="primary"
                htmlType="submit"
              >
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {id && (
          <Col span={12}>
            <Applicants applicants={currentJob?.applicants} />
          </Col>
        )}
      </Row>
    </>
  );
};

const titleContainer: React.CSSProperties = {
  alignItems: 'center',
  justifyContent: 'space-between'
};

const title: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: 18,
  lineHeight: '20px',
  letterSpacing: '0.2px',
  color: '#000000'
};

const button: React.CSSProperties = {
  padding: 0
};

export default NewJobForm;
