import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import OrderForm from './OrderForm';
import { getOrder } from '../../../core/services/order/order.service';
import { IOrder } from '../../../core/interfaces/order.interface';

const { Text } = Typography;

const OrderDetail = ({ orderId }) => {
  const [order, setOrder] = useState<IOrder>();

  const getOrderDetails = (orderIdIn) => {
    getOrder(orderIdIn).then((resp) => {
      setOrder(resp);
    });
  };

  useEffect(() => {
    getOrderDetails(orderId);
  }, [orderId]);
  return (
    <Row gutter={[24, 32]}>
      <Col span={4}>
        <Row align="middle">
          <Link
            to="/orders"
            style={{
              flex: 1,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <LeftOutlined style={{ fontSize: 15, color: 'black' }} />
            </div>
            <div
              style={{
                marginLeft: 10,
                alignItems: 'center',
                flexDirection: 'column',
                flex: 1,
                display: 'flex',
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 0.2,
                  color: '#000000',
                  lineHeight: '20px',
                  textAlign: 'center',
                }}
              >
                {order?.customer.name}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color: 'rgba(0, 0, 0, 0.75)',
                  lineHeight: '20px',
                }}
              >
                {order?.nro}
              </Text>
            </div>
          </Link>
        </Row>
      </Col>
      <Col offset={1} span={19}>
        {order ? (
          <OrderForm order={order} isOrderDetail />
        ) : (
          <span>Loading...</span>
        )}
      </Col>
    </Row>
  );
};

export default OrderDetail;
