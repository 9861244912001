import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Typography } from 'antd';
import {
  ContactsOutlined,
  FieldTimeOutlined,
  OrderedListOutlined,
  SafetyOutlined,
  WarningOutlined,
  ScheduleOutlined,
  CheckOutlined,
  MessageOutlined,
  IdcardOutlined,
  AuditOutlined
} from '@ant-design/icons';
import logo from '../../images/FillerupText.svg';
import icon from '../../images/FillerupIcon.svg';

import './index.scss';

const { Text } = Typography;
const { SubMenu } = Menu;

declare type MenuSpec = {
  label: string;
  link: string;
  path: RegExp;
  icon: any;
  submenu?: MenuSpec[];
  key?: number;
};

const menuItems = [
  {
    label: 'Employees',
    link: '/employees',
    path: /^\/employees/,
    icon: <IdcardOutlined />
  },
  {
    label: 'Scheduling',
    link: '/scheduling',
    path: /^\/scheduling/,
    icon: <ScheduleOutlined />
  },
  {
    label: 'Orders',
    link: '/orders',
    path: /^\/orders/,
    icon: <OrderedListOutlined />
  },
  {
    label: 'Time Tracking',
    link: '/time-tracking',
    path: /^\/time-tracking/,
    icon: <FieldTimeOutlined />
  },
  {
    label: 'Finance',
    link: '',
    path: /^\/finance/,
    icon: <ContactsOutlined />,
    submenu: [
      {
        label: 'Invoices',
        link: '/finance/invoices',
        path: /^\/finance\/invoices/,
        icon: <ContactsOutlined />
      },
      {
        label: 'Payments',
        link: '/finance/payments',
        path: /^\/finance\/payments/,
        icon: <ContactsOutlined />
      }
    ]
  },
  {
    label: 'Customers',
    link: '/customers',
    path: /^\/customers/,
    icon: <ContactsOutlined />
  },
  {
    label: 'Safety',
    link: '',
    path: /^\/safety/,
    icon: <SafetyOutlined />,
    submenu: [
      {
        label: 'Check-ins',
        link: '/safety/checkins/date',
        path: /^\/safety\/checkins/,
        icon: <CheckOutlined />
      },
      {
        label: 'Incidents',
        link: '/safety/incidents',
        path: /^\/safety\/incidents/,
        icon: <WarningOutlined />
      }
    ]
  },
  {
    label: 'Messaging',
    link: '/messaging',
    path: /^\/messaging/,
    icon: <MessageOutlined />
  },
  {
    label: 'Lists',
    link: '/lists/crud',
    path: /^\/lists\/crud/,
    icon: <MessageOutlined />
  },
  {
    label: 'Jobs',
    link: '/jobs',
    path: /^\/lists/,
    icon: <AuditOutlined />
  }
] as MenuSpec[];

const SideNavigation = ({ menuCollapsed }) => {
  const location = useLocation();
  const menuItemsWKey = [] as MenuSpec[];
  let counter = 0;
  menuItems.forEach((mm) => {
    const nItem = { ...mm, key: counter };
    counter += 1;
    if (mm.submenu) {
      nItem.submenu = [];
      mm.submenu.forEach((mmm) => {
        if (nItem.submenu) {
          nItem.submenu.push({ ...mmm, key: counter });
          counter += 1;
        }
      });
    }
    menuItemsWKey.push(nItem);
  });
  const flattenItems = ([] as MenuSpec[]).concat(
    ...(menuItemsWKey.map((mm) =>
      mm.submenu ? mm.submenu : [mm]
    ) as MenuSpec[][])
  );
  const selected = flattenItems.find((mm) => {
    return mm.path.test(location.pathname);
  });
  const selectedKey = selected?.key;
  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[`${selectedKey}`]}
      style={{ color: '#FFFFFF', backgroundColor: '#BAC3C8', marginTop: 10 }}
    >
      <Menu.Item style={{ marginBottom: 30 }}>
        <div className="logo" style={{ maxHeight: 53 }}>
          <Link to="/" style={{ color: 'white' }}>
            {menuCollapsed ? (
              <img src={icon} alt="Main Icon" style={{ maxHeight: 53 }} />
            ) : (
              <img
                src={logo}
                alt="Main Logo"
                style={{ maxHeight: 53, maxWidth: 150 }}
              />
            )}
          </Link>
        </div>
      </Menu.Item>
      {menuItemsWKey.map((item) => {
        if (!item.submenu) {
          return (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              style={{ marginTop: 10 }}
            >
              <Link to={item.link}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    color: '#FFFFFF'
                  }}
                >
                  {item.label}
                </Text>
              </Link>
            </Menu.Item>
          );
        }
        return (
          <SubMenu
            key={item.key}
            icon={item.icon}
            title={item.label}
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              lineHeight: '20px',
              letterSpacing: '0.2px',
              backgroundColor: '#BAC3C8'
            }}
          >
            {item.submenu.map((sub) => {
              return (
                <Menu.Item key={sub.key} icon={sub.icon}>
                  <Link to={sub.link}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'normal',
                        fontSize: 16,
                        lineHeight: '20px',
                        letterSpacing: '0.2px',
                        color: '#FFFFFF'
                      }}
                    >
                      {sub.label}
                    </Text>
                  </Link>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
};

export default SideNavigation;
