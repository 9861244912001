import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Tag,
  Typography,
  Space,
  Button,
  DatePicker,
  notification,
  message
} from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { concat } from 'lodash';

import moment from 'moment';
import { getAllOrders } from '../../../core/services/order/order.service';
import { getAllEmployeesAvailables } from '../../../core/services/employee/employee.service';
import { getAllSkills } from '../../../core/services/skill/skill.service';
import { IOrder } from '../../../core/interfaces/order.interface';
import { IEmployee } from '../../../core/interfaces/employee.interface';
import { ISkill } from '../../../core/interfaces/skill.interface';
import { colorsRating } from '../../../core/enums/rating.enum';
import OrderScheduling from './OrderScheduling';
import { createSchedule } from '../../../core/services/schedule/schedule.service';
import SkillIconText from '../../../core/components/SkillIconText';

const { Text } = Typography;

const Scheduling = ({ selectedDate, onDateNavigate }) => {
  const [valueOrders, setOrders] = useState<IOrder[]>([]);
  const [valueAvailableEmployees, setAvailableEmployees] = useState<
    IEmployee[]
  >([]);
  const [valueAvailableSkills, setAvailableSkills] = useState<ISkill[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<String>('loading');

  useEffect(() => {
    setFetchStatus('loading');
    Promise.all([
      getAllSkills(),
      getAllOrders(
        { date: selectedDate.format('YYYY-MM-DD') },
        selectedDate.format('YYYY-MM-DD')
      ),
      getAllEmployeesAvailables({ date: selectedDate.format('YYYY-MM-DD') })
    ]).then(
      ([skills, orders, employees]) => {
        setOrders(orders.data);
        setAvailableEmployees(employees.data);
        setAvailableSkills(skills.data);
        setFetchStatus('success');
      },
      (err) => {
        message.error(err.message);
        setFetchStatus('error');
      }
    );
  }, [selectedDate]);

  const handleSubmit = (assingation) => {
    const request = createSchedule(assingation);
    request.then(
      () => {
        notification.open({
          message: 'Schedule assigned successfully.',
          type: 'success'
        });
      },
      (err) => {
        notification.open({
          message: err.message,
          type: 'error'
        });
      }
    );
  };

  const notAvailableEmployees = concat(
    [],
    ...valueOrders.map((ord) =>
      concat(
        [],
        ...ord.Schedules.data.map((data) =>
          data.Assignations.data.map((assig) => assig.employee.id)
        )
      )
    )
  );
  const unsignedEmployees = valueAvailableEmployees.filter(
    (emp) => !notAvailableEmployees.includes(emp.id)
  );

  if (valueFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }
  if (valueFetchStatus === 'error') {
    return <span>Error...</span>;
  }
  return (
    <div className="scheduling-container">
      <Row align="middle" justify="center">
        <Col>
          <Row
            justify="center"
            align="middle"
            style={{ textAlign: 'center' }}
            gutter={10}
          >
            <Col>
              <Button
                type="link"
                onClick={() => onDateNavigate(selectedDate.subtract(1, 'day'))}
              >
                <CaretLeftOutlined
                  style={{
                    color: '#636363',
                    borderRadius: 1
                  }}
                />
              </Button>
            </Col>
            <Col>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  lineHeight: '20px',
                  letterSpacing: '0.2px',
                  color: 'rgba(0, 0, 0, 0.75)'
                }}
              >
                {selectedDate.format('dddd')}
              </Text>
              <div style={{}}>
                <DatePicker
                  className="custom"
                  format="M/DD"
                  value={selectedDate}
                  onChange={(date) => onDateNavigate(moment(date))}
                  suffixIcon={null}
                  clearIcon={false}
                  bordered={false}
                  renderExtraFooter={() => 'extra footer'}
                  style={{
                    fontWeight: 200,
                    marginLeft: 10,
                    fontSize: 15,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    color: 'rgba(0, 0, 0, 0.75)',
                    padding: 0,
                    paddingLeft: 10,
                    margin: 0
                  }}
                />
              </div>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() => onDateNavigate(selectedDate.add(1, 'day'))}
              >
                <CaretRightOutlined
                  style={{
                    color: '#636363',
                    borderRadius: 1
                  }}
                />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 16 }}>
        <Col
          span={16}
          style={{ marginTop: 15, overflowY: 'scroll', maxHeight: '80vh' }}
        >
          <div style={{ width: '100%' }}>
            {valueOrders.length ? null : 'No order available on selected date.'}
            {valueOrders.map((order) => {
              return (
                <OrderScheduling
                  key={order.id}
                  availableEmployees={valueAvailableEmployees}
                  availableSkills={valueAvailableSkills}
                  selectedDate={selectedDate}
                  order={order}
                  onChangeOrder={(newOrder) => {
                    setOrders(
                      valueOrders.map((ord) =>
                        ord.id === newOrder.id ? newOrder : ord
                      )
                    );
                  }}
                  onSubmit={(assignation) =>
                    handleSubmit({
                      ...assignation,
                      order: order.id,
                      // ToDo: Is not needed
                      date: order.Schedules.data[0]
                        ? order.Schedules.data[0].date
                        : selectedDate.format('YYYY-MM-DD')
                    })
                  }
                />
              );
            })}
          </div>
        </Col>
        <Col span={8} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
          <div style={{ marginBottom: 15 }}>
            Available ({unsignedEmployees.length})
          </div>
          <div>
            {unsignedEmployees.map((employee) => {
              return (
                <div
                  className="card-custom-padding"
                  style={{
                    border: '1px solid #D7DEE2',
                    boxSizing: 'border-box',
                    borderRadius: 4,
                    minHeight: 46,
                    display: 'flex',
                    flex: 1,
                    marginBottom: 14
                  }}
                  draggable="true"
                  onDragStart={(ev) => {
                    const targetDiv = ev.target as HTMLDivElement;
                    ev.dataTransfer.setData('text', targetDiv.id);
                  }}
                  id={employee.id}
                  key={employee.id}
                >
                  <div
                    style={{
                      marginRight: 10,
                      marginLeft: 5,
                      backgroundColor: colorsRating[employee.rating],
                      width: 5,
                      borderRadius: 4,
                      border: '1px solid #D7DEE2'
                    }}
                  />
                  <Row
                    gutter={24}
                    justify="space-between"
                    align="middle"
                    style={{ paddingTop: 5, flex: 1 }}
                  >
                    <Col span={10}>
                      <Text
                        style={{
                          fontSize: 14,
                          lineHeight: '20px',
                          letterSpacing: '0.2px',
                          color: '#000000',
                          marginRight: 3
                        }}
                      >
                        {employee.user.profile.firstName}{' '}
                        {employee.user.profile.lastName}
                      </Text>
                      {employee.h2bVisa ? (
                        <Tag
                          style={{
                            color: '#000000',
                            backgroundColor: 'F5F3F3',
                            borderRadius: 4,
                            border: '1px solid #D7DEE2',
                            marginTop: 5,
                            marginBottom: 5
                          }}
                        >
                          signed safety policy
                        </Tag>
                      ) : null}
                    </Col>
                    <Col span={14}>
                      <div
                        style={{
                          display: 'flex',
                          // flex: 1,
                          flexWrap: 'wrap',
                          // justifyContent: 'center',
                          flexDirection: 'row-reverse',
                          alignItems: 'flex-start'
                        }}
                      >
                        {employee.Skills.data.map((skill) => {
                          return (
                            <Space
                              size="small"
                              key={skill.id}
                              style={{
                                marginRight: 10,
                                marginBottom: 5
                                // textAlign: 'center',
                              }}
                            >
                              <SkillIconText name={skill.name} />
                            </Space>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Scheduling;
