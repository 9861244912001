import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Row,
  Table,
  Typography,
  Space,
  message,
  Slider,
  Tabs,
  Popconfirm
} from 'antd';
import { DeleteOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { getAllEmployees } from '../../../core/services/employee/employee.service';
import { IEmployee } from '../../../core/interfaces/employee.interface';
import { ISkill } from '../../../core/interfaces/skill.interface';
import { getAllSkills } from '../../../core/services/skill/skill.service';
import { ICertification } from '../../../core/interfaces/certification.interface';
import { getAllCertifications } from '../../../core/services/certification/certification.service';
import EmployeeFilter from './EmployeeFilter';
import SkillIconText from '../../../core/components/SkillIconText';
import CertificationIconText from '../../../core/components/CertificationIconText';
import FilterButton from '../../../core/components/FilterButton';
import { formatPhone } from '../../../core/helpers/utilities';
import './index.scss';
import { EStatus } from '../../../core/interfaces/status.enum';
import {
  ChangeUserStatus,
  DeleteUser,
  GetUsers
} from '../../../core/services/user/user.service';
import { IUser } from '../../../core/interfaces/user.interface';

const { TabPane } = Tabs;

const { Text } = Typography;

export const DEFAULT_FILTER = {
  englishLevelGt: 0,
  certificationsHasAll: [],
  skillsHasAll: [],
  nameLike: '',
  transportation: null
  // ratingGt: null,
};
const cleanFilter = (filterIn) => {
  return {
    ...(filterIn.englishLevelGt !== 0
      ? { englishLevelGt: filterIn.englishLevelGt }
      : {}),
    ...(filterIn.certificationsHasAll.length !== 0
      ? { certificationsHasAll: filterIn.certificationsHasAll }
      : {}),
    ...(filterIn.skillsHasAll.length !== 0
      ? { skillsHasAll: filterIn.skillsHasAll }
      : {}),
    ...(filterIn.transportation !== null
      ? { transportation: filterIn.transportation }
      : {}),
    // ...(filterIn.ratingGt !== null ? { ratingGt: filterIn.ratingGt } : {}),
    ...(filterIn.nameLike !== '' ? { nameLike: filterIn.nameLike } : {})
  };
};

const Employees = () => {
  const columns = [
    {
      title: 'Name',
      width: '20%',
      render: (employeeObj) => {
        return (
          <Text className="row-text">
            {employeeObj.user.profile.firstName}{' '}
            {employeeObj.user.profile.lastName}
          </Text>
        );
      }
    },
    {
      title: 'Phone #',
      width: '15%',
      render: (emp) => {
        return (
          <Text className="row-text">
            {formatPhone(emp.user.profile.phone)}
          </Text>
        );
      }
    },
    {
      title: 'Transportation',
      dataIndex: 'transportation',
      width: '3%',
      render: (transportation, employee: IEmployee) => {
        if (employee.user?.status === EStatus.REGISTERED_ON_APP) return '';
        return <Text className="row-text">{transportation ? 'Y' : 'N'}</Text>;
      }
    },
    {
      title: 'Skills',
      width: '25%',
      dataIndex: 'Skills',
      render: (skills) => {
        return skills?.data?.map((skill) => {
          return (
            <Space
              size="small"
              key={skill.id}
              style={{ marginRight: 5, textAlign: 'center' }}
            >
              <SkillIconText name={skill.name} />
            </Space>
          );
        });
      }
    },
    {
      title: 'Certifications',
      width: '15%',
      dataIndex: 'certifications',
      render: (certifications, employeeObj: IEmployee) => {
        if (employeeObj.user?.status === EStatus.REGISTERED_ON_APP) return '';
        return employeeObj?.certifications?.map((certification) => {
          return (
            <Space
              key={certification.catalog?.id || 0}
              size="small"
              style={{ marginRight: 10, textAlign: 'center' }}
            >
              <CertificationIconText name={certification.catalog?.name} />
            </Space>
            // ToDo: This cant happen on backend. Add non null on EmployeeCertification gql type
          );
        });
      }
    },
    {
      title: 'English Proficiency',
      width: '12%',
      dataIndex: 'englishLevel',
      render: (englishLevel, employee: IEmployee) => {
        if (employee.user?.status === EStatus.REGISTERED_ON_APP) return '';
        return <Slider value={parseInt(englishLevel, 10)} disabled />;
      }
    },
    {
      title: '',
      width: '1%',
      render: (text, emp: IEmployee) => {
        if (emp.user?.status === EStatus.REGISTERED_ON_APP) return '';
        return (
          <RightOutlined
            style={{ fontSize: 15, color: '#465968', cursor: 'pointer' }}
            onClick={() => history.push(`employees/${emp.id}`)}
          />
        );
      }
    },
    {
      title: '',
      width: '1%',
      render: (text, employee: IEmployee) => {
        return employee.user.status === EStatus.REGISTERED_ON_APP ? (
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => deleteUser(employee)}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <DeleteOutlined
              style={{ fontSize: 15, color: '#465968', cursor: 'pointer' }}
            />
          </Popconfirm>
        ) : null;
      }
    },
    {
      title: '',
      width: '',
      render: (text, employee: IEmployee) => {
        return employee.user.status === EStatus.REGISTERED_ON_APP_COMPLETED ? (
          <Popconfirm
            title="Are you sure to mark as active?"
            onConfirm={() => changeUserStatus(employee.user?.id)}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <Space style={{ width: '100%' }}>
              <Button
                type="primary"
                loading={loadingStatus === employee.user?.id ? true : false}
              >
                Mark as active
              </Button>
            </Space>
          </Popconfirm>
        ) : null;
      }
    }
  ];
  const history = useHistory();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [valueFetchStatus, setFetchStatus] = useState<boolean>(false);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [certificates, setCertificates] = useState<ICertification[]>([]);
  const [showFilter, setShowFilter] = useState(true);
  const [statusValue, setStatusValue] = useState<EStatus>(EStatus.ACTIVE);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadingStatus, setLoadingStatus] = useState<string>('');

  useEffect(() => {
    setCurrentPage(1);
  }, [employees]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const fetchAllSkills = () => {
    getAllSkills().then((resp) => {
      setSkills(resp.data);
    });
  };

  const fetchAllCertificates = () => {
    getAllCertifications().then((resp) => {
      setCertificates(resp.data);
    });
  };
  const [filterInConst, setFilterInConst] = useState<{
    englishLevelGt: Number;
    certificationsHasAll: string[];
    skillsHasAll: string[];
    nameLike: string;
    transportation: Boolean | null;
    // ratingGt: string | null;
  }>(DEFAULT_FILTER);

  const [filter, setFilter] = useState<{
    englishLevelGt: Number;
    certificationsHasAll: string[];
    skillsHasAll: string[];
    nameLike: string;
    transportation: Boolean | null;
    // ratingGt: string | null;
  }>(DEFAULT_FILTER);

  const fetchEmployees = (filterIn, status: EStatus, users: any[]) => {
    setFetchStatus(true);
    const filterClean = cleanFilter(filterIn);
    getAllEmployees({ ...filterClean, status }).then(
      (resp: any) => {
        setEmployees([...resp.data, ...users]);
        setFetchStatus(false);
      },
      (err) => {
        message.error(err.message);
        setFetchStatus(false);
      }
    );
  };

  const changeUserStatus = async (id: string) => {
    if (id) {
      setLoadingStatus(id);
      try {
        await ChangeUserStatus(id, EStatus.ACTIVE);
        const newValue = employees.filter(
          (employee) => employee.user?.id !== id
        );
        setEmployees(newValue);
        setLoadingStatus('');
      } catch (error) {
        setLoadingStatus('');
        message.error('Error changing status');
      }
    }
  };

  const transformUsers = (users: IUser[]): any[] => {
    let usersTransformed = users.map((user) => {
      return {
        user: {
          id: user.id,
          status: EStatus.REGISTERED_ON_APP,
          profile: {
            phone: user.username
          }
        }
      };
    });
    return usersTransformed;
  };

  const changeTab = async (status) => {
    setStatusValue(status);

    if (status === EStatus.REGISTERED_ON_APP) {
      setFetchStatus(true);
      try {
        const users: any = await GetUsers(
          {
            status: EStatus.REGISTERED_ON_APP
          },
          -1,
          0
        );
        const usersTransformed = transformUsers(users.data);

        fetchEmployees(
          filter,
          EStatus.REGISTERED_ON_APP_COMPLETED,
          usersTransformed
        );
      } catch (error) {
        message.error('Error fetching users');
        setFetchStatus(false);
      }
    } else {
      fetchEmployees(filter, status, []);
    }
  };

  const deleteUser = async (transformedUser: IEmployee) => {
    try {
      setFetchStatus(true);
      await DeleteUser(transformedUser.user.id);
      message.success('User deleted');
      fetchEmployees(filter, statusValue, []);
    } catch (error) {
      setFetchStatus(false);
      message.error('Error deleting user');
    }
  };

  useEffect(() => {
    fetchEmployees(filter, statusValue, []);
  }, [filter]);

  useEffect(() => {
    fetchAllSkills();
    fetchAllCertificates();
  }, []);

  return (
    <div className="employees-container">
      <Row gutter={15} style={{}}>
        <Col span={8}>
          <Row>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#000000',
                marginBottom: 5
              }}
            >
              Employees
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[24, 8]}>
        <Col span={20}>
          <EmployeeFilter
            filter={filterInConst}
            setFilter={setFilterInConst}
            skillsAvailables={skills}
            certificatesAvailables={certificates}
            onSubmit={setFilter}
            showFilter={showFilter}
          />
        </Col>
        <Col>
          <Row>
            <Link to="/employees/new-employee">
              <Button
                type="default"
                size="large"
                style={{
                  color: 'white',
                  fontSize: 15,
                  background: '#BAC2C8',
                  borderRadius: 4,
                  fontWeight: 'bold',
                  lineHeight: '20px',
                  letterSpacing: '0.2px'
                }}
              >
                <PlusOutlined /> New Employee
              </Button>
            </Link>
          </Row>
          <Row>
            {statusValue === EStatus.REGISTERED_ON_APP ? null : (
              <FilterButton
                styles={{ marginTop: 10 }}
                handleFilter={toggleFilter}
              />
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="1" onChange={changeTab}>
            <TabPane tab="Active" key={EStatus.ACTIVE}></TabPane>
            <TabPane
              tab="Registered on web"
              key={EStatus.REGISTERED_ON_WEB}
            ></TabPane>
            <TabPane
              tab="Registered on mobile"
              key={EStatus.REGISTERED_ON_APP}
            ></TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row gutter={15} style={{ marginBottom: '25px' }}>
        <Col span={24}>
          <Table
            dataSource={employees}
            columns={columns}
            loading={valueFetchStatus}
            pagination={{
              onChange: (page, pageSize) => {
                setCurrentPage(page);
              },
              current: currentPage,
              hideOnSinglePage: true,
              position: ['bottomCenter'],
              defaultPageSize: 7
            }}
            rowKey={(employee) => employee.id}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Employees;
