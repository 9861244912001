import React from 'react';
import { Col, Modal, Row } from 'antd';
import { IApplicant } from '../../../../core/interfaces/applicant.interface';
import moment from 'moment';
import { isNil } from 'lodash';

interface IProps {
  visible: boolean;
  applicant: IApplicant | undefined;
  onOk: () => void;
  onCancel: () => void;
}

const ApplicantInfo = (props: IProps) => {
  const { visible, onOk, onCancel, applicant } = props;

  const getAge = (value: boolean | undefined | null): string => {
    if (isNil(value)) {
      return '-';
    } else {
      return value ? 'Is over 18 years old' : 'Is not over 18 years old';
    }
  };

  const getDriverLicense = (value: boolean | undefined | null) => {
    if (isNil(value)) {
      return '-';
    } else {
      return value
        ? "Has a valid driver's license"
        : "Has not a valid driver's licens";
    }
  };

  return (
    <Modal
      title="Application"
      okText="Convert to Employee"
      cancelText="Cancel"
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
      width={600}
    >
      <Row>
        <Col span={15}>
          <div className="line__container">
            <p className="line__container__title">Applicant Info</p>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">FIRST NAME</p>
                <p className="info__container__item__text">
                  {applicant?.firstname}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">LAST NAME</p>
                <p className="info__container__item__text">
                  {applicant?.lastname}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">BIRTH DATE</p>
                <p className="info__container__item__text">
                  {applicant?.birthDate
                    ? moment(applicant.birthDate).format('YYYY-MM-DD')
                    : '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="line__container">
            <p className="line__container__title">Contact Info</p>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">EMAIL</p>
                <p className="info__container__item__text">
                  {applicant?.email}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">PHONE NUMBER</p>
                <p className="info__container__item__text">
                  {applicant?.phone}
                </p>
              </div>
            </div>
          </div>

          <div className="line__container">
            <p className="line__container__title">Previous Employer</p>
            <div className="info__container">
              <div className="info__container__item">
                <p className="info__container__item__label">Name</p>
                <p className="info__container__item__text">
                  {applicant?.previousEmployerName || '-'}
                </p>
              </div>
              <div className="info__container__item">
                <p className="info__container__item__label">PHONE NUMBER</p>
                <p className="info__container__item__text">
                  {applicant?.previousEmployerPhone || '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="line__container">
            <p className="line__container__title">
              {applicant?.meetMinimumRequired
                ? 'Meets the minimum requirements for this position'
                : 'Does not meet the minimum requirements for this position'}
            </p>
          </div>
          <div className="line__container">
            <p className="line__container__title">
              {getAge(applicant?.atLeast18)}
            </p>
          </div>
          <div className="line__container">
            <p className="line__container__title">
              {getDriverLicense(applicant?.driverLicense)}
            </p>
          </div>
        </Col>
        <Col span={9}>
          <p>Work Experience</p>
          <p>{applicant?.workExperience || '-'}</p>
        </Col>
      </Row>
    </Modal>
  );
};

export default ApplicantInfo;
