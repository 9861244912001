import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import IncidentForm from './IncidentForm';
import { getIncident } from '../../../../core/services/incident/incident.service';
import { IIncident } from '../../../../core/interfaces/incident.interface';

const { Text } = Typography;

const IncidentDetail = ({ incidentId }) => {
  const [incident, setIncident] = useState<IIncident>();

  useEffect(() => {
    getIncident(incidentId).then((incidentIn) => {
      setIncident(incidentIn);
    });
  }, [incidentId]);

  return (
    <div className="employee-container">
      <Row justify="center" gutter={24}>
        <Col span={24}>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginBottom: 30,
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#000000',
                marginBottom: 0,
              }}
            >
              Incident Detail
            </Text>
          </div>
          <Row justify="center" align="middle" gutter={15}>
            <Col span={24}>
              {incident ? (
                <IncidentForm incident={incident} />
              ) : (
                <span>Loading...</span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default IncidentDetail;
