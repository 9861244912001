import { message, Table } from 'antd';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import {
  IArchivedInvoice,
  IArvhivedItem,
  IDataLine
} from '../../../../../core/interfaces/invoice.interface';
import { InvoiceArchieved } from '../../../../../core/services/invoice/invoice.service';
import { formatTimeSpentSeconds } from '../../../../../core/helpers/utilities';
import '../index.scss';
import pdfIcon from '../../../../../core/images/pdf-icon.svg';

interface IProps {
  reload: number;
  lastPayPeriod: (value: string) => void;
}

const ArchivedTable = (props: IProps) => {
  const [firstCall, setFirstCall] = useState(false);
  const { lastPayPeriod } = props;
  const columns = [
    {
      title: 'Pay Period/Name',
      dataIndex: 'name',
      width: '25%',
      render: (text: string, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Generated Date/Hours',
      dataIndex: 'createdAt',
      width: '20%',
      render: (text: string, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Confirmed Date/Rate',
      dataIndex: 'confirmedAt',
      width: '20%',
      render: (text: string, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Pushed to QB/Total',
      dataIndex: 'qbDate',
      width: '20%',
      render: (text: string, record) => {
        return record.children ? <strong>{text}</strong> : text;
      }
    },
    {
      title: 'Timecards',
      dataIndex: 'client',
      render: (client: IDataLine) => {
        return client?.order?.pdfUrl ? (
          <img
            className="image"
            src={pdfIcon}
            alt="timecar"
            onClick={() => {
              window.open(client.order.pdfUrl);
            }}
          />
        ) : (
          '-'
        );
      }
    }
  ];

  const [invoices, setInvoices] = useState<IArvhivedItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalArchived, setTotalArchived] = useState<number>();

  useEffect(() => {
    getInvoiceHistory(10, 0);
  }, [props.reload]);

  const getInvoiceHistory = async (limit: number, skip: number) => {
    setLoading(true);
    try {
      const data: IArchivedInvoice = await InvoiceArchieved(limit, skip);

      if (data) {
        setTotalArchived(data.total);
        setInvoices(transformDataTable(data.data));

        if (data.data.length > 0) {
          do {
            const invoiceCompleted = data.data.find(
              (invoice) => invoice.invoice.confirmedAt && invoice.invoice.qbDate
            );
            if (invoiceCompleted) {
              const periodEnd = moment(
                invoiceCompleted.invoice.payPeriodEnd
              ).format('LL');
              lastPayPeriod(periodEnd);
            }
          } while (firstCall);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Error getInvoiceHistory');
    }
  };

  const transformDataTable = (data: IArvhivedItem[]): any => {
    let finalData = data.map((item) => {
      let clients: any[] = [];
      item.lineItemsGroupByOrder.forEach((client) => {
        let employees: any[] = [];
        client.lineItems.forEach((line) => {
          //EMPLOYEE
          employees.push({
            key: uuidv4(),
            name: `${line.employee.user.profile.firstName} ${
              line.employee.user.profile.lastName
            } ${line.type === 'REGULAR' ? 'Reg. Hrs.' : '0/T Hrs'} ( ${
              line.task
            } )`,
            createdAt: formatTimeSpentSeconds(line.timeSpentSeconds),
            confirmedAt: line.rate,
            qbDate: line.total.toString()
          });
        });
        //CLIENT
        clients.push({
          client,
          key: uuidv4(),
          name: `${client.order.customer.name} ( ${client.order.nro} )`,
          createdAt: formatTimeSpentSeconds(client.timeSpentSeconds),
          confirmedAt: client.avgRate,
          qbDate: client.total.toString(),
          children: employees
        });
      });
      //INVOICE
      return {
        key: uuidv4(),
        name: `${moment(item.invoice.payPeriodStart).format(
          'YYYY/MM/DD'
        )} to ${moment(item.invoice.payPeriodEnd).format('YYYY/MM/DD')}`,
        createdAt: `${
          item.invoice.createdAt
            ? moment(item.invoice.createdAt).format('YYYY/MM/DD')
            : '-'
        }`,
        confirmedAt: `${
          item.invoice.confirmedAt
            ? moment(item.invoice.confirmedAt).format('YYYY/MM/DD')
            : '-'
        }`,
        qbDate: `${
          item.invoice.qbDate
            ? moment(item.invoice.qbDate).format('YYYY/MM/DD')
            : '-'
        }`,
        children: clients
      };
    });
    return finalData;
  };

  const onHandleChangePage = (page: number) => {
    const skip = 10 * (page - 1);
    if (totalArchived && skip < totalArchived) {
      getInvoiceHistory(10, skip);
    }
  };

  return (
    <>
      <h1 style={{ fontSize: '22px', marginTop: '2rem' }}>Invoice History</h1>
      <Table
        columns={columns}
        dataSource={invoices}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          total: totalArchived,
          onChange: onHandleChangePage
        }}
      />
    </>
  );
};

export default ArchivedTable;
