import React from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  formatAddress,
  formatTimeSpentSeconds,
} from '../../../core/helpers/utilities';

const { Text } = Typography;

function getBestFeedback(dateEntries) {
  return dateEntries.find((dd) => dd.feedback)?.feedback || '';
}

function getConfirmed(dateEntries) {
  return dateEntries.every((dd) => dd.confirmed);
}

const WeekTree = ({ orderTraces }) => {
  return (
    <Row>
      <Col span={24}>
        <Table
          className="week-table"
          dataSource={orderTraces.map((orderTrace) => {
            const oT = {
              ...orderTrace,
              children: orderTrace.item.employeeTraces.map((employeeTrace) => {
                const allConfirmed = getConfirmed(
                  employeeTrace.item.dateTraces
                );
                return {
                  ...employeeTrace,
                  confirmed: allConfirmed,
                  // key: employeeTrace.employee.id,
                  children: [
                    ...[].concat(
                      ...employeeTrace.item.dateTraces.map((dateTrace) => {
                        const formatTask = (taskTrace) => ({
                          ...taskTrace,
                          // key: taskTrace.task.id,
                          parent: dateTrace,
                        });
                        return [
                          {
                            ...dateTrace,
                            firstTask: formatTask(dateTrace.item.taskTraces[0]),
                            // key: dateTrace.date,
                            parent: employeeTrace,
                          },
                          ...dateTrace.item.taskTraces.slice(1).map(formatTask),
                        ];
                      })
                    ),
                    {
                      feedback: getBestFeedback(employeeTrace.item.dateTraces),
                      type: 'feedback',
                      aKey: `feedbackof-${employeeTrace.employee.id}`,
                    },
                  ],
                };
              }),
            };
            oT.children.forEach((eT) => {
              // eslint-disable-next-line no-param-reassign
              eT.parent = oT;
              let dT;
              eT.children.forEach((dTtT) => {
                if (dTtT.date) {
                  dT = dTtT;
                  dT.parent = eT;
                  dT.firstTask.parent = dT;
                } else if (dTtT.task) {
                  // eslint-disable-next-line no-param-reassign
                  dTtT.parent = dT;
                }
              });
            });
            return oT;
          })}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomRight'],
          }}
          rowKey={(trace) => {
            if (trace.type === 'feedback') {
              return trace.aKey;
            }
            if (trace.order) {
              return trace.order.id;
            }
            if (trace.employee) {
              return trace.employee.id;
            }
            if (trace.date) {
              return trace.date;
            }
            if (trace.task) {
              return trace.task.id;
            }
            throw new Error('wrong data');
          }}
          rowClassName={(record) => {
            let dateTrace;
            if (record.date) {
              dateTrace = record;
            } else if (record.task) {
              dateTrace = record.parent;
            }
            if (dateTrace) {
              const currentIndex = dateTrace.parent.item.dateTraces.findIndex(
                (rr) => rr.date === dateTrace.date
              );
              if (currentIndex % 2 !== 1) {
                return 'no-border-row bg-light';
              }
              return 'no-border-row';
            }
            return '';
          }}
          expandable={{
            expandIconColumnIndex: 6,
            expandIcon: ({ expanded, onExpand, record }) => {
              if (record.type === 'feedback') {
                return null;
              }
              if (record.date) {
                return null;
              }
              if (record.task) {
                return null;
              }
              return expanded ? (
                <DownOutlined
                  style={{ fontSize: 15, color: '#465968' }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <RightOutlined
                  style={{ fontSize: 15, color: '#465968' }}
                  onClick={(e) => onExpand(record, e)}
                />
              );
            },
          }}
        >
          <Table.Column
            title="Customer"
            key="customer"
            render={(trace) => {
              if (trace.type === 'feedback') {
                return {
                  children: '',
                  props: {
                    colSpan: 1,
                  },
                };
              }
              if (trace.order) {
                return (
                  <Text className="row-text">{trace.order.customer.name}</Text>
                );
              }
              if (trace.employee) {
                return {
                  children: '',
                  props: {
                    colSpan: 1,
                  },
                };
              }
              return '';
            }}
            width="13%"
          />
          <Table.Column
            className="custom-feedback"
            title="Job #"
            key="order"
            render={(trace) => {
              if (trace.order) {
                return (
                  <Text className="row-text fw-300 light-color">
                    {trace.order.nro}
                  </Text>
                );
              }
              if (trace.employee) {
                return {
                  children: (
                    <Text className="fs-14 ml-4" style={{ display: 'block' }}>
                      {`${trace.employee.user.profile.firstName} ${trace.employee.user.profile.lastName}`}
                    </Text>
                  ),
                  props: {
                    colSpan: 1,
                  },
                };
              }
              if (trace.date) {
                return {
                  children: (
                    <Text
                      className="c-italic fw-200 fs-14 ml-8"
                      style={{ color: 'rgba(0,0,0,0.5)' }}
                    >
                      {moment(trace.date).format('ddd M/D')}
                    </Text>
                  ),
                  rowSpan: trace.item.taskTraces.length,
                };
              }
              if (trace.type === 'feedback') {
                const feedbackText = trace.feedback;
                return {
                  children: (
                    <div className="ml-8">
                      <Row gutter={[16, 8]}>
                        <Col>
                          <Text className="c-italic fw-600 fs-14">
                            Feedback
                          </Text>
                        </Col>
                      </Row>
                      <Row gutter={[16, 8]}>
                        <Col>
                          <Text className="c-italic fw-200 fs-14">
                            {feedbackText}
                          </Text>
                        </Col>
                      </Row>
                    </div>
                  ),
                  props: {
                    colSpan: 5,
                  },
                };
              }
              return '';
            }}
            width="17%"
          />
          <Table.Column
            title="Address"
            key="address"
            className="taskName"
            render={(trace) => {
              if (trace.type === 'feedback') {
                return {
                  children: null,
                  props: {
                    colSpan: 0,
                  },
                };
              }
              if (trace.order) {
                return (
                  <Text className="fs-14 fw-300 light-color">
                    {formatAddress(trace.order.address)}
                  </Text>
                );
              }
              if (trace.employee) {
                return {
                  children: '',
                  props: {
                    colSpan: 1,
                  },
                };
              }
              if (trace.date) {
                return (
                  <Text
                    style={{
                      color: 'rgba(0,0,0, 0.5)',
                    }}
                    className="c-italic fw-200 fs-14 ml-4"
                  >
                    {trace.item.taskTraces[0].task.name}
                  </Text>
                );
              }
              if (trace.task) {
                return (
                  <Text
                    style={{ color: 'rgba(0,0,0, 0.5)' }}
                    className="c-italic fw-200 fs-14 ml-4"
                  >
                    {trace.task.name}
                  </Text>
                );
              }
              throw new Error('wrong data');
            }}
            colSpan={2}
            width="26%"
          />
          <Table.Column
            title=""
            key="task_timeSpentSeconds"
            render={(trace) => {
              if (trace.type === 'feedback') {
                return {
                  children: null,
                  props: {
                    colSpan: 0,
                  },
                };
              }
              let tFormated;
              if (trace.date) {
                tFormated = formatTimeSpentSeconds(
                  trace.firstTask.item.timeSpentSeconds
                );
              }
              if (trace.task) {
                tFormated = formatTimeSpentSeconds(trace.item.timeSpentSeconds);
              }
              if (tFormated) {
                return {
                  children: (
                    <div
                      style={{
                        display: 'flex',
                        marginRight: 20,
                        alignItems: 'flex-end',
                      }}
                    >
                      <Text className="fw-300 fs-14">{tFormated}</Text>
                    </div>
                  ),
                  props: {
                    colSpan: 1,
                  },
                };
              }
              return '';
            }}
            colSpan={0}
            width="12%"
          />
          <Table.Column
            title="Employee Tracked"
            key="timeSpentSeconds"
            render={(trace) => {
              if (trace.type === 'feedback') {
                return {
                  children: null,
                  props: {
                    colSpan: 0,
                  },
                };
              }
              if (trace.task) {
                return '';
              }
              const tFormated = formatTimeSpentSeconds(
                trace.item.timeSpentSeconds
              );
              if (trace.order) {
                return <Text className="fw-700 fs-14">{tFormated}</Text>;
              }
              if (trace.employee) {
                return <Text className="fw-400 fs-14">{tFormated}</Text>;
              }
              if (trace.date) {
                return {
                  children: <Text className="fw-400 fs-14">{tFormated}</Text>,
                  rowSpan: trace.item.taskTraces.length,
                };
              }
              return '';
            }}
            width="17%"
          />
          <Table.Column
            title="Superintendent Confirmed"
            key="timeConfirmedSeconds"
            render={(trace) => {
              if (trace.type === 'feedback') {
                return {
                  children: null,
                  props: {
                    colSpan: 0,
                  },
                };
              }
              if (trace.task) {
                return '';
              }
              const tFormated = formatTimeSpentSeconds(
                trace.confirmed ? trace.item.timeReviewedSeconds : 0
              );
              if (trace.order) {
                return <Text className="fw-700 fs-14">{tFormated}</Text>;
              }
              if (trace.employee) {
                return <Text className="fw-400 fs-14">{tFormated}</Text>;
              }
              if (trace.date) {
                return {
                  children: <Text className="fw-400 fs-14">{tFormated}</Text>,
                  rowSpan: trace.item.taskTraces.length,
                };
              }
              return '';
            }}
            width="15%"
          />
          <Table.Column title="" key="expander" className="expander" />
        </Table>
      </Col>
    </Row>
  );
};

export default WeekTree;
