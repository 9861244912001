import quickBooksApi from '../../helpers/quickBooks.api';
import { IQuickbooksCreateInvoice } from '../../interfaces/quickbooksCreateInvoice.interface';

const createInvoice = (data: IQuickbooksCreateInvoice) => {
  return quickBooksApi.post('Customer/invoice', data);
};

const createInvoices = (data: IQuickbooksCreateInvoice[]) => {
  return quickBooksApi.post('Customer/invoices', data);
};

export { createInvoice, createInvoices };
