import { ApolloQueryResult } from 'apollo-boost';
import moment from 'moment';
import { FUESApolloClient, authRestCall } from '../../helpers/api';
import { ICustomer } from '../../interfaces/customer.interface';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import {
  CustomerDelete,
  CustomerDetailQuery,
  CustomerListQuery,
  CustomerUpdate,
} from './customer.gql';

export const getAllCustomers = (args = {}) => {
  return FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: CustomerListQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'name.asc',
          },
          args,
        },
      },
    })
    .then(
      (resp: ApolloQueryResult<{ Customer: IPaginationWrapper<ICustomer> }>) =>
        resp.data.Customer
    );
};

export const getCustomer = (id) =>
  FUESApolloClient()
    .query<{ Customer: IPaginationWrapper<ICustomer> }>({
      fetchPolicy: 'no-cache',
      query: CustomerDetailQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'name.asc',
          },
          args: {
            id,
          },
        },
        currentDay: {
          activeAt: moment().format('YYYY-MM-DD'),
        },
      },
    })
    .then((resp) => resp.data.Customer.data[0]);

export const updateCustomer = async (id, payload) =>
  FUESApolloClient()
    .mutate({
      mutation: CustomerUpdate,
      variables: {
        input: { ...payload, id },
      },
    })
    .then((resp) => resp.data.CustomerUpdate);

export const deleteCustomer = async (id) =>
  FUESApolloClient()
    .mutate({
      mutation: CustomerDelete,
      variables: {
        input: {
          id,
        },
      },
    })
    .then((resp) => resp.data.CustomerDelete);

export const uploadFromCSV = async (file) => {
  const ff = new FormData();
  ff.append('file', file);
  return authRestCall('quickbook/csv', {
    method: 'POST',
    body: ff,
    responseType: 'blob',
  });
};
