import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Form, Input, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { Rule } from 'rc-field-form/lib/interface';

import { RequestPasswordChange as RequestPasswordChangeAuth } from '../../core/services/auth/auth.service';
import { isPhone } from '../../core/helpers/phone';
import { isEmail } from '../../core/helpers/email';

const RequestPasswordChange = () => {
  const [error, setError] = useState(null);
  const [recoveryMessage, setRecoveryMessage] = useState(null);
  return (
    <div>
      <h1 style={{ fontSize: 18 }}>Forgot Password</h1>
      <Form
        layout="vertical"
        name="login-form"
        onFinish={({ username }) => {
          setError(null);
          RequestPasswordChangeAuth(username)
            .then((result: any) => {
              setRecoveryMessage(result.message);
            })
            .catch((err) => {
              setError(err.message);
            });
        }}
        size="large"
      >
        {recoveryMessage && (
          <Alert
            style={{ marginBottom: '15px' }}
            message={recoveryMessage}
            type="info"
            showIcon
          />
        )}
        {error && (
          <Alert
            style={{ marginBottom: '15px' }}
            message={error}
            type="error"
            showIcon
          />
        )}
        <Form.Item
          name="username"
          rules={
            [
              { type: 'string' },
              {
                async validator(rule, value) {
                  if (!value) {
                    throw new Error('Please input your phone or email!');
                  }
                  if (!isPhone(value) && !isEmail(value)) {
                    throw new Error('Value is not phone nor an email!');
                  }
                },
              },
            ] as Rule[]
          }
        >
          <Input placeholder="Phone or Email" prefix={<UserOutlined />} />
        </Form.Item>
        <Row justify="center">
          <Form.Item style={{ marginTop: '25px', width: '150px' }}>
            <Button block type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>

      <Row justify="center">
        Have reset code?&nbsp;
        <Link to="/auth/reset-password">Reset Password.</Link>
      </Row>

      <Row justify="center">
        Go back to&nbsp;<Link to="/auth/login">Log In</Link>
      </Row>
    </div>
  );
};

export default withRouter(RequestPasswordChange);
