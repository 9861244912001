import { Button, Card, Col, message, Row, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IApplicant } from '../../../../core/interfaces/applicant.interface';
import { deleteApplicantById } from '../../../../core/services/applicant/applicant.service';
import '../index.scss';
import ApplicantInfo from './ApplicantInfo';

const { Text } = Typography;

interface IProps {
  applicants: IApplicant[] | undefined;
}

const Applicants: FC<IProps> = (props) => {
  const history = useHistory();
  const { applicants } = props;
  const [visible, setVisible] = useState(false);
  const [applicantsForList, setApplicantsForList] = useState<IApplicant[]>([]);
  const [applicantSelected, setApplicantSelected] = useState<IApplicant>();

  const deleteApplicant = async (applicant: IApplicant) => {
    if (window.confirm('Are you sure you want to delete this applicant?')) {
      try {
        const response = await deleteApplicantById(applicant.id);
        const newArr = applicantsForList.filter(
          (app) => app.id !== applicant.id
        );
        setApplicantsForList(newArr);
        message.success('Applicant deleted successfully.');
      } catch (error) {
        message.error('Error deleting applicant.');
      }
    }
  };

  const View = (applicant: IApplicant) => {
    setApplicantSelected(applicant);
    setVisible(true);
  };

  const convertToEmployee = () => {
    if (applicantSelected) {
      history.push(
        `/employees/new-employee-from-applicant/${applicantSelected.id}`
      );
    }
  };

  useEffect(() => {
    if (applicants) {
      setApplicantsForList(applicants);
    }
  }, [applicants]);

  const renderCard = (applicant: IApplicant) => {
    return (
      <Card key={applicant.id} style={{ marginBottom: 10 }}>
        <div className="card__container">
          <span
            className="close__button"
            onClick={() => deleteApplicant(applicant)}
          >
            X
          </span>
          <div className="name__container">
            <Text>
              {applicant.firstname} {applicant.lastname}
            </Text>
            <Text>{moment(applicant.createdAt).format('YYYY-MM-DD')}</Text>
          </div>

          <div>
            <Button onClick={() => View(applicant)}>VIEW</Button>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <Col span={24} style={{}}>
        <Row style={{ marginBottom: 20 }}>
          <Text style={title}>Applicants</Text>
        </Row>

        {applicantsForList?.map((item) => renderCard(item))}
      </Col>

      <ApplicantInfo
        visible={visible}
        applicant={applicantSelected}
        onOk={() => convertToEmployee()}
        onCancel={() => setVisible(false)}
      />
    </div>
  );
};

const title: React.CSSProperties = {
  fontSize: 16,
  lineHeight: '20px',
  letterSpacing: '0.2px'
};

export default Applicants;
