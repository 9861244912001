import { message } from 'antd';
import moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { IDataLine } from '../interfaces/invoice.interface';

export const getFileNameFromUrl = (url: string) => {
  const filename = url.split('/');
  return filename[filename.length - 1];
};

export const formatAddress = ({
  // name,
  country,
  state,
  city,
  address1,
  address2,
  zipCode
}) => {
  /*
    Name of addressee
    House number and street name +Apartment/Suite/Room number if any
    Name of town + State abbreviation + ZIP+4 code
     */
  let address12 = address1?.trim();
  if (address2?.trim()) {
    address12 = [address12, address2?.trim()].join('-');
  }
  if (zipCode?.trim()) {
    address12 = `${zipCode.trim()} ${address12}`;
  }
  if (city?.trim()) {
    address12 = address12 ? `${address12}. ${city?.trim()}` : city?.trim();
  }
  if (state?.trim()) {
    address12 = `${address12}, ${state?.trim()}`;
  }
  return country?.trim() ? `${address12}, ${country?.trim()}` : `${address12}`;
};

export const formatAddressDumb = ({ address1, address2 }) => {
  let address12 = address1?.trim();
  if (address2?.trim()) {
    address12 = [address12, address2?.trim()].join(', ');
  }
  return address12;
};

export const secondsToHours = (seconds) => {
  return seconds ? seconds / (60 * 60) : seconds;
};

export function addDates(obj) {
  return {
    ...obj,
    ...(obj.createdAt ? { createdAt: moment(obj.createdAt) } : {}),
    ...(obj.updatedAt ? { updatedAt: moment(obj.updatedAt) } : {})
  };
}

export const formatTimeSpentHours = (totalHoursIn) => {
  if (totalHoursIn !== null && totalHoursIn !== undefined) {
    return totalHoursIn.toFixed(2);
  }
  return '';
};

export const formatTimeSpentSeconds = (totalSecondsIn) => {
  let totalSeconds = totalSecondsIn;
  if (totalSeconds < 0) {
    totalSeconds = 0;
  }
  const dminutes = Math.trunc(totalSeconds / 60);
  const minutes = `${dminutes % 60}`;
  const dhours = Math.trunc(dminutes / 60);
  const hours = `${dhours}`;

  return `${hours}:${minutes.padStart(2, '0')}`;
};

export const formatMoney = (money) => {
  if (
    money !== null &&
    money !== undefined &&
    !Number.isNaN(money) &&
    money !== ''
  ) {
    return `${money.toFixed(2)}`;
  }
  return null;
};

export const formatPhone = (vv) => {
  if (vv) {
    const nn = vv.replace(/[ ()-]/g, '');
    if (nn.length > 3) {
      const lPart = nn.slice(3);
      return `(${nn.slice(0, 3)}) ${
        lPart.length > 3 ? `${lPart.slice(0, 3)} - ${lPart.slice(3)}` : lPart
      }`;
    }
    return nn;
  }
  return '';
};

export const formatTimeRange = (range) => {
  const from = range[0].format('MMMM Do');
  const to = range[1].format('Do YYYY');
  return `${from} - ${to}`;
};

export const weekToDateRange = ({ week, year }) => {
  return [
    moment().startOf('day').week(week).year(year).day(0),
    moment().startOf('day').week(week).year(year).day(6)
  ];
};

export const formatWeek = (weekOfYear) => {
  return formatTimeRange(weekToDateRange(weekOfYear));
};

export const weekOfDate = (date) => {
  return {
    week: date.week(),
    year: date.year()
  };
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function cancelAblePromise<T>(prom) {
  const obj = {
    promise: new Promise<T>((resolve, reject) => {
      prom.then(
        (res) => {
          if (!obj.canceled) {
            resolve(res);
          }
        },
        (err) => {
          if (!obj.canceled) {
            reject(err);
          }
        }
      );
    }),
    canceled: false,
    cancel: () => {
      const hasChanged = !obj.canceled;
      obj.canceled = true;
      return { canceled: obj.canceled, hasChanged };
    }
  };
  return obj;
}

export const USA_DATE_FORMAT = 'MM/DD/YY';
export const USA_TIME_FORMAT = 'hh:mm A';

export const pdfPrintInvoiceClient = (client: IDataLine) => {
  if (client) {
    try {
      let employeesRows: any = [];

      const ClientRow = [
        {
          text: `${client.order.customer.name} ( ${client.order.nro} )`,
          bold: true,
          fontSize: 14
        },
        formatTimeSpentSeconds(client.timeSpentSeconds),
        client.avgRate,
        client.total
      ];

      client.lineItems.forEach((line) => {
        const employeeRow = [
          {
            text: `${line.employee.user.profile.firstName} ${
              line.employee.user.profile.lastName
            } ${line.type === 'REGULAR' ? 'Reg. Hrs.' : '0/T Hrs'} ( ${
              line.task
            } )`
          },
          formatTimeSpentSeconds(line.timeSpentSeconds),
          line.rate,
          line.total
        ];
        employeesRows.push(employeeRow);
      });

      const ClientOrderDocDefinition: TDocumentDefinitions = {
        info: {
          title: `${client.order.customer.name} ( ${client.order.nro} )`,
          author: 'Fillerup'
        },
        pageSize: 'A4',
        pageMargins: 16,
        pageOrientation: 'landscape',
        styles: {
          title: {
            fontSize: 18,
            margin: [0, 0, 0, 15]
          }
        },
        content: [
          { text: `Client Order`, style: 'title' },
          {
            table: {
              widths: [270, '*', '*', '*'],
              body: [
                ['Name', 'Hours', 'Rate', 'Total'],
                ClientRow,
                ...employeesRows
              ]
            }
          }
        ]
      };

      const fonts: any = {
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-MediumItalic.ttf',
          bolditalics: 'Roboto-Italic.ttf'
        }
      };

      pdfMake
        .createPdf(
          ClientOrderDocDefinition,
          undefined,
          fonts,
          pdfFonts.pdfMake.vfs
        )
        .open();
    } catch (error) {
      message.error('Error printing PDF');
    }
  }
};
