import React from 'react';
import labor from '../images/skillIcons/labor.svg';
import carpentry from '../images/skillIcons/carpentry.svg';
import forklift from '../images/skillIcons/forklift.svg';
import flagger from '../images/skillIcons/flagger.svg';
import foreman from '../images/skillIcons/foreman.svg';
import painting from '../images/skillIcons/painting.svg';

const NAME_2_ICON_MAP = new Map<string, any>([
  ['Labor', labor],
  ['Carpenters Helper', carpentry],
  ['Carpenter', carpentry],
  ['Machine Operator', forklift],
  ['Rod Buster', labor],
  ['Flagger', flagger],
  ['Foreman', foreman],
  ['Painter', painting],
]);
export const nameToIcon = (nname) => {
  if (NAME_2_ICON_MAP.has(nname)) {
    return NAME_2_ICON_MAP.get(nname);
  }
  return labor;
};

const ICON_SIZE = 20;

const SkillIcon = ({ skillName, ...props }) => (
  <img
    alt={skillName}
    src={nameToIcon(skillName)}
    {...props}
    style={{ width: ICON_SIZE, height: ICON_SIZE }}
  />
);

export default SkillIcon;
