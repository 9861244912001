import gql from 'graphql-tag';
import {
  customerWithSuperintendentsFieldsFragment,
  customerFieldsFragment,
  customerWithSuperintendentsWithActiveOrdersFieldsFragment,
} from '../../fragments/customer.fragment';
import { pagingFieldsFragment } from '../../fragments/pagination.fragment';

export const CustomerListQuery = gql`
  query CustomerList($input: CustomerPagingInput) {
    ...customerDetailQuery
  }

  fragment customerDetailQuery on Query {
    Customer(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...customerWithSuperintendentsFields
      }
    }
  }

  ${pagingFieldsFragment}
  ${customerWithSuperintendentsFieldsFragment}
`;

export const CustomerDetailQuery = gql`
  query CustomerList(
    $input: CustomerPagingInput
    $currentDay: ActiveOrderQuery
  ) {
    ...customerDetailQuery
  }

  fragment customerDetailQuery on Query {
    Customer(input: $input) {
      pagingMeta {
        ...pagingFields
      }
      data {
        ...customerWithSuperintendentsWithActiveOrdersFields
      }
    }
  }

  ${pagingFieldsFragment}
  ${customerWithSuperintendentsWithActiveOrdersFieldsFragment}
`;

export const CustomerUpdate = gql`
  mutation CustomerUpdate($input: CustomerUpdate!) {
    CustomerUpdate(input: $input) {
      ...customerFields
    }
  }
  ${customerFieldsFragment}
`;

export const CustomerDelete = gql`
  mutation CustomerDelete($input: EntityDeleteInput!) {
    CustomerDelete(input: $input) {
      ...customerFields
    }
  }

  ${customerFieldsFragment}
`;
