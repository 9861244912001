import { RightOutlined } from '@ant-design/icons';
import { Button, message, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IJob } from '../../../../core/interfaces/job.interface';
import { getJobs, updateJob } from '../../../../core/services/job/job.service';
import '../index.scss';

const JobsTable = () => {
  const [jobs, setJobs] = useState<IJob[]>([]);

  const getAllJobs = async () => {
    try {
      const response = await getJobs();
      if (response.data && response.data.length) {
        setJobs(response.data);
      }
    } catch (error) {
      message.error('Error fetching jobs.');
    }
  };

  const updateHiring = async (job: IJob, checked: boolean, index: number) => {
    delete job['__typename'];
    delete job['applicants'];

    try {
      const respose = await updateJob({ ...job, id: job.id, hiring: checked });

      const updatedJobs = jobs.map((j, idx) => {
        if (idx === index) {
          return respose;
        }
        return j;
      });
      setJobs(updatedJobs);
      message.success(
        `${job.title} is ${checked ? 'hiring' : 'not hiring'} now.`
      );
    } catch (error) {
      message.error('Error updating current job.');
    }
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  const columns: ColumnsType<IJob> = [
    {
      title: 'Title',
      dataIndex: 'title'
    },
    {
      title: 'Actively Hiring',
      render: (value, record, index) => {
        return (
          <Switch
            checked={record.hiring}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => updateHiring(record, checked, index)}
          />
        );
      }
    },
    {
      title: 'Active Since',
      render: (value, record, index) => {
        return record.activeSince
          ? moment(record.activeSince).format('MMM Do YY')
          : '-';
      }
    },
    {
      title: 'Applicants',
      render: (value, record, index) => {
        return record.applicants?.length || 0;
      }
    },
    {
      title: '',
      align: 'right',
      render: (value, record, index) => {
        return (
          <Link to={`/jobs/form-job/${record.id}`}>
            <Button type="text">
              <RightOutlined />
            </Button>
          </Link>
        );
      }
    }
  ];

  return (
    <Table rowKey={(data) => data.id} dataSource={jobs} columns={columns} />
  );
};

export default JobsTable;
