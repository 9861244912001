import { ApolloQueryResult } from 'apollo-boost';
import { FUESApolloClient } from '../../helpers/api';
import {
  CreateScheduleMutation,
  ScheduleListQuery,
  SendReminderMutation,
} from './schedule.gql';
import { IPaginationWrapper } from '../../interfaces/graphql.interface';
import { IScheduleBrief } from '../../interfaces/schedule.interface';
import { addDates } from '../../helpers/utilities';

export const createSchedule = (payload) => {
  return FUESApolloClient()
    .mutate({
      mutation: CreateScheduleMutation,
      variables: {
        input: payload,
      },
    })
    .then((resp) => resp.data.ScheduleCreate);
};

export const sendReminder = (idd) => {
  return FUESApolloClient()
    .mutate({
      mutation: SendReminderMutation,
      variables: {
        input: { id: idd },
      },
    })
    .then((resp) => resp.data.ScheduleSendReminder);
};

export const getAllSchedules = (args = {}) => {
  return FUESApolloClient()
    .query({
      fetchPolicy: 'no-cache',
      query: ScheduleListQuery,
      variables: {
        input: {
          paging: {
            first: -1,
            skip: 0,
            orderBy: 'name.asc',
          },
          args,
        },
      },
    })
    .then(
      (
        resp: ApolloQueryResult<{
          Schedule: IPaginationWrapper<IScheduleBrief>;
        }>
      ) => resp.data.Schedule
    )
    .then((rr) => ({
      ...rr,
      data: rr.data.map((sch) => ({
        ...sch,
        Checkins: { ...sch.Checkins, data: sch.Checkins.data.map(addDates) },
      })),
    }));
};
