import React from 'react';
import bodyPartsImg from '../../../../core/images/body_parts.png';

const PATH_PARTS = [
  {
    d:
      'm 3743,5305 c -1,-16 -5,-52 -9,-79 -4,-28 -2,-75 6,-115 8,-44 9,-74 2,-91 -9,-24 -12,-25 -85,-22 -69,3 -75,5 -75,25 -3,153 -13,193 -57,245 l -27,32 27,10 c 14,5 55,10 90,10 35,0 67,5 70,11 4,5 19,9 33,7 23,-3 27,-7 25,-33 z',
    id: 'rb_pie',
  },
  {
    d:
      'm 3995,5330 c 3,-5 37,-10 75,-10 38,0 80,-4 95,-10 l 26,-10 -35,-34 c -38,-38 -56,-83 -55,-136 1,-19 -2,-56 -6,-82 l -7,-46 -67,-4 c -36,-2 -70,-1 -74,3 -11,9 -11,62 -2,156 5,49 4,87 -5,117 -7,24 -9,49 -5,55 8,14 52,14 60,1 z',
    id: 'lb_pie',
  },
  {
    d:
      'm 3716,4959 c 12,-19 4,-183 -9,-191 -20,-13 -70,-9 -123,8 -27,9 -50,18 -52,19 -3,3 38,156 45,168 7,12 131,8 139,-4 z',
    id: 'rb_antepie',
  },
  {
    d:
      'm 4126,4890 c 13,-41 23,-80 23,-86 1,-13 -86,-44 -126,-44 -61,0 -63,3 -63,105 0,65 4,95 13,99 6,2 39,4 72,3 l 59,-2 z',
    id: 'lb_antepie',
  },
  {
    d:
      'm 3575,4744 c 24,-10 58,-14 91,-12 l 53,3 8,-75 c 10,-100 10,-368 0,-453 l -9,-68 -36,8 c -66,14 -154,8 -204,-14 l -47,-21 -7,66 c -13,113 -7,193 26,323 43,173 69,259 79,259 5,0 26,-7 46,-16 z',
    id: 'rb_pantorrilla',
  },
  {
    d:
      'm 4187,4674 c 12,-43 34,-135 49,-204 23,-106 26,-139 21,-225 -8,-136 -8,-137 -58,-112 -42,21 -126,27 -196,15 l -41,-8 -8,88 c -11,113 -11,270 -1,402 l 9,105 56,-3 c 36,-2 70,2 92,12 19,8 39,14 45,12 5,-1 20,-38 32,-82 z',
    id: 'lb_pantorrilla',
  },
  {
    d:
      'm 3680,4114 c 17,-4 33,-12 35,-18 2,-6 13,-43 25,-82 12,-40 22,-103 23,-140 l 2,-69 -65,-22 c -75,-26 -137,-29 -183,-9 l -33,13 -13,109 c -7,60 -15,126 -18,146 -5,37 -4,39 38,58 45,20 127,26 189,14 z',
    id: 'rb_rodilla',
  },
  {
    d:
      'm 4171,4109 c 61,-21 64,-28 51,-121 -6,-46 -14,-111 -18,-145 -6,-60 -7,-62 -41,-74 -48,-16 -138,-6 -202,22 l -53,22 7,66 c 4,36 16,93 26,126 10,33 19,68 19,77 0,9 12,21 28,26 37,14 142,14 183,1 z',
    id: 'lb_rodilla',
  },
  {
    d:
      'm 3785,3559 c 3,-117 7,-280 11,-361 l 5,-147 -37,20 c -74,37 -252,21 -379,-36 l -58,-25 7,62 c 11,98 50,285 101,490 46,183 48,187 69,176 37,-20 145,-13 211,14 33,13 61,24 62,23 1,-1 5,-98 8,-216 z',
    id: 'rb_muslo',
  },
  {
    d:
      'm 4046,3730 c 57,-11 91,-8 144,14 13,5 18,-7 28,-67 7,-41 33,-157 57,-258 49,-202 83,-385 73,-394 -3,-4 -46,8 -94,26 -112,40 -265,52 -328,25 -22,-9 -40,-15 -42,-13 -2,1 1,90 6,197 5,107 9,268 10,357 v 162 l 43,-19 c 23,-10 69,-24 103,-30 z',
    id: 'lb_muslo',
  },
  {
    d:
      'm 2946,2998 c 9,-38 19,-68 23,-68 13,0 31,-74 31,-124 0,-44 -1,-46 -28,-46 -35,0 -66,-22 -101,-71 l -28,-38 -42,21 c -23,11 -58,41 -78,65 -21,25 -53,56 -72,69 -32,22 -33,24 -12,24 12,0 42,-14 66,-31 24,-17 47,-29 52,-26 5,3 6,43 2,89 -4,46 -9,117 -13,158 l -5,75 16.5355,63.7177 C 2754.6422,3129.8202 2880.2842,3257.1548 2946,2998 Z',
    id: 'rb_mano',
  },
  {
    d:
      'm 4774.5076,3109.2687 c 10,45 169.4924,10.7313 171.4924,8.7313 2,-2 -3,-60 -11,-129 -8,-68 -16,-140 -19,-160 -6,-61 6,-68 58,-31 72,52 79,37 14,-28 -109,-109 -169,-143 -183,-101 -10,34 -72,81 -106,81 -30,0 -31,0 -24,43 11,77 27,137 36,137 4,0 13,19 19,43 6,23 15,53 20,67 28.745,49.9928 10.8944,13.2309 24.5076,69.2687 z',
    id: 'lb_mano',
  },
  {
    d:
      'm 3771,3033 c 18,-10 35,-30 39,-43 3,-14 7,-100 9,-192 3,-155 1,-171 -19,-213 -25,-51 -23,-77 3,-51 16,16 17,16 17,-2 0,-16 -10,-20 -73,-25 -40,-4 -102,-15 -136,-26 -57,-18 -62,-18 -56,-3 18,45 25,138 16,203 -17,122 -75,218 -166,276 -25,15 -45,31 -45,35 0,9 76,36 150,53 87,20 218,14 261,-12 z',
    id: 'rb_pelvis',
  },
  {
    d:
      'm 4140,3049 c 56,-8 156,-39 182,-55 5,-3 -10,-18 -34,-33 -64,-41 -106,-89 -141,-161 -29,-60 -31,-73 -32,-170 0,-58 4,-120 8,-138 8,-32 8,-33 -11,-23 -31,17 -157,41 -213,41 -44,0 -49,2 -49,22 0,20 1,21 20,3 30,-27 36,3 10,47 -29,50 -35,146 -20,300 12,118 16,131 37,145 49,32 134,40 243,22 z',
    id: 'lb_pelvis',
  },
  {
    d:
      'm 3430,2893 c 67,-59 93,-114 109,-223 17,-113 -28,-250 -98,-297 -19,-13 -39,-23 -46,-23 -12,0 -22,43 -31,135 -3,28 -9,75 -15,105 -6,30 -13,126 -16,214 l -6,158 32,-17 c 18,-9 50,-32 71,-52 z',
    id: 'rb_cadera',
  },
  {
    d:
      'm 4354,2858 c -3,-57 -12,-157 -20,-223 -9,-66 -17,-142 -20,-170 -11,-110 -13,-117 -37,-109 -102,31 -160,199 -123,357 21,91 47,139 98,179 50,40 90,67 100,67 4,1 5,-45 2,-101 z',
    id: 'lb_cadera',
  },
  {
    d:
      'm 3002,2713 c 29,-34 80,-142 74,-158 -7,-18 -37,-31 -97,-40 l -44,-6 -31,67 -31,67 42,43 c 45,48 64,53 87,27 z',
    id: 'rb_munieca',
  },
  {
    d:
      'm 4756,2678 45,-43 -28,-62 -28,-63 -40,6 c -55,9 -105,33 -105,52 0,21 67,139 83,145 23,10 28,7 73,-35 z',
    id: 'lb_munieca',
  },
  {
    d:
      'm 3220,2291 c 11,-35 23,-93 26,-130 l 6,-66 -86,3 c -63,3 -94,-1 -118,-12 -17,-9 -32,-16 -34,-16 -9,0 -27,84 -39,194 -9,72 -17,138 -19,146 -19,72 -19,70 22,70 22,0 59,11 83,23 l 44,24 47,-86 c 27,-47 57,-115 68,-150 z',
    id: 'rb_antebrazo',
  },
  {
    d:
      'm 4624,2505 c 27,-14 65,-25 84,-25 26,0 33,-4 29,-14 -9,-24 -26,-125 -37,-226 -6,-52 -16,-111 -22,-131 l -11,-37 -33,14 c -39,17 -167,19 -195,3 -20,-10 -20,-9 -14,48 13,110 25,148 87,271 34,67 62,122 62,122 1,0 23,-11 50,-25 z',
    id: 'lb_antebrazo',
  },
  {
    d:
      'm 3820,2336 c 0,-167 11,-153 -129,-157 -46,-1 -131,-11 -189,-21 -57,-10 -107,-16 -110,-13 -3,3 -8,43 -10,89 l -4,83 30,6 c 17,4 55,28 84,55 68,61 157,90 311,101 15,1 17,-12 17,-143 z',
    id: 'rb_cintura',
  },
  {
    d:
      'm 3949,2470 c 132,-17 185,-37 241,-91 28,-27 62,-52 76,-55 30,-8 38,-37 30,-107 -10,-81 -7,-79 -135,-57 -62,11 -148,20 -190,20 -130,0 -121,-11 -121,156 0,109 3,144 13,144 6,0 45,-5 86,-10 z',
    id: 'lb_cintura',
  },
  {
    d:
      'm 3820,1858 v -303 l -98,3 c -87,3 -307,-23 -375,-44 -15,-5 -18,-2 -13,13 11,36 56,377 62,472 4,53 11,99 17,103 25,16 265,52 375,57 l 32,1 z',
    id: 'rb_torso',
  },
  {
    d:
      'm 4110,2134 c 52,-8 111,-19 131,-24 l 36,-10 2,-88 c 0,-48 9,-136 20,-196 14,-81 16,-118 9,-145 -6,-19 -8,-61 -6,-93 4,-59 4,-59 -21,-53 -90,19 -241,35 -328,35 h -103 v 301 301 l 83,-7 c 45,-3 125,-13 177,-21 z',
    id: 'lb_torso',
  },
  {
    d:
      'm 3224,2060 c 15,-6 26,-17 26,-29 0,-10 7,-35 15,-54 8,-20 15,-41 15,-47 0,-14 -61,-40 -92,-40 -46,0 -98,20 -98,39 0,9 -14,36 -31,59 -17,23 -28,46 -24,52 16,25 141,39 189,20 z',
    id: 'rb_codo',
  },
  {
    d:
      'm 4602,2060 c 21,-6 39,-14 42,-19 3,-4 -6,-24 -19,-44 -14,-20 -25,-47 -25,-61 0,-20 -7,-27 -37,-35 -52,-15 -87,-14 -129,4 -34,14 -36,17 -30,47 4,18 9,47 13,65 3,23 13,35 32,42 35,13 108,13 153,1 z',
    id: 'lb_codo',
  },
  {
    d:
      'm 3306,1853 c 3,-21 10,-56 14,-77 8,-39 -2,-172 -18,-229 -7,-27 -9,-28 -87,-31 -44,-1 -88,-6 -97,-10 -15,-7 -17,-1 -16,51 0,32 -3,115 -7,184 l -8,126 79,-4 c 61,-4 84,-1 99,11 29,22 33,20 41,-21 z',
    id: 'rb_biceps',
  },
  {
    d:
      'm 4454,1863 c 31,-11 50,-12 89,-3 27,6 52,8 55,5 3,-2 -1,-62 -8,-132 -8,-71 -13,-152 -12,-181 l 1,-52 -27,7 c -15,5 -59,8 -98,8 h -72 l -11,65 c -16,87 -16,172 -1,248 11,52 16,62 29,56 9,-4 34,-13 55,-21 z',
    id: 'lb_biceps',
  },
  {
    d:
      'm 3798,1524 22,-6 V 1246 974 l -56,-17 c -31,-10 -57,-16 -58,-15 -1,2 -12,35 -25,74 -30,91 -116,253 -166,313 -22,25 -65,69 -96,96 -34,30 -52,53 -45,56 9,6 125,27 226,42 42,7 174,7 198,1 z',
    id: 'rb_pecho',
  },
  {
    d:
      'm 4200,1505 c 130,-25 126,-22 86,-55 -80,-62 -169,-172 -218,-265 -36,-69 -88,-211 -88,-240 0,-19 -1,-19 -52,4 -24,11 -51,22 -60,25 -17,5 -18,27 -18,275 v 270 l 23,5 c 41,9 241,-3 327,-19 z',
    id: 'lb_pecho',
  },
  {
    d:
      'm 3339,1447 c 135,-81 293,-322 326,-497 5,-30 3,-36 -17,-45 -19,-9 -42,-2 -133,39 -109,48 -151,64 -231,85 -59,16 -106,51 -132,98 -35,64 -63,191 -60,270 l 3,68 55,12 c 75,16 125,8 189,-30 z',
    id: 'rb_hombro',
  },
  {
    d:
      'm 3854.625,940.3125 c 13,0 33.375,-4.3125 64.375,-20.3125 86,-47 87,-48 83,-107 l -4,-53 h -74 -74 v 61 z',
    id: 'lb_cuello',
  },
  {
    d:
      'm 3819,762 -74,-4 -75,-3 v 47 c 0,25 -2,53 -5,60 -7,17 119.6875,76.125 158.6875,76.125 z',
    id: 'rb_cuello',
  },
  {
    d:
      'M 3820,536 V 343 l -72,-7 c -40,-4 -90,-9 -111,-13 l -39,-6 7,47 c 5,29 4,46 -3,46 -5,0 -12,-7 -16,-15 -3,-8 -12,-15 -21,-15 -30,0 -11,85 33,140 11,14 23,44 27,67 3,23 15,53 26,67 10,13 19,33 19,43 0,30 8,33 82,33 h 68 z',
    id: 'rb_cara',
  },
  {
    d:
      'm 4000,703 c 0,-14 7,-34 16,-44 9,-10 18,-37 21,-61 4,-24 16,-56 28,-73 34,-45 55,-91 55,-120 0,-28 -21,-33 -40,-10 -19,23 -29,9 -23,-37 5,-34 3,-40 -9,-36 -8,4 -56,9 -107,13 l -91,8 v 193 194 h 75 c 75,0 75,0 75,-27 z',
    id: 'lb_cara',
  },
  {
    d:
      'M 3820,204 V 97 l -47,7 c -60,8 -132,43 -154,76 -10,14 -19,43 -21,65 l -3,40 60,11 c 33,7 84,12 113,13 l 52,1 z',
    id: 'rb_craneo',
  },
  {
    d:
      'm 4048,293 c 22,-5 24,-9 19,-49 -2,-24 -12,-55 -22,-70 -20,-31 -81,-60 -147,-69 l -48,-7 v 107 108 l 86,-7 c 48,-3 98,-9 112,-13 z',
    id: 'lb_creneo',
  },
  {
    d:
      'm 1192,5250 c -7,-98 -6,-169 2,-202 l 8,-38 -32,11 c -17,6 -55,9 -85,7 -61,-5 -69,3 -59,57 4,23 -1,45 -15,73 -26,52 -67.875,102 -35.875,96 39.1194,36.8701 169.8448,35.7217 192.875,34 24,-3 27,-7 24,-38 z',
    id: 'lf_pie',
  },
  {
    d:
      'm 1373.0078,5011.9922 7.9883,37.0117 c 12,51 11.0078,158.9922 -1.9922,181.9922 -16,31 -2,59.0039 30,59.0039 20,0 176.6062,-0.765 184.6289,-17.7539 54.1928,-12.3184 18.6697,-12.7376 -18.6328,-103.2422 -19,-36 -24,-59 -20,-80 4,-16 4,-37.0078 0,-48.0078 -5,-17 -12.0078,-19.0039 -58.0078,-14.0039 -29,3 -67.9883,1 -87.9883,-5 z',
    id: 'rf_pie',
  },
  {
    d:
      'm 1168,4993 c 21,-5 23,-10 18,-47 -3,-23 -6,-77 -7,-121 -1,-44 -6,-86 -12,-92 -5,-7 -28,-13 -51,-13 -47,0 -136,27 -136,41 0,27 62,224 73,231 14,9 83,10 115,1 z',
    id: 'lf_antepie',
  },
  {
    d:
      'm 1519,4994 c 20,-4 31,-12 31,-24 0,-9 8,-50 19,-91 35,-140 36,-125 -11,-144 -44,-17 -101,-22 -136,-10 -20,6 -22,13 -22,98 0,51 -4,107 -9,125 -9,29 -7,34 12,42 27,11 74,12 116,4 z',
    id: 'rf_antepie',
  },
  {
    d:
      'm 1025,4704 c 24,-10 59,-14 92,-12 30,2 53,-1 54,-7 0,-5 2,-19 4,-30 10,-49 15,-350 8,-445 l -8,-105 -25,5 c -59,12 -195,4 -227,-13 -29,-15 -31,-15 -38,1 -3,9 -9,60 -12,114 -6,92 -3,108 43,303 28,116 54,205 60,205 6,0 29,-7 49,-16 z',
    id: 'lf_pantorrilla',
  },
  {
    d:
      'm 1614,4703 c 82,-280 91,-326 91,-458 0,-71 -3,-138 -7,-148 -7,-17 -9,-17 -40,-1 -43,22 -164,30 -212,14 -21,-6 -39,-10 -41,-8 -1,1 -8,67 -14,146 -8,106 -7,183 1,296 l 12,152 55,-4 c 34,-2 72,2 96,12 52,19 53,19 59,-1 z',
    id: 'rf_pantorrilla',
  },
  {
    d:
      'm 1140,4081 c 29,-8 53,-77 72,-208 14,-97 12,-101 -83,-127 -69,-20 -120,-20 -164,-2 -34,14 -35,17 -35,68 -1,29 -7,89 -15,133 -20,108 -21,103 28,125 43,20 145,26 197,11 z',
    id: 'lf_rodilla',
  },
  {
    d:
      'm 1621,4079 c 61,-21 63,-25 45,-130 -9,-51 -16,-114 -16,-141 0,-47 -1,-50 -37,-65 -50,-20 -136,-12 -206,20 l -53,24 8,76 c 4,42 15,95 23,119 8,24 15,53 15,65 0,16 9,23 38,31 52,14 142,14 183,1 z',
    id: 'rf_rodilla',
  },
  {
    d:
      'm 1250,3075 c 6,-66 13,-128 16,-137 4,-12 0,-18 -11,-18 -31,0 -79,-29 -150,-91 -101,-87 -97,-87 -134,-29 -33,52 -86,93 -157,124 l -40,17 4,62 c 5,86 22,173 94,464 l 62,252 25,-7 c 46,-13 155,-6 211,14 l 55,19 7,-275 c 4,-151 12,-329 18,-395 z',
    id: 'lf_muslo',
  },
  {
    d:
      'm 1425,3722 c 42,-16 166,-22 200,-10 20,8 24,-7 88,-265 65,-263 86,-376 87,-464 0,-42 0,-42 -71,-79 -65,-34 -105,-73 -144,-139 -10,-16 -18,-12 -74,36 -95,82 -133,108 -169,115 -24,5 -31,11 -28,23 11,36 26,213 26,311 0,58 3,194 7,303 5,176 8,197 22,191 9,-4 34,-14 56,-22 z',
    id: 'rf_muslo',
  },
  {
    d:
      'm 386,3025 c 13,-44 29,-87 34,-95 21,-33 31,-67 37,-126 l 6,-62 -40,5 c -52,7 -99,-17 -120,-62 -9,-19 -20,-35 -23,-35 -16,0 -89,60 -117,97 -17,21 -43,45 -57,52 -14,8 -23,18 -20,23 9,14 21,9 68,-27 26,-19 51,-35 56,-35 14,0 12,64 -5,210 -8,69 -15,127 -14,130 12.64062,105.7813 161.11198,19.4535 195,-75 z',
    id: 'lf_mano',
  },
  {
    d:
      'm 2387,3070 c -16,-127 -30,-300 -24,-306 3,-4 31,10 62,31 37,26 59,35 65,29 7,-7 2,-15 -15,-24 -14,-8 -45,-37 -69,-64 -24,-28 -62,-61 -85,-74 l -41,-23 v 23 c 0,14 -13,37 -31,54 -25,24 -39,29 -81,29 h -50 l 5,63 c 4,35 14,78 23,95 9,18 22,58 30,91 19.1048,138.4139 204.1114,253.3996 211,76 z',
    id: 'rf_mano',
  },
  {
    d:
      'm 880,2852 c 77,-52 113,-123 113,-226 -1,-51 -7,-86 -21,-118 -22,-51 -77,-98 -118,-101 -27,-2 -28,-1 -41,83 -18,120 -31,231 -37,332 l -6,87 27,-10 c 16,-5 53,-26 83,-47 z',
    id: 'lf_cadera',
  },
  {
    d:
      'm 1796,2783 c -7,-126 -34,-374 -41,-381 -10,-10 -57,11 -90,40 -56,48 -79,102 -79,178 0,80 29,152 82,202 34,33 105,77 126,78 5,0 6,-50 2,-117 z',
    id: 'rf_cadera',
  },
  {
    d:
      'm 1364,2876 c 30,-12 167,-127 174,-146 2,-4 -9,-11 -24,-14 -15,-4 -57,-36 -93,-70 -36,-35 -76,-67 -88,-70 l -23,-7 v 160 c 0,104 4,161 10,161 6,0 26,-6 44,-14 z',
    id: 'rf_pelvis',
  },
  {
    d:
      'm 448,2705 c 24,-17 92,-128 92,-150 0,-19 -50,-44 -103,-51 l -44,-6 -37,77 c -40,85 -40,107 2,130 35,19 62,19 90,0 z',
    id: 'lf_munieca',
  },
  {
    d:
      'm 2233,2693 c 22,-21 19,-44 -17,-128 -25,-60 -26,-60 -65,-58 -43,2 -99,26 -107,47 -7,18 67,143 91,155 26,13 74,5 98,-16 z',
    id: 'rf_munieca',
  },
  {
    d:
      'm 1565,2617 c 1,-107 27,-166 93,-208 25,-16 56,-29 68,-29 22,0 22,-2 19,-97 -1,-54 -6,-134 -10,-179 l -7,-81 -88,-6 c -136,-10 -157,-20 -211,-101 -43,-64 -77,-95 -106,-96 -18,0 -18,397 -1,435 9,19 9,28 0,37 -8,8 -12,53 -12,129 v 117 l 26,6 c 15,4 52,31 83,60 55,53 116,96 136,96 6,0 10,-34 10,-83 z',
    id: 'rf_abdomen',
  },
  {
    d:
      'm 627,2393 c 59,-124 83,-200 83,-266 v -32 h -93 c -72,0 -99,-4 -120,-18 l -26,-17 -16,52 c -8,29 -19,94 -25,143 -5,50 -14,115 -20,144 -5,30 -10,57 -10,61 0,5 22,11 49,15 26,3 61,15 77,25 16,10 32,19 35,19 3,1 33,-56 66,-126 z',
    id: 'lf_antebrazo',
  },
  {
    d:
      'm 2058,2501 c 20,-10 57,-22 80,-26 43,-7 44,-8 39,-39 -3,-17 -11,-71 -17,-121 -15,-114 -39,-233 -49,-239 -4,-3 -25,1 -47,9 -46,17 -132,19 -170,5 l -27,-11 6,69 c 4,37 16,94 27,126 20,60 108,246 116,246 3,0 21,-8 42,-19 z',
    id: 'rf_antebrazo',
  },
  {
    d:
      'm 684,2060 c 15,-6 26,-17 26,-29 0,-10 7,-35 15,-54 20,-48 20,-50 -14,-70 -55,-32 -171,-15 -171,26 0,11 -12,38 -26,58 -14,21 -23,43 -19,48 16,26 140,40 189,21 z',
    id: 'lf_codo',
  },
  {
    d:
      'm 2049,2057 c 44,-15 48,-28 16,-65 -14,-16 -25,-42 -25,-56 0,-20 -7,-29 -27,-37 -42,-16 -130,-6 -154,17 -18,19 -19,22 -4,57 8,20 15,45 15,56 0,36 107,53 179,28 z',
    id: 'rf_codo',
  },
  {
    d:
      'm 1746,1852 c 17,-109 19,-153 12,-185 -13,-56 -3,-137 27,-211 13,-33 23,-61 22,-61 -128,-84 -259,-230 -343,-382 -21,-38 -38,-69 -40,-71 -1,-2 -20,6 -43,17 -22,12 -47,21 -56,21 -13,0 -15,48 -15,410 0,407 0,410 20,410 42,0 81,32 125,100 37,58 50,71 82,79 54,14 92,19 142,17 l 45,-1 z',
    id: 'rf_pecho',
  },
  {
    d:
      'm 764,1853 c 3,-21 8,-56 11,-78 3,-22 10,-59 17,-83 6,-23 8,-75 5,-115 -5,-70 -32,-157 -48,-157 -5,0 -29,13 -55,29 -25,16 -68,38 -95,47 l -49,18 2,45 c 1,25 -2,108 -7,184 l -8,138 27,-11 c 38,-14 115,-12 151,5 42,19 43,19 49,-22 z',
    id: 'lf_biceps',
  },
  {
    d:
      'm 1870,1874 c 25,-13 49,-15 101,-11 l 69,6 v -57 c 0,-31 -5,-88 -11,-127 -12,-79 -13,-136 -4,-165 4,-13 2,-20 -7,-20 -8,0 -51,-18 -97,-40 -45,-22 -85,-40 -87,-40 -9,0 -43,84 -54,133 -8,38 -6,69 10,160 35,203 27,187 80,161 z',
    id: 'rf_biceps',
  },
  {
    d:
      'm 658,1433 c 91,-46 208,-137 288,-224 34,-37 150,-218 164,-255 8,-19 5,-26 -11,-35 -23,-12 -78,7 -194,64 -33,16 -87,36 -119,44 -123,28 -177,77 -218,192 -24,70 -38,261 -18,261 7,0 55,-21 108,-47 z',
    id: 'lf_hombro',
  },
  {
    d:
      'm 2032,1443 c 3,-67 -5,-143 -23,-214 -32,-121 -87,-173 -216,-203 -32,-7 -78,-23 -102,-36 -24,-12 -60,-28 -80,-35 -20,-7 -59,-22 -88,-33 -80,-32 -84,-15 -22,94 71,125 120,185 223,276 63,56 118,93 188,128 55,28 104,50 109,50 5,0 10,-12 11,-27 z',
    id: 'rf_hombro',
  },
  {
    d:
      'M 1279.6875,826.6875 1280,762 1223,751 c -32,-5 -68,-13 -80,-17 -21,-6 -22,-3 -25,76 l -3,82 41.5625,26 c 56.5,28.5 91.75,39.625 125.625,39.375 z',
    id: 'lf_cuello',
  },
  {
    d:
      'm 1306.25,957.6875 c 33.875,-0.25 42.3125,-12.1875 88.3125,-41.6875 L 1450,885 v -78 c 0,-71 -2,-78 -17,-72 -10,3 -41,11 -70,17 l -52,11 -3,60 z',
    id: 'rf_cuello',
  },
  {
    d:
      'm 1382,719 c 75,-22 100,-47 113,-117 4,-19 17,-49 30,-67 32,-42 53,-94 48,-116 -6,-23 -16,-24 -33,-4 -23,28 -33,17 -27,-30 6,-44 6,-44 -21,-40 -32,6 -116,13 -154,14 l -28,1 v 114 115 l 27,3 c 39,4 42,28 4,28 -29,0 -31,3 -31,34 0,19 0.7565,20.89165 -2.2435,29.89165 0.6291,16 1.0373,6.27078 -1.474,19.46621 l 3.064,28.2719 C 1341.4715,733.75476 1336.5,733 1382,719',
    id: 'lr_cara',
  },
  {
    d:
      'm 1152,704 c 56,20.5 98.9259,31.02903 131.0509,33.15403 l 2.2394,-34.45908 c -4.5,-6.25 -3.3016,-22.05806 -3.3016,-46.05806 C 1281.9887,607.63689 1279,621 1248,618 c -18,-2 -33,-7 -33,-13 0,-5 15,-11 33,-13 l 32,-3 V 476 362 l -77,-6 c -43,-4 -94,-9 -114,-12 l -37,-5 5,45 c 6,48 -4,59 -28,30 -12,-14 -14,-14 -26,1 -15,22 -1,61 46,125 17,24 31,54 31,67 0,44 27,80 72,97',
    id: 'lf_cara',
  },
  {
    d:
      'M 1282.3934,324.2435 C 1278.8016,305.58835 1280,307 1280,229 V 127 l -60,6 c -33,3 -75,14 -92,24 -43,25 -78,78 -78,119 0,31 2,33 53,43 28,6 66,12 82,15 20,3 36.8125,8.8125 100.0625,6.1875 z',
    id: 'lf_craneo',
  },
  {
    d:
      'M 1306.625,340.1875 C 1369.875,337.5625 1458,326.5 1480,322 c 31,-7 35,-12 38,-43 6,-70 -60,-132 -155,-145 l -52,-6 -3,97 c -2,68 -1.0338,81.43845 -0.8579,100.34835 z',
    id: 'rf_craneo',
  },
  {
    d:
      'm 4539.0962,1475.3731 c 16.4874,-4.2537 35.2199,-9.7598 41.6277,-12.2357 l 11.6505,-4.5016 -2.3488,-73.4295 c -1.6547,-51.7305 -4.8723,-85.3383 -10.8883,-113.7287 -18.2198,-85.9809 -51.0739,-161.9286 -82.6324,-191.0182 -29.9027,-27.5633 -84.0535,-50.7258 -171.241,-73.2467 -18.8398,-4.8664 -56.0158,-18.83637 -82.6132,-31.04441 -109.0989,-50.07574 -189.4039,-81.48719 -208.326,-81.48719 -18.208,0 -31.4536,13.66305 -31.4536,32.44496 0,24.7077 34.5722,118.38894 70.5661,191.21504 71.5285,144.7228 153.6272,255.446 223.1269,300.9219 16.3536,10.7007 32.0735,19.4559 34.933,19.4559 24.5732,16.6586 18.2844,12.8473 34.0659,19.9285 0,7.0418 20.4569,17.8362 43.9043,23.167 29.3584,6.6745 90.5834,3.6323 129.6289,-6.4413 z',
    id: 'lb_hombro',
  },
  {
    d:
      'm 1051,1978 c 31,-9 45,-20 60,-50 34,-67 87,-117 130,-124 l 39,-7 -2,-404 -3,-405 -63,-20 c -35,-11 -65,-18 -66,-16 -2,2 -19,35 -40,73 -65,121 -189,268 -287,340 l -46,35 25,51 c 28,57 42,186 25,236 -7,20 -5,58 8,133 10,58 18,123 18,144 l 1,39 80,-6 c 45,-4 99,-12 121,-19 z',
    id: 'lf_pecho',
  },
  {
    d:
      'm 1280,2730 c 0,-146 -2,-160 -17,-160 -25,0 -62,27 -111,82 -24,25 -58,54 -78,63 l -34,16 56,52 c 82,75 132,107 167,107 15,0 17,-14 17,-160 z',
    id: 'lf_pelvis',
  },
  {
    d:
      'm 1068,2681 c 20,-11 62,-44 92,-75 36,-37 65,-58 87,-62 l 33,-6 v -111 c 0,-90 -3,-114 -17,-129 -17,-19 -17,-21 0,-50 14,-25 17,-59 17,-229 0,-132 -4,-199 -10,-199 -39,0 -96,52 -128,116 -27,54 -74,73 -197,81 l -93,6 -7,86 c -4,47 -8,127 -9,178 l -1,91 33,6 c 50,8 101,52 128,110 19,40 24,68 24,129 0,42 2,77 5,77 3,0 22,-9 43,-19 z',
    id: 'lf_abdomen',
  },
];

const BodyPartInput = ({
  value,
  onChange,
  disabled,
  width,
  height,
}: {
  value?;
  onChange?;
  defaultValue?;
  disabled?;
  width;
  height;
}) => {
  const valueIState = value || [];
  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
      }}
    >
      <img
        alt="Body Parts"
        src={bodyPartsImg}
        style={{
          position: 'absolute',
          top: 0,
        }}
      />
      <svg
        style={{
          position: 'absolute',
          top: 0,
        }}
        width={width}
        height={height}
        viewBox="0 0 5140 5380"
      >
        {PATH_PARTS.map(({ d, id }) => {
          const found = Boolean(valueIState.find((idd) => id === idd));
          return (
            <path
              onClick={() => {
                if (!disabled) {
                  return found
                    ? onChange(valueIState.filter((idd) => idd !== id))
                    : onChange([...valueIState, id].sort());
                }
                return false;
              }}
              d={d}
              key={id}
              id={id}
              style={{ fill: found ? 'red' : 'transparent' }}
            />
          );
        })}
      </svg>
    </div>
  );
};

export default BodyPartInput;
