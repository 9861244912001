import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { getAllSkills } from '../services/skill/skill.service';
import { ISkill, IWorkerGroup } from '../interfaces/skill.interface';

const { Text } = Typography;

const SkillQuantitySelector = ({
  defaultSkillQuantity,
  skillQuantityChanged,
}) => {
  const [skills, setSkills] = useState<ISkill[]>([]);

  const [skillQuantity, setSkillQuantity] = useState<IWorkerGroup[]>(
    defaultSkillQuantity || []
  );

  const fetchAllSkills = () => {
    getAllSkills().then((resp) => {
      setSkills(resp.data);
    });
  };

  useEffect(() => {
    skillQuantityChanged(skillQuantity);
  }, [skillQuantity, skillQuantityChanged]);

  useEffect(() => {
    fetchAllSkills();
  }, []);

  return (
    <div>
      <Select
        size="large"
        mode="multiple"
        placeholder="Skills"
        style={{ width: '100%', fontWeight: 400 }}
        value={skillQuantity.map(({ skill }) => skill.id)}
        onChange={(e: string[]) => {
          const selectedSkills = e.map((skillId) => {
            const skill = skills.find((x) => x.id === skillId);
            const skillQ = skillQuantity.find((x) => x.skill.id === skillId);
            return {
              skill,
              quantity: skillQ ? skillQ.quantity : 1,
            };
          });
          setSkillQuantity(selectedSkills as IWorkerGroup[]);
        }}
        optionFilterProp="label"
        filterOption
        options={skills.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
      />

      {skillQuantity.length > 0 ? (
        <Row gutter={15} style={{ marginTop: 25 }}>
          <Col span={24}>
            <div style={{ fontWeight: 'bold' }}>Worker Quantities</div>
            <Space
              direction="vertical"
              style={{ width: '100%', marginBottom: 15 }}
              size="small"
            >
              {skillQuantity.map((skill: any, i) => {
                return (
                  <Row
                    key={skill.skill.id}
                    align="middle"
                    justify="space-between"
                  >
                    <Col>
                      <Text>{skill.skill.name}</Text>
                    </Col>
                    <Col>
                      {/* <Form.Item name={[skill.skill.id, 'quantity']}  style={{ marginBottom: 0 }} rules={[{ required: true, message: 'quantity is required'}]}> */}
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Input
                          size="small"
                          type="number"
                          placeholder="Quantity"
                          value={`${skill.quantity}`}
                          onChange={(e) => {
                            skillQuantity[i].quantity = parseInt(
                              e.target.value,
                              10
                            );
                            setSkillQuantity([...skillQuantity]);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
            </Space>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default SkillQuantitySelector;
